import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import pick from 'lodash/pick';

import gettext from 'airborne/gettext';

import Modal from 'react-bootstrap/lib/Modal';
import Glyphicons from 'midoffice/components/Glyphicons';

import searchHotels from 'airborne/store/modules/search_hotels/actions/lazySearch';
import searchCars from 'airborne/store/modules/search_cars/actions/search';
import {reuseDestination} from '../store/modules/homepage/actions';


class DestinationLink extends React.Component {
    static propTypes = {
        icon: PropTypes.string,
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        onSelect: PropTypes.func.isRequired,
    };

    handleSelect = (event)=> {
        event.preventDefault();
        this.props.onSelect(pick(this.props, 'value', 'label', 'type', 'iataCode', 'lat', 'lon'));
    };

    render() {
        const {label, icon} = this.props;
        return (<a onClick={this.handleSelect} href="#">
            {icon ? <Glyphicons glyph={icon} /> : null}
            {label}
        </a>);
    }
}


class DestinationsModal extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        results: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    handleSelect = (value)=> {
        const {onSelect, onHide} = this.props;
        onHide();
        onSelect(value);
    };

    renderItem(item, idx) {
        return (<DestinationLink
            key={idx}
            {...item}
            onSelect={this.handleSelect} />
        );
    }

    renderBlock({category, items, id}) {
        return (<li key={id}>
            {category && (<div className="multiple-select__title">
                {category}
            </div>)}
            <div className="multiple-select__items">
                {items.map((item, idx)=> this.renderItem(item, idx))}
            </div>
        </li>);
    }

    render() {
        const {onHide, results, title, subtitle} = this.props;

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="multiple-select">
                    <h4 className="multiple-select__header">{subtitle}</h4>
                    <ul className="multiple-select__list">
                        {results && results.map((block)=> this.renderBlock(block))}
                    </ul>
                </Modal.Body>
            </Modal>
        );
    }
}


@connect(null, {
    onChange: reuseDestination,
    onSearch: searchCars,
})
export class CarsDestinationsModal extends React.Component {
    static propTypes = {
        param: PropTypes.oneOf(['destination', 'pickUp', 'dropOff']).isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
    };

    handleSelect = (value)=> {
        const {onChange, onSearch, param} = this.props;
        onChange(value, param);
        onSearch();
    };

    render() {
        const {param} = this.props;

        const title = {
            'pickUp': gettext('Your search returned multiple pick-up locations'),
            'dropOff': gettext('Your search returned multiple drop-off locations'),
        }[param];

        const subtitle = {
            'pickUp': gettext('Please choose the correct pick-up location below'),
            'dropOff': gettext('Please choose the correct drop-off location below'),
        }[param];

        return (
            <DestinationsModal
                {...this.props}
                title={title}
                subtitle={subtitle}
                onSelect={this.handleSelect} />
        );
    }
}


@connect(null, {
    onChange: reuseDestination,
    onSearch: searchHotels,
})
export class HotelsDestinationsModal extends React.Component {
    static propTypes = {
        toSearch: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
    };

    handleSelect = (value)=> {
        const {toSearch, onChange, onSearch} = this.props;
        onChange(value);
        toSearch && onSearch();
    };

    render() {
        return (
            <DestinationsModal
                {...this.props}
                title={gettext('Your search returned multiple destinations')}
                subtitle={gettext('Please choose the correct destination below')}
                onSelect={this.handleSelect} />
        );
    }
}
