import React from 'react';
import PropTypes from 'prop-types';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';


/**
 * @class FieldLabel
 */
export default class FieldLabel extends React.Component {
    static propTypes = {
        label: PropTypes.node,
        name: PropTypes.string,
        className: PropTypes.string,
        component: PropTypes.string,
        required: PropTypes.bool,
        hint: PropTypes.string,
        suffix: PropTypes.node,
        after: PropTypes.node,
    };

    static defaultProps = {
        className: '',
        component: 'label',
        label: '',
        suffix: ':',
        hint: '',
        required: false
    };

    renderTooltip(hint, name) {
        return (<Tooltip id={name}>
            <div className="text-left" dangerouslySetInnerHTML={{__html: hint}} />
        </Tooltip>);
    }

    renderHint(hint, name) {
        return (
            <OverlayTrigger placement="right" overlay={this.renderTooltip(hint, name)}>
                <Glyphicon glyph="info-sign" />
            </OverlayTrigger>
        );
    }

    render() {
        let {name, label, required, hint, suffix,
            className, component, after} = this.props;

        if (!label) { required = hint = suffix = false; }

        let hintElement = hint ? this.renderHint(hint, name) : null;

        return React.createElement(component, {className},
            suffix ? label + suffix : label,
            required && '\u00a0',
            required && <span className="highlight-red">*</span>,
            hintElement && '\u00a0',
            hintElement,
            after || null
        );
    }
}
