import systemData from 'airborne/systemData';
import {createSelector} from 'reselect';
import flow from 'lodash/flow';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import every from 'lodash/every';
import {identity} from 'lodash';

export const getGroupTree = createSelector(
    () => systemData.common.PERMISSIONS_PER_GROUP,
    permissionsPerGroup =>
        Object.keys(permissionsPerGroup).map(item => ({
            code: item,
            title: item,
        }))
);

export function flattenTree(nodes = [], processor = identity) {
    return nodes.reduce((acc, {children, ...node}) => {
        const flattenNodes = children ? flattenTree(children) : [node];
        return [
            ...acc,
            ...flattenNodes.map(flattenNode => {
                const prevTitle = flattenNode.prevTitle
                    ? `${node.title} -> ${flattenNode.prevTitle}`
                    : flattenNode.title;
                return processor({
                    ...flattenNode,
                    prevTitle,
                });
            }),
        ];
    }, []);
}

export const getTreeNodeByCode = createSelector(
    tree => tree,
    tree => {
        const codeMapper = flattenTree(tree).reduce((acc, item) => ({...acc, [item.code]: item}), {});
        return function(code) {
            return codeMapper[code];
        };
    }
);

export const getGroupPermissions = group => {
    if (group === 'superuser') {
        return flattenTree(systemData.common.ALL_PERMISSIONS).map(({code}) => code);
    }

    if (group === 'RESTRICTED_PERMISSIONS') {
        return systemData.common.RESTRICTED_PERMISSIONS;
    }

    return systemData.common.PERMISSIONS_PER_GROUP[group] || [];
};

const getSuperAdmin = value => (value['is_superuser'] ? ['superuser'] : []);

export const getDefaultPemissions = value =>
    flow(
        value => [...value['groups'], ...getSuperAdmin(value)],
        groups => groups.map(getGroupPermissions),
        groups => union(...groups),
        uniq
    )(value);

export function getPemissionNodeByCode(code) {
    return getTreeNodeByCode(systemData.common.ALL_PERMISSIONS)(code);
}

export function isDependenciesChecked(checkedPemission) {
    return function(code) {
        const node = getPemissionNodeByCode(code);
        const {dependencies} = node;

        return (
            dependencies.length === 0 ||
            every(
                dependencies,
                dependency => checkedPemission.includes(dependency) && isDependenciesChecked(dependency)
            )
        );
    };
}
