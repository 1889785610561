import noop from 'lodash/noop';
import bindAll from 'lodash/bindAll';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import format from 'midoffice/helpers/format';
import {ELEMENT_H, nodePosition, findVisible, splitVisible} from 'midoffice/helpers/treeSize';
import {recursiveChildren} from 'midoffice/helpers/selectUtils';

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Loader from './Loader';
import {fetchPartial, markCompaniesAsNotLoaded} from 'midoffice/data/actions';
import {selectError} from 'midoffice/newforms/helpers';
import {hasAccess} from 'midoffice/helpers/permission';

import settings from 'airborne/settings';

const INHERITS_TOOLTIP = 'Inheritance is turned off and  setting may have been modified';

class CompanyActions extends React.Component {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
        onSelect: PropTypes.func.isRequired,
        isDeprecated: PropTypes.bool,
        folder: PropTypes.bool,
        root: PropTypes.bool,
    };

    static defaultProps = {
        folder: false,
        root: false,
    };

    shouldComponentUpdate({id}) {
        return id !== this.props.id;
    }

    renderRoot({canEdit, canView}) {
        return (
            <div className="navbar-tree__dropdown">
                <DropdownButton bsStyle={null} title="" id="actions" onSelect={this.props.onSelect}>
                    <MenuItem disabled={!canView} eventKey="settings">
                        Open
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="add" eventKey="add">
                        Add Company…
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="addGroup" eventKey="addGroup">
                        Add Company Group…
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="expand">
                        Expand all
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="collapse">
                        Collapse all
                    </MenuItem>
                </DropdownButton>
            </div>
        );
    }

    renderFolder({canEdit, canView, isDeprecated}) {
        return (
            <div className="navbar-tree__dropdown">
                <DropdownButton bsStyle={null} title="" id="actions" onSelect={this.props.onSelect}>
                    <MenuItem disabled={!canView} eventKey="settings">
                        Open
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="add" eventKey="add">
                        Add Company…
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="addGroup" eventKey="addGroup">
                        Add Company Group…
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="info">
                        External IDs
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="rename" eventKey="rename">
                        Rename…
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="move" eventKey="move">
                        Move…
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="expand">
                        Expand all
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="collapse">
                        Collapse all
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="deprecated" eventKey="deprecated">
                        {isDeprecated ? 'Unmark as deprecated' : 'Mark as deprecated'}
                    </MenuItem>
                </DropdownButton>
            </div>
        );
    }

    renderLeaf({canEdit, canView, isDeprecated}) {
        return (
            <div className="navbar-tree__dropdown">
                <DropdownButton bsStyle={null} title="" id="actions" onSelect={this.props.onSelect}>
                    <MenuItem disabled={!canView} eventKey="settings">
                        Open
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="info">
                        External IDs
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="rename" eventKey="rename">
                        Rename…
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="move" eventKey="move">
                        Move…
                    </MenuItem>
                    <MenuItem disabled={!canEdit} key="deprecated" eventKey="deprecated">
                        {isDeprecated ? 'Unmark as deprecated' : 'Mark as deprecated'}
                    </MenuItem>
                </DropdownButton>
            </div>
        );
    }

    render() {
        let {folder, root, isDeprecated} = this.props;

        const canEdit = hasAccess('modify_company_tree');
        const canView = hasAccess('section:company');
        if (root) return this.renderRoot({canEdit, canView});
        if (folder) return this.renderFolder({canEdit, canView, isDeprecated});

        return this.renderLeaf({canEdit, canView, isDeprecated});
    }
}

class AgencyActions extends React.Component {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
        onSelect: PropTypes.func.isRequired,
        folder: PropTypes.bool,
        root: PropTypes.bool,
    };

    static defaultProps = {
        folder: false,
        root: false,
    };

    shouldComponentUpdate({id}) {
        return id !== this.props.id;
    }

    renderRoot({canEdit, canView}) {
        return (
            <div className="navbar-tree__dropdown">
                <DropdownButton bsStyle={null} title="" id="actions" onSelect={this.props.onSelect}>
                    <MenuItem disabled={!canView} eventKey="settings">
                        Open
                    </MenuItem>
                    <MenuItem disabled={!canEdit} eventKey="add">
                        Add Country…
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="expand">
                        Expand all
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="collapse">
                        Collapse all
                    </MenuItem>
                </DropdownButton>
            </div>
        );
    }

    renderFolder({canEdit, canView}) {
        return (
            <div className="navbar-tree__dropdown">
                <DropdownButton bsStyle={null} title="" id="actions" onSelect={this.props.onSelect}>
                    <MenuItem disabled={!canView} eventKey="settings">
                        Open
                    </MenuItem>
                    <MenuItem disabled={!canEdit} eventKey="add">
                        Add Agency…
                    </MenuItem>
                    <MenuItem disabled={!canEdit} eventKey="rename">
                        Rename…
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="expand">
                        Expand all
                    </MenuItem>
                    <MenuItem disabled={!canView} eventKey="collapse">
                        Collapse all
                    </MenuItem>
                </DropdownButton>
            </div>
        );
    }

    renderLeaf({canEdit, canView}) {
        return (
            <div className="navbar-tree__dropdown">
                <DropdownButton bsStyle={null} title="" id="actions" onSelect={this.props.onSelect}>
                    <MenuItem disabled={!canView} eventKey="settings">
                        Open
                    </MenuItem>
                    <MenuItem disabled={!canEdit} eventKey="rename">
                        Rename…
                    </MenuItem>
                    <MenuItem disabled={!canEdit} eventKey="move">
                        Move…
                    </MenuItem>
                </DropdownButton>
            </div>
        );
    }

    render() {
        let {folder, root} = this.props;
        const canEdit = hasAccess('modify_agency_tree');
        const canView = hasAccess('section:branch');
        if (root) return this.renderRoot({canEdit, canView});
        if (folder) return this.renderFolder({canEdit, canView});

        return this.renderLeaf({canEdit, canView});
    }
}

class InheritanceColumns extends React.Component {
    static propTypes = {
        columns: PropTypes.array,
        inheritance: PropTypes.object,
    };

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps, this.props);
    }

    renderInhMark(field, enabled = null) {
        const label = settings.SYSTEM_LABELS[field];
        if (enabled !== false) {
            return (
                <span key={field} className="nav-tree__inh-icon">
                    {label}
                </span>
            );
        }

        return (
            <OverlayTrigger key={field} overlay={<Tooltip id={field}>{INHERITS_TOOLTIP}</Tooltip>}>
                <span className="nav-tree__inh-icon nav-tree__inh-icon--on">{label}</span>
            </OverlayTrigger>
        );
    }

    render() {
        const {columns, inheritance = {}} = this.props;

        return (
            <div className="nav-tree__inh-icons">
                {columns.map(field => this.renderInhMark(field, inheritance[field]))}
            </div>
        );
    }
}

class LazyChildren extends React.Component {
    static propTypes = {
        ids: PropTypes.array.isRequired,
        expanded: PropTypes.object,
        visible: PropTypes.object,
        tree: PropTypes.object,
    };

    render() {
        const {ids, ...props} = this.props;
        const {visible, expanded, tree} = this.props;

        const {before, body, after} = splitVisible(ids, visible, expanded, tree);

        return (
            <ul className="nav-tree__list">
                <div style={{height: before * ELEMENT_H}} />
                {body.length > 0 && body.map(childId => <TreeNode {...props} key={childId || 'new'} id={childId} />)}
                <div style={{height: after * ELEMENT_H}} />
            </ul>
        );
    }
}

/**
 * @class TreeNode
 */
class TreeNode extends React.Component {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
        parentId: PropTypes.number,
        root: PropTypes.bool,
        disableRoot: PropTypes.bool,
        folderOnly: PropTypes.bool,
        leafOnly: PropTypes.bool,
        radios: PropTypes.bool,
        treeName: PropTypes.string,
        disabled: PropTypes.object.isRequired,
        selected: PropTypes.object.isRequired,
        parentSelected: PropTypes.bool,
        parentPartSelected: PropTypes.bool,
        partSelected: PropTypes.object.isRequired,
        multiSelect: PropTypes.bool.isRequired,
        expanded: PropTypes.object.isRequired,
        autoFocus: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        onDropdownClick: PropTypes.func.isRequired,
        onExpand: PropTypes.func.isRequired,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        columns: PropTypes.array,
        matrix: PropTypes.object,
        updateDetail: PropTypes.bool,
        dropdown: PropTypes.bool.isRequired,
        list: PropTypes.object.isRequired,
        tree: PropTypes.object.isRequired,
        uptree: PropTypes.object.isRequired,
    };

    static defaultProps = {
        onBlur: noop,
        onFocus: noop,
        onChange: noop,
        disableRoot: false,
        folderOnly: false,
        leafOnly: false,
    };

    constructor(props) {
        super(props);

        bindAll(this, 'onToggleExpanded', 'handleCheck', 'handleChange', 'handleClick', 'handleDropdown');
    }

    shouldComponentUpdate(nextProps) {
        const oldOwnProps = this.ownProps(this.props);
        const newOwnProps = this.ownProps(nextProps);

        if (oldOwnProps.deepNode || newOwnProps.deepNode) {
            return true;
        }

        return !isEqual(newOwnProps, oldOwnProps);
    }

    ownProps(props) {
        const {
            id,
            edit,
            expanded,
            selected,
            leafOnly,
            disableRoot,
            folderOnly,
            multiSelect,
            partSelected,
            disabled,
            matrix,
            updateDetail,
            parentSelected,
            parentPartSelected,
            tree,
            list,
            columns,
            autoFocus,
        } = props;
        const children = tree[id] || [];
        const node = list[id] || {};
        const isEditing = edit[id];
        const isExpanded = expanded[id];
        const isSelected = (parentSelected && multiSelect) || selected[id] === true;
        const isPartSelected = (parentPartSelected && multiSelect) || partSelected[id] === true;
        const isDisabled = disabled[id] === true;
        const notClickable =
            (folderOnly && !node.isFolder) || (leafOnly && node.isFolder) || (disableRoot && !node.parent);
        const isClickable = !notClickable;

        return {
            isEditing,
            isExpanded,
            isSelected,
            isPartSelected,
            isDisabled,
            isClickable,
            autoFocus,
            multiSelect,
            updateDetail,
            children,
            node,
            columns,
            inheritance: matrix && matrix[id],
            deepNode: children.length && isExpanded,
        };
    }

    getNodeTitle = () => {
        const {node} = this.ownProps(this.props);
        if (node?.title?.length > 35) {
            return `${node.title.slice(0, 35)}...`;
        }
        return node.title;
    };

    onToggleExpanded(event) {
        event.preventDefault();
        event.stopPropagation();

        let {id, expanded} = this.props;
        let value = !expanded[id];

        this.props.onExpand({id, value});
    }

    handleChange(event) {
        let {id, parentId: parent} = this.props;
        this.props.onChange({title: event.target.value, id, parent});
    }

    handleCheck() {
        this.props.onClick(this.props.id);
    }

    handleClick() {
        if (this.props.multiSelect) {
            return;
        }
        this.props.onClick(this.props.id);
    }

    handleDropdown(action) {
        const {id, list, onExpand, onDropdownClick} = this.props;
        if (action === 'expand') {
            onExpand({id: id, recursive: true, value: true});
        }
        else if (action === 'collapse') {
            onExpand({id: id, recursive: true, value: false});
        }
        else {
            onDropdownClick(action, id, list[id]?.title, list[id]['is_deprecated']);
        }
    }

    renderCheckbox(isSelected, isDisabled) {
        const {multiSelect, radios} = this.props;
        const checkboxLabelClass = classnames({
            'control--disabled': isDisabled,
        });
        if (!multiSelect && !radios) {
            return null;
        }

        const wrapperClass = classnames({
            checkbox: !radios,
            'checkbox-rev': !radios,
            radio: radios,
            'radio-rev': radios,
        });
        const iconClass = classnames({
            checkbox__icon: !radios,
            radio__icon: radios,
        });

        return (
            <div className={wrapperClass}>
                <label className={checkboxLabelClass}>
                    <input
                        type={radios ? 'radio' : 'checkbox'}
                        onChange={this.handleCheck}
                        checked={isSelected}
                        disabled={isDisabled}
                    />
                    <span className={iconClass} />
                </label>
            </div>
        );
    }

    renderDropdown(node) {
        let {id, treeName, dropdown, root} = this.props;
        const {isFolder, is_deprecated: isDeprecated} = node;
        if (!dropdown) {
            return null;
        }
        let isCompany = treeName === 'companies';
        let DropdownActions = isCompany ? CompanyActions : AgencyActions;

        return (
            <DropdownActions
                id={id}
                key={id + isDeprecated}
                folder={isFolder}
                isDeprecated={isDeprecated}
                root={root}
                onSelect={this.handleDropdown}
            />
        );
    }

    renderColumns(inheritance) {
        const {columns} = this.props;
        if (!columns) {
            return null;
        }
        return <InheritanceColumns columns={columns} inheritance={inheritance} />;
    }

    renderUpdatedAgency(item) {
        return (
            <table className="nav-tree__table nav-tree__table--align-left">
                <tbody>
                    <tr>
                        <td style={{width: 400}}>{format.date(item.date_updated, 'longtz')}</td>
                        <td style={{width: 400}}>
                            {item.updated_by_email && format.normalizeEmail(item.updated_by_email)}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    renderUpdatedCompany(item) {
        return (
            <table className="nav-tree__table nav-tree__table--align-left">
                <tbody>
                    <tr>
                        <td style={{width: 170}}>{item.tspm_entity_id}</td>
                        <td style={{width: 170}}>{item.smid}</td>
                        <td style={{width: 150}}>{item.obt_client_id}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    renderUpdated(item) {
        let isCompany = this.props.treeName === 'companies';
        return isCompany ? this.renderUpdatedCompany(item) : this.renderUpdatedAgency(item);
    }

    renderChildren(children, isSelected, isPartSelected, props) {
        if (!children || !children.length) {
            return false;
        }

        return (
            <LazyChildren
                ids={children}
                {...props}
                root={false}
                parentSelected={isSelected}
                parentPartSelected={isPartSelected}
                parentId={this.props.id}
            />
        );
    }

    render() {
        let {
            isClickable,
            isEditing,
            isExpanded,
            isSelected,
            isPartSelected,
            isDisabled,
            updateDetail,
            multiSelect,
            node,
            children,
            inheritance,
            autoFocus,
        } = this.ownProps(this.props);

        let itemClass = classnames({
            'nav-tree__item': true,
            'nav-tree__item--selected': !multiSelect && isSelected,
            'nav-tree__item--disabled': isDisabled,
            'nav-tree__item--expanded': isExpanded && node.isFolder,
            'nav-tree__item--no-child': !node.isFolder,
        });

        let iconClass = classnames('nav-tree__icon', {
            'nav-tree__icon--no-child': !node.isFolder,
        });
        let folderClass = classnames({
            'navbar-tree__icon-folder': node.isFolder,
            open: isExpanded,
            'nav-tree__icon--no-child-folder': !node.isFolder,
        });
        let labelClass = classnames('nav-tree__title', {
            'checkbox--partially-checked': isPartSelected,
            'has-error': Boolean(node.error),
        });
        let clickableClass = classnames('nav-tree__inwrapper', {
            'nav-tree__editable': isEditing,
        });
        let clickableIntitleClass = classnames('nav-tree__intitle', {
            clickable: isClickable,
        });
        let formClass = classnames('form-control', {
            error: Boolean(node.error),
        });

        const nodeTitle = this.getNodeTitle();
        const fullNodeTitle = node?.title;
        const isLongTitle = fullNodeTitle?.length > 35;

        const title = (
            <span
                onClick={isClickable ? this.handleClick : undefined} //eslint-disable-line
                className={clickableIntitleClass}
            >
                <span className={folderClass} />
                {isClickable && this.renderCheckbox(isSelected || isPartSelected, isDisabled)}
                {node['is_deprecated'] && '[*DEPRECATED*]'} {nodeTitle}
            </span>
        );

        return (
            <li className={itemClass}>
                <div className="nav-tree__wrapper" ref="wrapper">
                    <div className={clickableClass}>
                        <i className={iconClass} onClick={this.onToggleExpanded} />
                        <span className={labelClass}>
                            {isEditing ? (
                                <div className="nav-tree__control">
                                    <input
                                        type="text"
                                        className={formClass}
                                        value={fullNodeTitle}
                                        onBlur={this.props.onBlur}
                                        onFocus={this.props.onFocus}
                                        onChange={this.handleChange}
                                        autoFocus={autoFocus}
                                    />
                                    {node.error && <span className="error-msg">{selectError(node.error)}</span>}
                                </div>
                            ) : isLongTitle ? (
                                <OverlayTrigger
                                    key={node.id * 2}
                                    overlay={<Tooltip id={node.id * 2}>{fullNodeTitle}</Tooltip>}
                                >
                                    {title}
                                </OverlayTrigger>
                            ) : (
                                title
                            )}
                            {this.renderDropdown(node)}
                        </span>
                    </div>

                    {updateDetail && this.renderUpdated(node)}
                    {this.renderColumns(inheritance)}
                </div>
                {isExpanded && this.renderChildren(children, isSelected, isPartSelected, this.props)}
            </li>
        );
    }
}

/**
 * @class TreeView
 */

export class ControlledTreeView extends React.Component {
    static propTypes = {
        rootId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        disabled: PropTypes.object,
        selected: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
        edit: PropTypes.object,
        partSelected: PropTypes.object,
        multiSelect: PropTypes.bool,
        multiSelectAutocomplete: PropTypes.bool,
        empty: PropTypes.bool,
        loading: PropTypes.bool,
        onClick: PropTypes.func,
        onChange: PropTypes.func,
        onDropdownClick: PropTypes.func,
        onPosition: PropTypes.func,
        fetchPartial: PropTypes.func.isRequired,
        expanded: PropTypes.object,
        autoFocus: PropTypes.bool,
        matrix: PropTypes.object,
        columns: PropTypes.array,
        dropdown: PropTypes.bool,
        list: PropTypes.object.isRequired,
        tree: PropTypes.object.isRequired,
        uptree: PropTypes.object.isRequired,
        scroll: PropTypes.number,
    };

    static defaultProps = {
        edit: {},
        selected: {},
        disabled: {},
        multiSelect: false,
        multiSelectAutocomplete: false,
        onClick: noop,
        onChange: noop,
        onDropdownClick: noop,
        dropdown: false,
        loading: false,
        matrix: null,
        expanded: {},
    };

    state = {};

    componentDidMount() {
        this.fixPosition();
    }

    componentDidUpdate(prevProps) {
        if (this.props.multiSelectAutocomplete && !isEqual(this.props.selected, prevProps.selected)) {
            this.fixPosition(difference(Object.keys(this.props.selected), Object.keys(prevProps.selected)));
            return;
        }
        if (
            (prevProps.empty && !this.props.empty) ||
            (!this.props.multiSelect && this.props.selected !== prevProps.selected)
        ) {
            this.fixPosition();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {empty, selected, multiSelect} = state;
        let {expanded=ControlledTreeView.expandSelectedNodes(props)} = state;

        const emptyChanged = empty && !props.empty;
        const selectedChanged = props.multiSelectAutocomplete ? selected !== props.selected : !multiSelect && selected !== props.selected;

        if (emptyChanged || selectedChanged) {
            expanded = {...expanded, ...ControlledTreeView.expandSelectedNodes(props)};
        }
        return {
            expanded,
            visible: findVisible(expanded, props.tree, props.uptree, props.scroll),
            empty,
            selected,
            multiSelect
        };
    }

    fixPosition(difference = null) {
        const {multiSelect, selected, tree, uptree, multiSelectAutocomplete} = this.props;
        const {expanded} = this.state;

        if (multiSelect && !multiSelectAutocomplete) {
            return;
        }
        let selectedKeys = Object.keys(ControlledTreeView.normalize(selected));
        const selectedKey = difference ? difference[0] : selectedKeys[selectedKeys.length - 1];
        //TO DO: need to fix selectedKey and to refactor conditions below. Object.keys return not null, but `null`, the same with undefined.
        // So selectedKey should store  data in right format. After refactoring need to test carefully all trees.
        if (!selectedKey || selectedKey === 'undefined') {
            return;
        }
        const selectedId = selectedKey === 'null' ? null : Number(selectedKey);
        const newPos = nodePosition(selectedId, tree, uptree, expanded) * ELEMENT_H;
        this.props.onPosition(newPos);
    }

    static expandSelectedNodes({selected, partSelected, expanded, tree, uptree}) {
        selected = {
            ...ControlledTreeView.normalize(selected),
            ...ControlledTreeView.normalize(partSelected),
        };
        let ret = {...expanded};
        // eslint-disable-next-line no-unused-vars
        for (let nodeId in selected) {
            if (!selected[nodeId]) {
                continue;
            }
            // eslint-disable-next-line no-unused-vars
            for (let parentId of uptree[nodeId] || []) {
                ret[parentId] = true; // eslint-disable-line immutable/no-mutation
            }
        }
        // eslint-disable-next-line no-unused-vars
        for (let nodeId of tree.root) {
            ret[nodeId] = true; // eslint-disable-line immutable/no-mutation
        }
        return ret;
    }

    static normalize(selected) {
        if (!selected) {
            return {};
        }
        if (typeof selected === 'number') {
            return {[selected]: true};
        }
        return selected;
    }

    doExpand({id, recursive, value}) {
        let {expanded} = this.state;
        let {tree, uptree, list, scroll} = this.props;
        let ids = recursive ? recursiveChildren(id, tree) : [id];

        expanded = ids.reduce((acc, id) => {
            const node = list[id];
            return node.isFolder ? {...acc, [id]: value} : acc;
        }, expanded);

        let visible = findVisible(expanded, tree, uptree, scroll);
        this.setState({expanded, visible});
    }

    handleChange = value => {
        this.props.onChange(value);
    };

    handleExpand = ({id, recursive, value}) => {
        const done = value ? this.props.fetchPartial(id, recursive) : Promise.resolve();
        done.then(() => this.doExpand({id, recursive, value}));
    };

    renderEmpty() {
        return (
            <div className="central-alert">
                <h3>
                    You haven't been designated access to any companies.
                    <br />
                    Please contact an AgentSource Booking administrator to gain access.
                </h3>
            </div>
        );
    }

    render() {
        let {edit, loading, tree, rootId, selected, partSelected, autoFocus} = this.props;
        let {expanded, visible} = this.state;
        let root = rootId ? [rootId] : tree.root;
        selected = ControlledTreeView.normalize(selected);
        partSelected = ControlledTreeView.normalize(partSelected);
        if (!loading && (!root || !root.length)) {
            return this.renderEmpty();
        }

        return (
            <div className="nav-tree">
                <Loader loading={loading} />
                <ul className="nav-tree__list nav-tree__list--top-level">
                    {root.map(nodeId => (
                        <TreeNode
                            {...this.props}
                            onExpand={this.handleExpand}
                            onChange={this.handleChange}
                            expanded={expanded}
                            visible={visible}
                            key={nodeId}
                            id={nodeId}
                            root
                            edit={edit}
                            selected={selected}
                            partSelected={partSelected}
                            autoFocus={autoFocus}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}

export function bindDispatch(dispatch, {treeName}) {
    return {
        fetchPartial: (id, recursive) =>
            dispatch(fetchPartial(treeName, recursive ? {subtreeFor: id} : {siblingsFor: id})),
        markCompaniesAsNotLoaded: ids => dispatch(markCompaniesAsNotLoaded(ids))
    };
}

const TreeView = connect(function(state, props) {
    const {list, tree, uptree, loading, empty} = state[props.treeName];
    return {
        list,
        tree,
        uptree,
        empty: Boolean(empty),
        loading: Boolean(loading || props.loading),
    };
}, bindDispatch)(ControlledTreeView);

export default TreeView;
