import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchCategories, fetchDescriptions, postLabels} from 'midoffice/store/modules/bedTypes/actions';
import {getCategories, getDescriptions} from 'midoffice/store/modules/bedTypes/selectors';

import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';

import Radio from 'midoffice/newforms/widgets/Radio';
import PageTitle from 'midoffice/components/PageTitle';
import Button from 'midoffice/components/Button';

import modals from 'midoffice/modals';

const CATEGORY_LABELS = {
    room_type: 'Room Type',
    bed_types: 'Bed Type',
};

class RadioBox extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        field: PropTypes.string.isRequired,
    };

    handleRadios = value => {
        const {onClick, field} = this.props;
        onClick(value, field);
    };

    render() {
        return <Radio {...this.props} onChange={this.handleRadios} />;
    }
}

const mapStateToProps = state => {
    return {
        categories: getCategories(state) || [],
        descriptions: getDescriptions(state),
    };
};

function getDefaultValue(categories) {
    return categories.reduce(
        (acc, {name, many_accepted: manyAccepted}) => ({
            ...acc,
            [name]: manyAccepted ? [] : null,
        }),
        {}
    );
}

@connect(mapStateToProps, {
    fetchCategories,
    fetchDescriptions,
    postLabels,
})
export default class Labeling extends React.Component {
    static propTypes = {
        fetchCategories: PropTypes.func,
        fetchDescriptions: PropTypes.func,
        descriptions: PropTypes.array,
        postLabels: PropTypes.func,
        categories: PropTypes.array.isRequired,
    };
    state = {
        isOnProcessing: false,
    };

    componentDidMount() {
        const {fetchCategories, fetchDescriptions} = this.props;
        fetchCategories();
        fetchDescriptions();
    }

    static getDerivedStateFromProps({categories}, {value}) {
        if (!value && categories.length > 0) {
            return {
                value: getDefaultValue(categories),
            };
        }
        return null;
    }

    resetState = () => {
        this.setState({
            value: getDefaultValue(this.props.categories),
        });
    };

    getComponent(manyAccepted, name) {
        return manyAccepted
            ? {
                Component: MultiCheckbox,
                onChange: this.handleFields,
                border: false,
                table: false,
            }
            : {
                Component: RadioBox,
                onClick: this.handleFields,
                block: true,
                field: name,
            };
    }

    isSaveDisabled = () => {
        const {value} = this.state;
        const {descriptions, categories} = this.props;
        const enabled = categories.reduce((acc, {name, many_accepted: manyAccepted}) => {
            return manyAccepted ? value[name].length > 0 : Boolean(value[name]);
        }, false);
        return !enabled || descriptions.length === 0;
    };

    handleFields = (value, field) => {
        this.setState({
            value: {
                ...this.state.value,
                [field]: value,
            },
        });
    };

    handleSave = () => {
        const {descriptions, postLabels} = this.props;
        const {value} = this.state;
        const data = {
            descriptions: [
                {
                    object_id: descriptions[0]?.id,
                    ...value,
                },
            ],
        };
        this.setState({isOnProcessing: true});
        postLabels(data)
            .then(() => {
                this.resetState();
            })
            .catch(() => {
                modals.alert('Failed to save changes', 'danger');
            })
            .finally(() => {
                this.setState({isOnProcessing: false});
            });
    };

    renderCategory = ({name, many_accepted: manyAccepted, options}) => {
        const {value} = this.state;
        const {Component, ...extraProps} = this.getComponent(manyAccepted, name);
        return (
            <div className="form-group" key={name}>
                <label className="control-label col-xs-2">{CATEGORY_LABELS[name]}</label>
                <div className="col-xs-12">
                    <div className="multi-selector-body">
                        <Component
                            name={name}
                            value={value[name]}
                            choices={options.map(({key, name}) => [key, name])}
                            {...extraProps}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {categories, descriptions} = this.props;
        const {isOnProcessing} = this.state;
        const description =
            descriptions && descriptions.length !== 0
                ? descriptions[0].description
                : 'There are no rate descriptions left';
        return (
            <div className="container-full container-full--top-fix">
                <PageTitle>Description labeling</PageTitle>
                <header className="content-header">
                    <h3 className="content-header__title">Bed/Room Type Labeling Tool</h3>
                </header>
                <div className="bl-wrapper">
                    <div className="bl-content">
                        <div className="bl-content__text">{description}</div>
                    </div>
                    <div className="bl-sidebar">
                        <div className="bl-sidebar__content">
                            {categories &&
                                categories.map(this.renderCategory)}
                        </div>
                        <footer className="bl-sidebar__footer">
                            <Button
                                onClick={this.handleSave}
                                className="btn btn-primary btn-lg btn-block"
                                disabled={this.isSaveDisabled()}
                                progress={isOnProcessing}
                            >
                                Save and Next
                            </Button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}
