import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import settings from 'airborne/settings';
import {
    getList, isShowModal, isShowAddModal,
} from 'midoffice/store/modules/providerCredentials/selectors';
import {
    fetchProviderCreds,
    postCreds, setProviderCreds,
    resetForm, canDeleteProviderCred,
    showAddModal, editCredentials,
} from 'midoffice/store/modules/providerCredentials/actions';
import modals from 'midoffice/modals';

import Button from 'midoffice/components/Button';
import PageTitle from 'midoffice/components/PageTitle';
import DeleteCredsModal from 'midoffice/components/provider-credentials/DeleteCredsModal';
import AddCredsModal from 'midoffice/components/provider-credentials/AddCredsModal';
import {CommonTable} from 'midoffice/components/CommonTable';

class CredentialsRow extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        isEditMode: PropTypes.bool.isRequired,
        onRemove: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
    };

    handleRemove = () => {
        const {onRemove, value} = this.props;
        onRemove(value)
    }

    handleEdit = () => {
        const {onEdit, value} = this.props;
        onEdit(value)
    }

    render() {
        let {value, isEditMode} = this.props;
        const {username, name, id} = value;
        return (<tr>
                <td>{id}</td>
                <td>{name}</td>
                <td>{username}</td>
                {isEditMode && <td className="text-center">
                    <Button style={{marginRight: '10px'}} onClick={this.handleEdit} bsSize="small" bsStyle="default">
                        Edit
                    </Button>
                    <Button onClick={this.handleRemove} bsSize="small" bsStyle="default">
                        Remove
                    </Button>
                </td>}
            </tr>)
    }
}

const TABLE_HEADS = [
    {label: 'ID', field: 'id'},
    {label: 'Name', field: 'name'},
    {label: 'Username', field: 'username'},
    {label: '', field: ''},
];


class Editor extends React.Component {
    static propTypes = {
        isEditMode: PropTypes.bool.isRequired,
        value: PropTypes.array.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
    };

    handleRemove = (creds) => {
        const {onChange, value, onDelete} = this.props;
        if (creds.id) {
            onDelete(creds.id);
        }
        else {
            const newList = value.filter(({localId}) => (localId !== creds.localId));
            onChange(newList);
        }
    }

    handleEdit = (creds) => {
        const {onEdit} = this.props;
        onEdit(creds.localId);
    }

    render() {
        let {value, isEditMode} = this.props;

        return (
            <CommonTable
                    bsStyle="striped"
                    emptyLabel="No records."
                    heads={TABLE_HEADS}
                    selectable={false}
                >
                <tbody>
                    {value.map((elem, index)=>
                        <CredentialsRow key={index}
                                        isEditMode={isEditMode}
                                        value={elem}
                                        onRemove={this.handleRemove}
                                        onEdit={this.handleEdit}
                        />)}
                </tbody>
            </CommonTable>);
    }
}

const mapStateToProps = (state) => {
    return {
        showDeleteModal: isShowModal(state) || false,
        isShowAddModal: isShowAddModal(state) || false,
        credsList: getList(state) || [],
    };
};

@connect(mapStateToProps, {
    fetchProviderCreds,
    postCreds,
    setProviderCreds,
    resetForm,
    canDeleteProviderCred,
    editCredentials,
    showAddModal,
})
export default class ProviderCredentials extends React.Component {
    static propTypes = {
        credsList: PropTypes.array,
        resetForm: PropTypes.func.isRequired,
        canDeleteProviderCred: PropTypes.func.isRequired,
        editCredentials: PropTypes.func.isRequired,
        fetchProviderCreds: PropTypes.func.isRequired,
        showDeleteModal: PropTypes.bool.isRequired,
        isShowAddModal: PropTypes.bool.isRequired,
        showAddModal: PropTypes.func.isRequired,
        postCreds: PropTypes.func.isRequired,
    };

    state = {
        isOnProcessing: false,
    }

    componentDidMount() {
        this.props.fetchProviderCreds();
    }


    handleSave = () => {
        const {postCreds, credsList} = this.props;

        this.setState({isOnProcessing: true});
        postCreds({credentials: credsList})
            .then(() => {
                modals.alert('Saved');
            })
            .catch(() => {
                modals.alert('Failed to save changes', 'danger');
            })
            .finally(() => {
                this.setState({isOnProcessing: false});
            });
    };

    handleCancel = () => {
        this.props.resetForm();
    }

    handleAddModal = () => {
        this.props.showAddModal();
    }

    render() {
        const {isOnProcessing} = this.state;
        const {showDeleteModal, isShowAddModal} = this.props;
        const isEditMode = settings?.USER_ALL_PERMISSIONS.includes('midoffice:tools:gds_auth_details:edit');
        if (showDeleteModal) {
            return <DeleteCredsModal />;
        }
        if (isShowAddModal) {
            return <AddCredsModal />;
        }
        return (
            <>
                <PageTitle>Provider Сredentials</PageTitle>
                <div className="content-header content-header--tabs">
                    <header className="content-header">
                       <h3 className="content-header__title">Provider Сredentials</h3>
                    </header>
                </div>
                {isEditMode && <div className="row-wrapper row-wrapper--condensed">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="col-narrow">
                                <Button onClick={this.handleAddModal}
                                        bsSize="large" bsStyle="info"
                                        progress={isOnProcessing}>
                                    <span
                                        className="glyphicon glyphicon-plus"></span>
                                    Create New
                                    <div className="btn__spinner"></div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>}
                <div style={{marginBottom: '8%'}} className="row-wrapper row-wrapper--condensed">
                    <Editor
                        value={this.props.credsList}
                        isEditMode={isEditMode}
                        onDelete={this.props.canDeleteProviderCred}
                        onEdit={this.props.editCredentials}
                        onSubmit={this.handleSave} />
                </div>
                {isEditMode && <footer  className="sticky-controls sticky-controls--full-width bl-sidebar__footer">
                        <Button onClick={this.handleCancel} bsSize="large" bsStyle="info"
                            progress={isOnProcessing}
                        >
                            Cancel
                        </Button>
                        <Button onClick={this.handleSave}  bsSize="large"  bsStyle="primary"
                            progress={isOnProcessing}
                        >
                            Save
                        </Button>
                </footer>}
            </>
        );
    }
}
