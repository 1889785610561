import React from 'react';

import PropTypes from 'prop-types';

import {injectField} from 'midoffice/newforms/decorators';

import Input from 'midoffice/newforms/widgets/Input';
import Field from 'midoffice/newforms/components/Field';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';


@injectField
export default class Passenger extends React.Component {

    static propTypes = {
        value: PropTypes.shape({
            'first_name': PropTypes.string,
            'last_name': PropTypes.string,
        }).isRequired,
        errors: PropTypes.oneOfType([
            PropTypes.bool, PropTypes.object,
        ]),
        index: PropTypes.number.isRequired,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        removable: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleChange(value, name) {
        let {value: fullValue} = this.props;

        this.props.onChange({...fullValue, [name]: value});
    }

    handleRemove(event) {
        event.preventDefault();
        this.props.onRemove();
    }

    renderTrash() {
        let cls = 'form-group__tail-control';
        return (<a className={cls} href="#" onClick={this.handleRemove}>
            <Glyphicon glyph="trash" />
        </a>);
    }

    render() {
        const {value, errors, disabled, index, removable} = this.props;
        const first = index === 0;

        const extra = {
            inputCol: 7,
            inputSize: '',
            labelCol: 2,
            labelClassName: 'col-xs-offset-1',
            widget: Input,
            required: true,
            disabled,
        };

        return (<div className="row multifield-block">

            <Field name="first_name"
                   label="First Name"
                   placeholder="First Name"
                   value={value.first_name}
                   errors={errors && errors.first_name}
                   onChange={this.handleChange}
                   {...extra} />

            <Field name="last_name"
                   label="Last Name"
                   placeholder="Last Name"
                   value={value.last_name}
                   errors={errors && errors.last_name}
                   onChange={this.handleChange}
                   {...extra} />

            {removable && !first && this.renderTrash()}
        </div>);
    }
}
