import React from 'react';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import Input from 'midoffice/newforms/widgets/Input';
import Button from 'midoffice/components/Button';
import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';
import {connect} from 'react-redux';
import {
    sendResetPassword,
    changeResetPassword,
    identifyUser,
    authLoading
} from 'airborne/store/modules/login/actions/resetPassword';
import ResetPasswordSchema from './schemas/ResetPasswordSchema';
import ErrorsBlock from 'airborne/user_settings2/ErrorsBlock';
import settings from 'airborne/settings';
import {
    getUserSettingsErrors,
    getUserSettingsLoading,
    getUserSettingsValue
} from 'airborne/store/modules/user_settings/selectors/userSettings';

@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.object,
        loading: PropTypes.bool,
        errors: PropTypes.object,
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.onSubmit();
    }

    render() {
        const {value, errors, loading} = this.props;
        const extra = {
            labelCol: 2,
            inputCol: 12,
        };
        const resetPasswordError = errors && errors.errors && errors.errors[0].message;
        return (<div>
            <h3 className="floated-form__body__header">{gettext('Create a new password')}</h3>

            <p>{gettext('Please create a new password for')} <strong>{settings.USER.email}</strong></p>

            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <fieldset>
                    <Input.Field
                        name="password1"
                        type="password"
                        label={gettext('Password')}
                        placeholder={gettext('Password')}
                        {...extra} />
                    <Input.Field
                        name="password2"
                        type="password"
                        label={gettext('Confirm Password')}
                        placeholder={gettext('Confirm Password')}
                        {...extra} />
                    {<ErrorsBlock fullSize wrapper="form-group" value={value.password1}/>}
                </fieldset>
                {resetPasswordError && <section className="floated-form__error">
                    <strong>{resetPasswordError}</strong>
                </section>}
                <div className="floated-form__control">
                    <Button
                        type="submit"
                        bsStyle="info"
                        bsSize="large"
                        disabled={loading}
                        className="btn-block">
                        {gettext('Save Password')}
                    </Button>
                </div>

            </form>
        </div>);
    }
}

function isValidMail(mail) {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        value: getUserSettingsValue(state, 'resetPassword'),
        loading: getUserSettingsLoading(state, 'resetPassword'),
        errors: getUserSettingsErrors(state, 'resetPassword'),
    };
}

@connect((state) => mapStateToProps(state), {
    sendResetPassword,
    changeResetPassword,
    identifyUser,
    authLoading,
})
class ResetPassword extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        errors: PropTypes.object,
        loading: PropTypes.bool,
        changeResetPassword: PropTypes.func,
        sendResetPassword: PropTypes.func,
        identifyUser: PropTypes.func,
        history: PropTypes.object,
        auth: PropTypes.object,
        authLoading: PropTypes.bool,
    };

    componentDidMount() {
        const {authLoading, history, identifyUser, auth} = this.props;
        const [,,page,uid, token] = history.location.pathname.split('/');
        if (page === 'reset-password' && !auth.uid) {
            authLoading();
            const isEmail = isValidMail(uid);
            const flow = isEmail ? 'activate' : 'reset';
            identifyUser(uid, token, flow, history);
        }
        else if (page === 'activate' && !auth.uid) {
            authLoading();
            const query = new URLSearchParams(location.search);
            const email = query.get('email');
            const key = query.get('key');
            identifyUser(email, key,  'activate', history);
        }
    }

    handleChange = ({value, errors}) => {
        const newErrors = ResetPasswordSchema.fields.password2.validate(value.password1, value.password2);
        this.props.changeResetPassword(value, Object.assign(errors, newErrors));
    };

    handleSubmit = () => {
        const {changeResetPassword, value} = this.props;
        const formErrors = ResetPasswordSchema.validate(this.props.value);
        const password2 = ResetPasswordSchema.fields.password2.validate(value.password1, value.password2);
        const errors = {...formErrors, ...password2};
        if (noErrors(errors)) {
            this.props.sendResetPassword();
        }
        else {
            changeResetPassword(this.props.value, errors);
        }
    };

    render() {
        const {value, errors, loading} = this.props;
        return (
            <div>
                <Editor
                    onChange={this.handleChange}
                    schema={ResetPasswordSchema}
                    value={value}
                    loading={loading}
                    onSubmit={this.handleSubmit}
                    errors={errors}/>
            </div>
        );
    }
}

export default ResetPassword;
