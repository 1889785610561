'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/lib/Modal';


import {getRate} from 'airborne/store/modules/search_cars/selectors';

import {getRateDetails} from 'airborne/store/modules/search_cars/actions/rateDetails';

import Loader from 'airborne/search2/Loader';

import BookButton from './BookButton';
import RateDetails from './RateDetails';


export class ControlledRateDetailsModal extends React.Component {
    static propTypes = {
        rateId: PropTypes.number,
        rate: PropTypes.object,
        onHide: PropTypes.func,
        canBook: PropTypes.bool,
        loading: PropTypes.bool,
        preload: PropTypes.func,
    };

    componentDidMount() {
        const {preload, rateId} = this.props;
        if (preload) {
            preload(rateId);
        }
    }

    render() {
        const {onHide, rate, canBook, loading} = this.props;

        return (<Modal onHide={onHide} show className="modal--rate">
            <Modal.Header>
                <Modal.Title>
                    {gettext('Rate Details')}
                </Modal.Title>
                {canBook && !loading &&
                    <BookButton rateId={rate.rateId} className="btn btn-primary" >
                        {gettext('Book this car')}
                    </BookButton>}
            </Modal.Header>
            <Modal.Body>
                {loading
                    ? <Loader light wrapped/>
                    : <RateDetails {...rate} />}
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="default" onClick={onHide}>
                    {gettext('Close')}
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}


function mapStateProps(state, props) {
    const rate = getRate(state, props.rateId);
    return {
        rate: rate,
        loading: props.canBook && !rate.detailsLoaded,
    };
}

function mapDispatchProps(dispatch, props) {
    return {
        preload: (rateId)=> dispatch(getRateDetails(rateId, true)).catch(props.onHide)
    };
}

export default connect(mapStateProps, mapDispatchProps)(ControlledRateDetailsModal);
