import Row from 'react-bootstrap/lib/Row';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment/moment';

import gettext from 'airborne/gettext';

const MAX_SAFE_SEARCH_DAYS = 28;
function isSafe({min, max}) {
    if (!min || !max) {
        return true;
    }
    max = moment(max);
    min = moment(min);
    if (!min.isValid() || !max.isValid()) {
        return true;
    }

    return max.diff(min, 'days') <= MAX_SAFE_SEARCH_DAYS;
}
export const UnsafeSearchWarning = ({dates}) => {
    if (!dates || isSafe(dates)) {
        return null;
    }

    return (<Row className="form-warning__wrapper">
        <div className="form-warning">
            {gettext('Due to supplier and hotel constraints, stays longer than 28 days may have reduced availability.')}
        </div>
    </Row>);
};

UnsafeSearchWarning.propTypes = {
    dates: PropTypes.object,
};
