import React from 'react';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import Button from 'midoffice/components/Button';
import {connect} from 'react-redux';
import {changeLoginValue} from 'airborne/store/modules/login/actions/login';
import {mapStateToProps} from 'airborne/user_settings2/helpers/formHelpers';

@connect((state) => mapStateToProps(state, 'forgotPassword'), {onChange: changeLoginValue})
class ForgotPasswordSuccess extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            'email': PropTypes.string,
        }),
        onChange: PropTypes.func,
        loading: PropTypes.bool,
        history: PropTypes.object,
    };
    handleContinue = () => {
        const {history} = this.props;
        history.push('/accounts/login/');
    }

    render() {
        const {value, loading} = this.props;
        return (
            <div>
                <h3 className="floated-form__body__header">{gettext('Reset your password')}</h3>

                <p>{gettext('An email with instructions has been sent to')} <br />
                    <strong>{value.email}</strong>
                </p>

                <form className="form-horizontal">
                    <div className="floated-form__control">
                        <Button
                            bsSize="large"
                            type={'submit'}
                            disabled={loading}
                            onClick={this.handleContinue}
                            className="btn-secondary btn-block">{gettext('Continue')}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}


export default ForgotPasswordSuccess;
