import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';
import {formatCode} from 'airborne/utils/price';

import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import Button from 'midoffice/components/Button';
import Highlight from 'airborne/homepage2/Highlight';

import {setCurrency} from 'airborne/store/modules/header/actions/i18n';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';
import {getStep} from 'airborne/store/modules/homepage/selectors/homepage';
import Select from 'midoffice/newforms/widgets/Select';
import {isAirExchangeFlow} from 'airborne/store/modules/exchange_air/selectors';

function formatCurrencies(currency) {
    if (currency[0] === '___') {
        return [currency[0], `$€£ • ${currency[1]}`];
    }
    if (currency[0] === '...') {
        return [currency[0], `$€£ • ${currency[1]}`];
    }
    return [currency[0], `${currency[0]} • ${currency[1]}`];
}

function getCurrencies() {
    return settings.CURRENCIES.map(formatCurrencies);
}

function formatValue(value) {
    if (!value) {
        return null;
    }
    return value;
}

function mapStateProps(state) {
    return {
        currency: getCurrency(state),
        show: getStep(state) !== 'checkout' && getStep(state) !== 'settings' && !isAirExchangeFlow(state),
    };
}

@connect(mapStateProps, {setCurrency})
export default class CurrencyDropdown extends React.Component {
    static propTypes = {
        currency: PropTypes.string,
        setCurrency: PropTypes.func.isRequired,
        show: PropTypes.bool,
    };

    state = {
        opened: false,
        value: this.props.currency
    };

    // eslint-disable-next-line react/sort-comp
    handleDropdown = (opened, event) => {
        if (!event) return;
        this.setState({opened});
    };

    handleSetCurrency = () => {
        const {value} = this.state;
        if (value) {
            this.props.setCurrency(value);
            this.setState({
                opened: false,
            });
        }
    };

    handleChange = value => {
        this.setState({value: formatValue(value)});
    };

    resetCurrency = () => {
        this.setState({
            value: getCurrencies()[0][0]
        });
    }

    render() {
        const {currency, show} = this.props;

        if (!show || !currency) {
            return null;
        }

        return (
            <>
                <Highlight id="company-currency" fancy placement="bottom">
                    <NavDropdown
                        onToggle={this.handleDropdown}
                        eventKey="currency"
                        open={this.state.opened}
                        title={formatCode(currency)}
                        id="currency"
                    >
                        <div className="navbar-nav__form">
                            <div className="form-group">
                                <label className="control-label col-xs-2">
                                    {gettext('Choose a currency')}
                                </label>
                                <div className="col-xs-12">
                                    <div className="input-max custom-select">
                                        <Select
                                            name="currency"
                                            inputSize="max"
                                            onChange={this.handleChange}
                                            choices={getCurrencies()}
                                            value={this.state.value}
                                            placeholder={gettext('Select Currency')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="navbar-nav__form__action">
                                <Button onClick={this.resetCurrency} className="btn btn-link btn-link--narrow btn-xs pull-right">
                                    {gettext('Reset to default currency')}
                                </Button>
                            </div>

                            <footer className="navbar-nav__form__footer">
                                <Button
                                    onClick={this.handleSetCurrency}
                                    className="btn btn-primary"
                                >
                                    {gettext('Apply')}
                                </Button>
                            </footer>
                        </div>
                    </NavDropdown>
                </Highlight>
            </>
        );
    }
}
