import CancellationPolicyList from 'airborne/checkout2/CancellationPolicy';

'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import {TabContent} from 'midoffice/components/TabsWithContent';
import gettext from 'airborne/gettext';
import settings from 'airborne/settings';
import {BreakLines, Line, List, Fees, Desc} from 'midoffice/components/RateDetails';
import amenityFormat from 'airborne/search2/amenities';
import {formatStrict as formatPrice} from 'airborne/utils/price';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import CheckinInstructions from 'midoffice/components/hotelRateDetails/CheckinInstructions';
import Button from 'react-bootstrap/lib/Button';
import {displayBedTypes, displayRoomType} from 'airborne/search2/helpers/rates';

function raw(html) {
    return html ? (<div dangerouslySetInnerHTML={{__html: html}}/>)
        : null;
}

function reasonable(after, before) {
    return (after && before) && (Math.round(after - before));
}

function getTaxes(taxes, after, before, currency) {
    if (taxes) {
        return {
            money: taxes,
            currency,
        };
    }
    if (reasonable(after, before)) {
        return {
            money: after - before,
            currency: currency,
        };
    }

    return null;
}


export default class RateInfoTab extends React.Component {
    static propTypes = {
        'room_description': PropTypes.string,
        'rate_details_description': PropTypes.string,
        'rate_description': PropTypes.string,
        'detailed_rate_description': PropTypes.string,
        'plan_name': PropTypes.string,
        'additional_rate_information': PropTypes.string,
        'description': PropTypes.string,
        'rate_inclusions_list': PropTypes.string,
        'per_night': PropTypes.array,
        'currency': PropTypes.string,
        'tax_details': PropTypes.array,
        'total_after_taxes': PropTypes.number,
        'total_before_taxes': PropTypes.number,
        'total': PropTypes.number,
        'hotel_fees': PropTypes.array,
        'taxes': PropTypes.number,
        'voucher_observation': PropTypes.string,
        'bed_types': PropTypes.array,
        'max_occupancy': PropTypes.number,
        'rate_value_adds': PropTypes.array,
        'checkin_time': PropTypes.string,
        'checkout_time': PropTypes.string,
        'smoking_preferences': PropTypes.string,
        'cancellation_policy': PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),        'disclaimer': PropTypes.string,
        'reception': PropTypes.string,
        'promotion_details': PropTypes.string,
        'room_type': PropTypes.string,
        'rate_details_promoline': PropTypes.string,
        'rate_promoline': PropTypes.string,
        'deposit_policy': PropTypes.string,
        onWrongButton: PropTypes.func,
    };

    renderRates() {
        const {
            plan_name: planName,
            description,
            additional_rate_information: additionalRateInformation,
            rate_inclusions_list: rateInclusionsList,
            per_night: perNight,
            rate_details_promoline: rateDetailsPromoline,
            rate_promoline: ratePromoline,
            currency
        } = this.props;
        if (!perNight && !planName && !description) {
            return null;
        }
        const planNameOrPromoline = planName || rateDetailsPromoline || ratePromoline;

        return (<>
            <h4 className="modal-rate-desc__title">
                <strong>{gettext('Rate Information')}</strong>
            </h4>

            {planNameOrPromoline && (<div>
                <b>{planNameOrPromoline}</b>
            </div>)}

            <Line>
                <p>{description}</p>
            </Line>

            <div className="modal-rate-desc__row">
                <BreakLines>
                    {additionalRateInformation}
                </BreakLines>
            </div>

            <div className="modal-rate-desc__row">
                <BreakLines>
                    {rateInclusionsList}
                </BreakLines>
            </div>

            {perNight && <div>
                <strong>{gettext('Nightly rates')}:</strong>
                <ul className="modal-rate-desc__list">
                    {perNight.map((nightlyRate, idx) => (
                        <li key={idx}>
                            {nightlyRate.date}:&nbsp;
                            {formatPrice(nightlyRate.rate, currency)}
                        </li>
                    ))}
                </ul>
            </div>}
        </>);
    }

    renderTaxes() {
        const {
            tax_details: taxDetails,
            total_after_taxes: totalAfterTaxes,
            total_before_taxes: totalBeforeTaxes,
            currency,
            total,
            hotel_fees: hotelFees,
        } = this.props;
        const taxes = getTaxes(
            this.props.taxes,
            totalAfterTaxes,
            totalBeforeTaxes,
            currency,
        );
        return (<>
            <h4 className="modal-rate-desc__title">
                <strong>{gettext('Price Summary')}</strong>
            </h4>
            <div className="well well-sm">
                {Array.isArray(taxDetails)
                    ? Boolean(taxDetails.length) && (<List>{taxDetails}</List>)
                    : raw(taxDetails)}
            </div>

            <div className="well well-sm">
                <div>
                    {taxes && <>
                        {gettext('Total taxes and surcharges: ')}
                        <strong>
                            {formatPrice(taxes.money, taxes.currency)}
                        </strong>
                        <hr className="hr-condensed" />
                    </>}
                </div>
                <strong>
                    {gettext('Estimated total: ')}
                    {formatPrice(total || totalAfterTaxes || totalBeforeTaxes, currency)}
                </strong>
            </div>

            <div className="well well-sm">
                {hotelFees && (<Fees currency={currency} fees={hotelFees} />)}
            </div>
        </>);
    }

    render() {
        const {
            'voucher_observation': voucherObservation,
            'bed_types': bedTypes,
            'max_occupancy': maxOccupancy,
            'rate_value_adds': rateValueAdds,
            'room_description': roomDescription,
            'checkin_time': checkinTime,
            'checkout_time': checkoutTime,
            'detailed_rate_description': detailedRateDescription,
            'rate_details_description': rateDetailsDescription,
            'smoking_preferences': smokingPreferences,
            'rate_description': rateDescription,
            'cancellation_policy': cancellationPolicy,
            'deposit_policy': depositPolicy,
            disclaimer,
            reception,
            'room_type': roomType,
            'promotion_details': promotionDetails,
            onWrongButton,
        } = this.props;

        return (<TabContent>
            <div className="modal-rate-desc__wrap">
                <h4 className="modal-rate-desc__title">
                    <strong>{gettext('Description')}</strong>
                </h4>

                {raw(roomDescription)}

                <BreakLines>
                    {detailedRateDescription || rateDetailsDescription || rateDescription}
                </BreakLines>

                {voucherObservation && (<div className="well well-sm">{voucherObservation}</div>)}

                <div className="well well-sm">
                    <Line label={gettext('Room type')}>
                        <strong>{displayRoomType(roomType)}</strong>
                    </Line>
                    <Line label={gettext('Bed type')}>
                        <strong>{displayBedTypes(bedTypes)}</strong>
                    </Line>
                    {onWrongButton && (<Button className="btn-link--narrow btn-xs text-xs" bsStyle="link" onClick={onWrongButton}>
                        {gettext('Improve')}
                    </Button>)}

                </div>

                <div className="well well-sm">
                    <Line label={gettext('Max occupancy')}>
                        {maxOccupancy}
                    </Line>
                </div>

                <div className="well well-sm">
                    <BreakLines>
                        {rateValueAdds && rateValueAdds.length && rateValueAdds.map(amenityFormat) || null}
                    </BreakLines>
                </div>

                <div className="well well-sm">
                    <Line label={gettext('Smoking preferences')}>
                        {smokingPreferences}
                    </Line>
                </div>

                <div className="well well-sm">
                    <Line label={gettext('Check-in time')} italic>
                        {checkinTime}
                    </Line>

                    <Line label={gettext('Check-out time')} italic>
                        {checkoutTime}
                    </Line>
                </div>
            </div>


            <div className="modal-rate-desc__wrap">
                {this.renderRates()}
            </div>

            <div className="modal-rate-desc__wrap">
                {this.renderTaxes()}
            </div>

            <div className="modal-rate-desc__wrap">
                { disclaimer && (<div className="well well-sm">{ disclaimer }</div>) }
            </div>

            <div className="modal-rate-desc__wrap">
                <div className="well well-sm">
                    <Desc label={gettext('Reception Hours')}>
                        {raw(reception)}
                    </Desc>
                </div>
            </div>

            <div className="modal-rate-desc__wrap">
                {depositPolicy && (
                    <>
                        <h4 className="modal-rate-desc__title">
                            <strong>{gettext('Deposit Policy')}</strong>
                        </h4>
                        <p>{depositPolicy}</p>
                    </>
                )}
                <h4 className="modal-rate-desc__title">
                    <strong>{gettext('Cancelation Policy')}</strong>
                </h4>
                <div><CancellationPolicyList cancellationPolicy={cancellationPolicy} /></div>
                <Desc title={gettext('Promotion Details')}>
                    {promotionDetails && (
                        <BreakLines>{promotionDetails}</BreakLines>
                    )}
                </Desc>
            </div>

            <div className="modal-rate-desc__wrap">
                <CheckinInstructions {...this.props} />
            </div>
        </TabContent>);
    }
}
