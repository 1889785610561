import React from 'react';
import {Button} from 'react-bootstrap';
import gettext from 'airborne/gettext';
import {getTerms} from 'airborne/termsConditions/actions/terms';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

@connect(({termsConditions}) => ({
    text: termsConditions.text,
}))
class TermsConditions extends React.Component {
    static propTypes = {
        text: PropTypes.string,
    };

    componentDidMount() {
        getTerms();
        this.scrollTop();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    render() {
        const {text} = this.props;
        return (
            <div className="container" id="top">
                <div className="page-wrapper page-wrapper--conditions" style={{marginBottom: '30px'}}>
                    {text && <div dangerouslySetInnerHTML={{__html: text}}/>}
                    <hr />
                    <div className="clearfix">
                        <Button bsStyle="link" className="pull-right"
                            onClick={this.scrollTop}>{gettext('Return to top')}</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsConditions;
