import React from 'react';
import PropTypes from 'prop-types';
import {FareFamilyGroupShape} from './shapes';
import DescriptionTooltip from './DescriptionTooltip';
import isEmpty from 'lodash/isEmpty';
import gettext from 'airborne/gettext';
import OutOfPolicy from 'airborne/search_air/OutOfPolicy';
import {displayPriceDifference} from "airborne/search_air/helpers";
import {
    getOriginalFareTitle,
    isAllPriceClassesTheSame,
} from "airborne/checkout_air/helpers/brandedFares";

export const Title = ({fare, originalFare}) => {
    const {fareComponents, priceClassName, priceClassDescription} = fare;

    if (!isAllPriceClassesTheSame(fare)) {
        return (<>
            {originalFare && <div className="selector-grid__title branded-fares__multiple-title">
                {gettext('Original Fare:')}
            </div>}
            {fareComponents.map(({priceClassName, priceClassCode, priceClassDescription}, idx) => (
                <div key={idx} className="selector-grid__title branded-fares__multiple-title">
                    {priceClassName || priceClassCode}{' '}
                    {priceClassDescription && <DescriptionTooltip text={priceClassDescription} />}
                </div>
            ))}
        </>);
    }

    const title = originalFare ? getOriginalFareTitle(fare) : priceClassName;

    return <div className="selector-grid__title">
        {title || gettext('Branded fare')}{' '}
        {priceClassDescription && <DescriptionTooltip text={priceClassDescription} />}
    </div>;
}

const Header = ({
    fare,
    fare: {
        currency,
        total,
        outOfPolicyRules,
        unbookableReasons,
    },
    originalFare,
    priceDifference,
    policyRules,
    lang,
    hideHeaderCheckbox,
}) => {

    const priceDifferenceText = displayPriceDifference(priceDifference, currency);
    const isUnbookable = !isEmpty(unbookableReasons);

    return (
        <header className="selector-grid__header">
            {/* DES-699 Upsell Boxes Provider */}
            {/* <div className="selector-grid__header__code">NDC</div> */}

            {!hideHeaderCheckbox && <div className="selector-grid__checkbox" />}
            <h3>
                <Title fare={fare} originalFare={originalFare} />

                <div className="selector-grid__sub-title">
                    <span className="selector-grid__value">
                        {isUnbookable
                            ? <span className="highlight-red">{gettext('Unavailable by your company settings.')}</span>
                            : <>
                                {priceDifferenceText}
                                {!isEmpty(outOfPolicyRules) &&
                                    <>
                                        {' '}•{' '}
                                        <OutOfPolicy
                                            policyRules={policyRules}
                                            outOfPolicyRules={outOfPolicyRules}
                                            lang={lang} />
                                    </>
                                }
                            </>
                        }

                    </span>

                    <span className="selector-grid__price">
                        <span className="text-sm">{currency}</span>{' '}
                        <strong>{total.toFixed(2)}</strong>
                    </span>
                </div>
            </h3>
        </header>
    );
};

Header.propTypes = {
    fare: FareFamilyGroupShape.isRequired,
    priceDifference: PropTypes.number.isRequired,
    title: PropTypes.string,
    lang: PropTypes.string,
};

export default Header;
