import {createSelector} from 'reselect';
import get from 'lodash/get';
import head from 'lodash/head';
import flatMap from 'lodash/flatMap';
import {extractFareKeys, getUniqueFareKey} from 'airborne/search_air/helpers';
import {getFareGroupStoreKey} from './fareKeys';
import {getCheckoutFareGroup, getSeparatedTicketsCheckoutFareGroups} from './fareFamilies';
import {isAirSeparatedTicketsMode} from "airborne/store/modules/pricing_air/selectors";

function getFlightPrice(state) {
    return state.air.flightPrice;
}

export function getFlightPriceData(state) {
    return getFlightPrice(state).data;
}

export function isFlightPriceLoading(state) {
    return getFlightPrice(state).loading;
}

export function getFlightPriceRequests(state) {
    return getFlightPrice(state).requests;
}

function getLastRequestParams(state) {
    return getFlightPrice(state).lastRequestParams;
}

export const getSelectedFlightPrice = createSelector(
    getFlightPriceData,
    getFareGroupStoreKey,
    (flightPrices, key) => get(flightPrices, key)
);

export function getSeparatedTicketsFlightPrices(state) {
    const isSeparatedTickets = isAirSeparatedTicketsMode(state);
    const flightPrice = getSelectedFlightPrice(state);
    const checkoutFareGroup = getCheckoutFareGroup(state);

    if (!isSeparatedTickets) {
        // flight price request might not be finished yet to the moment when another fare family is selected
        // in this case we fallback to checkout fare group
        return [flightPrice ?? checkoutFareGroup];
    }

    return [
        getSelectedFlightPrice(state, 0),
        getSelectedFlightPrice(state, 1),
    ];
}

export const isSelectedFlightPriceAvailable = state =>
    Boolean(getSelectedFlightPrice(state));

export const getFlightPriceByKeys = (state, fareGroupKey, flightOptionKeys) => {
    const flightPriceData = getFlightPriceData(state);
    const storeKey = getUniqueFareKey({fareGroupKey, flightOptionKeys});

    return flightPriceData[storeKey];
};

export function getFlightPriceErrors(state) {
    return getFlightPrice(state).errors;
}

export const getOriginDestinations = createSelector(
    getCheckoutFareGroup,
    isAirSeparatedTicketsMode,
    getSeparatedTicketsCheckoutFareGroups,
    (fareGroup, isSeparatedTickets, separatedTicketsFareGroups) => {
        if (isSeparatedTickets) {
            return flatMap(separatedTicketsFareGroups, fareGroup => fareGroup.originDestinations);
        }

        return fareGroup.originDestinations;
    }
);

export const getSegments = (state, ticketIndex) => {
    const separatedTicketsFareGroups = getSeparatedTicketsCheckoutFareGroups(state);

    const originDestinations = ticketIndex
        ? separatedTicketsFareGroups[ticketIndex].originDestinations
        : getOriginDestinations(state);

    return flatMap(originDestinations, ({segments}) => segments) || [];
};

export const getProviders = createSelector(
    getCheckoutFareGroup,
    isAirSeparatedTicketsMode,
    getSeparatedTicketsCheckoutFareGroups,
    (fareGroup, isSeparatedTickets, separatedTicketsFareGroups) => {
        if (isSeparatedTickets) {
            return flatMap(separatedTicketsFareGroups, fareGroup => fareGroup.provider);
        }

        return [fareGroup.provider];
    }
);

export const getCurrency = createSelector(
    getCheckoutFareGroup,
    isAirSeparatedTicketsMode,
    getSeparatedTicketsCheckoutFareGroups,
    (fareGroup, isSeparatedTickets, separatedTicketsFareGroups) => {
        return isSeparatedTickets
            ? head(separatedTicketsFareGroups).currency
            : fareGroup.currency;
    }
)

export const getSegmentById = (state, segmentIdRef, ticketIndex) => {
    const segments = getSegments(state, ticketIndex);
    return segments.find(segment => segment.segmentIdRef === segmentIdRef);
};

export function getLastRequestParamsByKeys(state, fareGroupKey, flightOptionKeys) {
    const key = getUniqueFareKey({fareGroupKey, flightOptionKeys});

    return getLastRequestParams(state)[key];
}

export const getSelectedFlightPriceTravelerPrice = (state) => {
    return getSelectedFlightPrice(state).travelerPrice;
};
