import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Glyphicons extends React.Component {
    static propTypes = {
        /**
         * bootstrap className
         * @private
         */
        className: PropTypes.string,
        bsClass: PropTypes.string,
        /**
         * An icon name. See e.g. http://getbootstrap.com/components/#glyphicons
         */
        glyph: PropTypes.string.isRequired,
        /**
         * Adds 'form-control-feedback' class
         * @private
         */
        formControlFeedback: PropTypes.bool,
        children: PropTypes.any,
    };

    static defaultProps = {
        bsClass: 'glyphicons',
        formControlFeedback: false
    };

    render() {
        const {className, bsClass, glyph, formControlFeedback, ...props} = this.props;
        const cls = classNames(className, {
            [bsClass]: true,
            ['glyphicons-' + glyph]: true,
            ['form-control-feedback']: formControlFeedback,
        });

        return (
            <span {...props} className={cls}>
                {this.props.children}
            </span>
        );
    }
}
