import PropTypes from 'prop-types';
import React, {FC, ReactElement, useCallback, useContext, useEffect} from 'react';

import gettext from 'airborne/gettext';
import {AxiosApiProvider} from 'airborne/helpers/axiosApiProvider';
import session from 'airborne/session';
import ferrariDependencies from 'airborne/microFrontEnds/ferrariDependencies';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {getLang, getI18N} from 'airborne/store/modules/header/selectors/i18n';
import {getOptionsParams} from 'airborne/store/modules/homepage/selectors/homepage';
import {
    getPnrForm,
    getPnrOid,
    getPnrProfile,
    getProfileName,
} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getTspm} from 'airborne/store/modules/homepage/selectors/tspm';
import systemData from 'airborne/systemData';
import {hasAccess} from 'midoffice/helpers/permission';

const getMidasBundleUrl = () => {
    return systemData.common.MIDAS_FE_URL;
};

const getMidasBackendUrl = () => {
    return systemData.common.MIDAS_BE_URL;
};

const getASBAdminURL = () => systemData.common.ASB_ADMIN_URL;

const MicroFrontEndConfigs = {
    rail: {
        bundleUrlSetting: 'STADLER_URL',
        featureFlag: 'RAIL_ENABLED',
        name: 'rail',
    },
    'midas-fe': {
        bundleUrlSetting: 'MIDAS_FE_URL',
        featureFlag: 'MIDAS_ENABLED',
        name: 'midas-fe',
    },
    'ferrari': {
        bundleUrlSetting: 'FERRARI_URL',
        featureFlag: 'FERRARI_ENABLED',
        name: 'ferrari',
    },
};

type MicroFrontEndName = keyof typeof MicroFrontEndConfigs;

function bundleUrl(name: MicroFrontEndName) {
    const setting = MicroFrontEndConfigs[name].bundleUrlSetting;
    return process.env[setting] || `${systemData.common[setting]}?v=${new Date().getTime()}`;
}

export const MicroFrontEndsContext = React.createContext(
    {} as {
        loadMicroFrontEnd: (name: MicroFrontEndName) => void;
        render: (name: MicroFrontEndName) => void;
        getLoading: (name: MicroFrontEndName) => boolean;
        getError: (name: MicroFrontEndName) => unknown;
    }
);

export const MicroFrontEndsProvider: FC<{
    children: ReactElement;
    browserHistory: any;
    getState: any;
    settings: any;
    subscribe: any;
    axiosProvider: any;
}> = ({children, getState, settings, browserHistory, subscribe, axiosProvider}) => {
    const [loading, setLoading] = React.useState<{[key in MicroFrontEndName]: boolean}>({
        rail: false,
        'midas-fe': false,
        'ferrari': false,
    });

    const updateMicroFrontEndsSharedData = () => {
        // @ts-ignore
        window.microFrontEnds = {
            midas: {
                AxiosApiProvider: AxiosApiProvider,
                browserHistory,
                midasBundleURL: getMidasBundleUrl(),
                midasBackendURL: getMidasBackendUrl(),
                settings: {
                    DATE_FORMAT: settings.DATE_FORMAT,
                    USER_LOCALE: settings.USER_LOCALE,
                    ALL_PROVIDERS: settings.ALL_PROVIDERS,
                    PROVIDER_CODES: settings.PROVIDER_CODES,
                    CURRENCIES: settings.CURRENCIES,
                    COUNTRIES: settings.COUNTRIES,
                    AIRLINES: settings.AIRLINES,
                },
                selectors: {
                    getI18N: () => getI18N(getState()),
                    getPnrProfile: (slotIndex: number) => {
                        const profile = getPnrProfile(getState(), slotIndex);
                        return {...profile, profileName: getProfileName(profile)};
                    },
                    getOptionsParams: () => getOptionsParams(getState()),
                },
            },
            rail: {
                browserHistory,
                subscribe,
                utilities: {
                    hasAccess,
                    getASBAdminURL,
                },
                settings: {
                    USER_LOCALE: settings.USER_LOCALE,
                    USER: settings.USER,
                    COUNTRIES: settings.COUNTRIES,
                },
                selectors: {
                    getTspm: () => (index: number) => getTspm(getState(), index), // TSPM data for traveler
                    getOptionsParams: () => getOptionsParams(getState()), // optionsParams.configuration_id
                    getLang: () => getLang(getState()),
                    getPnrOid: () => getPnrOid(getState()),
                    getPnrProfile: (slotIndex: number) => {
                        const profile = getPnrProfile(getState(), slotIndex);
                        return {...profile, profileName: getProfileName(profile)};
                    }, // pnr profile with proper name
                },
                getSessionId: () => session.get('sid'),
            },
            airborne: {
                utilities: {
                    hasAccess,
                    getASBAdminURL,
                },
                sharedComponents: {
                    ferrari: ferrariDependencies,
                },
                browserHistory,
                settings,
                gettext: gettext,
                getSessionId: () => session.get('sid'),
                selectors: {
                    getPnrProfile: (slotIndex: number) => {
                        const profile = getPnrProfile(getState(), slotIndex);
                        return {...profile, profileName: getProfileName(profile)};
                    }, // pnr profile with proper name
                    getPnrForm: () => getPnrForm(getState()), // extra data on company like countryName
                    getFeatureFlag: (flag: string) => getFeatureFlag(getState(), flag), // feature flags
                    getTspm: () => (index: number) => getTspm(getState(), index), // TSPM data for traveler
                    getOptionsParams: () => getOptionsParams(getState()), // optionsParams.configuration_id
                    getLang: () => getLang(getState()),
                    getPnrOid: () => getPnrOid(getState()),
                },
                subscribe,
                axiosProvider,
                __getState: getState, // agreed to use explicit methods to get data from airborne store, leaving this for urgent cases
            },
        };
    };

    const [errors, setErrors] = React.useState<{[key in MicroFrontEndName]?: unknown}>({});
    const loadMicroFrontEnd = useCallback(
        (name: MicroFrontEndName, defer = false) => {
            updateMicroFrontEndsSharedData();
            // We can't check feature flag here because
            // 1. Feature flags are loaded after homepage render, and we want to start load some microFEs as soon as possible
            // 2. Checking it here does not make sense, because we already have tab or route specific to microFE which is already shown or not
            // and this check has no impact on it.
            // So for example if we want to show/hide rail tab on homepage, we will already show/hide rail tab in airborne
            // and this check will not change anything.
            // if (
            //     'featureFlag' in MicroFrontEndConfigs[name] &&
            //     !getFeatureFlag(null, MicroFrontEndConfigs[name].featureFlag)
            // ) {
            //     return;
            // }
            if (document.getElementById(`${name}-script`) || loading[name]) {
                // already loaded
                return;
            }
            setLoading({...loading, [name]: true});
            const script = document.createElement('script');
            script.src = bundleUrl(name);
            if (defer) {
                script.defer = true;
            }
            script.id = `${name}-script`;
            script.onload = () => {
                setLoading({...loading, [name]: false});
            };
            script.onerror = error => {
                // should catch both loading and script errors
                setLoading({...loading, [name]: false});
                setErrors({...errors, [name]: error});
            };
            document.body.appendChild(script);
        },
        [loading, errors]
    );
    const getError = useCallback((name: MicroFrontEndName) => errors[name], [errors]);
    const getLoading = useCallback((name: MicroFrontEndName) => loading[name], [loading]);
    const render = useCallback(
        (name: MicroFrontEndName) => {
            (window as any)?.[name]?.render?.(); // we don't know if script updated window object yet
        },
        [loading, errors]
    );

    useEffect(() => {
        updateMicroFrontEndsSharedData();
    }, []);

    return (
        <MicroFrontEndsContext.Provider value={{loadMicroFrontEnd, render, getLoading, getError}}>
            {children}
        </MicroFrontEndsContext.Provider>
    );
};
MicroFrontEndsProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export const MicroFrontEnd = ({name}: {name: MicroFrontEndName}) => {
    const {getError, getLoading, render, loadMicroFrontEnd} = useContext(MicroFrontEndsContext);
    useEffect(() => {
        loadMicroFrontEnd(name);
        render(name);
    }, []);

    if (getLoading(name)) {
        return <div id={name}>Loading...</div>;
    }
    if (getError(name)) {
        return <div id={name}>Failed to load =(</div>;
    }
    return <div id={name} />;
};
