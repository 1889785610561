import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {changeForm} from 'midoffice/data/actions/pnrTool';
import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';

import Input from 'midoffice/newforms/widgets/Input';
import SelectAutocomplete from 'midoffice/newforms/widgets/SelectAutocomplete';

import {PNRRetrieveSchema} from 'midoffice/data/pnrTool';
import {companyAutocomplete} from 'airborne/homepage2/autocomplete/CompanyAutocomplete';

import Col from 'react-bootstrap/lib/Col';
import Button from 'midoffice/components/Button';
import RowWrapper from 'midoffice/components/RowWrapper';
import Form from 'midoffice/newforms/components/SimpleForm';


@injectFormContext
class RetrieveForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const {onSubmit} = this.props;
        const extra = {
            labelCol: 2,
            inputCol: 8,
        };

        return (
            <Form onSubmit={onSubmit}>
                <SelectAutocomplete.Field
                    name="entity"
                    label="Entity"
                    autocompleteSource={companyAutocomplete}
                    {...extra}
                    withCompanyInfo />
                <Input.Field name="office_id" label="OID/PCC" disabled {...extra} />
                <Input.Field name="pnr" label="PNR Number" {...extra} />

                <div className="form-group">
                    <Col xs={11}>
                        <Button bsStyle="primary"
                            className="pull-right"
                            onClick={onSubmit}
                        > Retrieve </Button>
                    </Col>
                </div>
            </Form>
        );
    }
}


@connect(({'pnr-tool': {retrieveForm}})=> {
    const {value, errors} = retrieveForm;
    return {value, errors};
}, (dispatch)=> {
    return {
        onChange(value, errors) {
            return dispatch(changeForm(value, errors, 'retrieveForm'));
        },
    };
})
export default class PNRRetrieve extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    handleChange = ({value, errors}) => {
        this.props.onChange(value, errors);
    };

    handleSubmit = () => {
        const {value} = this.props;
        const errors = PNRRetrieveSchema.validate(value);

        this.props.onChange(value, errors);

        if (noErrors(errors)) {
            const {entity, pnr} = value;
            this.props.onSubmit(entity.value, pnr);
        }
    };

    render() {
        const {value, errors} = this.props;
        return (
            <Col xs={6}>
                <RowWrapper simple>
                    <div className="form-group">
                        <h4>Retrieve PNR</h4>
                    </div>

                    <RetrieveForm schema={PNRRetrieveSchema}
                        value={value}
                        errors={errors}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit} />
                </RowWrapper>
            </Col>
        );
    }
}
