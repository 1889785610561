'process i18n';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {gettext, ngettext} from 'airborne/gettext';
import markup from 'airborne/markup';

import {formatCarAddress} from 'airborne/checkout_cars/helpers/destination';
import {formatStrict as formatPrice} from 'airborne/utils/price';
import {formatIncludedDistance} from 'airborne/search_cars/helpers/distance';
import hasGrandChildren from 'airborne/helpers/hasGrandChildren';
import TabsWithContent, {Content, MenuItem, TabsMenu, TabContent} from 'midoffice/components/TabsWithContent';
import {getExplanationText} from 'airborne/homepage2/helpers/acriss';

import {getRatePlanI18N} from 'airborne/search_cars/types';

const locationShape = PropTypes.shape({
    airport: PropTypes.shape({
        shuttle: PropTypes.string,
        terminals: PropTypes.array,
    }),
    address: PropTypes.object,
    hours: PropTypes.shape({
        dropoff: PropTypes.string,
        pickup: PropTypes.string,
    }),
});

const CodedItems = PropTypes.arrayOf(
    PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })
);

const amountShape = PropTypes.shape({
    amount: PropTypes.number,
    distanceIncluded: PropTypes.shape({
        distanceAmount: PropTypes.number,
        distanceUnit: PropTypes.string,
        unlimited: PropTypes.bool,
    }),
    extraDistance: PropTypes.shape({
        amount: PropTypes.number,
        distanceUnit: PropTypes.number,
    }),
});

const ratePlanShape = PropTypes.shape({
    ...amountShape.isRequired,
    extraDistance: amountShape,
    extraHour: amountShape,
});

const rateShape = PropTypes.shape({
    currency: PropTypes.string,
    freetext: PropTypes.string,
    freetextInfo: PropTypes.string,
    includedCoverage: CodedItems,
    optionalCoverage: CodedItems,
    dropoff: locationShape, // TBD: remove after GG-22320
    dropoffLocation: locationShape,
    pickup: locationShape, // TBD: remove after GG-22320
    pickupLocation: locationShape,
    ratePlan: ratePlanShape,
    specialEquipment: CodedItems,
    surcharges: PropTypes.array,
    taxes: PropTypes.number,
    total: PropTypes.number,
    totalAfterTaxes: PropTypes.number,
});

// Reusable components

export class Section extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['freetext', 'list', 'paragraph', 'wrapper']).isRequired,
    }

    static Item = /* istanbul ignore next */ ()=> null;

    renderParagraph() {
        return React.Children.map(this.props.children, (cell, index)=> cell && cell.props.children && (
            <div key={index}>
                {cell.props.children}
            </div>)
        );
    }

    renderFreetext() {
        return React.Children.map(this.props.children, (item, index)=> item && (
            <div key={index} className="well well-xs" style={{whiteSpace: 'pre-wrap'}}>
                {item.props.children}
            </div>));
    }

    renderList() {
        return (<ul className="list-unstyled">
            {React.Children.map(this.props.children, (item, index)=> item && (
                <li key={index}>
                    {item.props.children}
                </li>))}
        </ul>);
    }

    render() {
        const {label, type, children} = this.props;
        if (type !== 'wrapper' && !hasGrandChildren(children)) {
            return null;
        }
        return (<div className="modal-rate-desc__wrap">
            <h4><strong>{label}</strong></h4>

            {type === 'list' && this.renderList()}
            {type === 'freetext' && this.renderFreetext()}
            {type === 'paragraph' && this.renderParagraph()}
            {type === 'wrapper' && this.props.children}
        </div>);
    }
}

class RatePlan extends React.Component {
    static propTypes = {
        ratePlan: ratePlanShape.isRequired,
        currency: PropTypes.string.isRequired,
    }

    render() {
        const {ratePlan, currency} = this.props;
        const {extraDay, extraHour} = ratePlan;
        const {amount, type} = getRatePlanI18N(ratePlan);

        return (<table className="table table-condensed table-hover">
            <tbody>
                <tr>
                    <th style={{width: '33%'}}/>
                    <th>{gettext('Base Rate / Type')}</th>
                    {extraDay && <th>{gettext('Extra Day')}</th>}
                    {extraHour && <th>{gettext('Extra Hour')}</th>}
                </tr>
                <tr>
                    <th>{gettext('Charge')}</th>
                    <td>{amount ? formatPrice(amount, currency) : gettext('N/A')} / {type}</td>
                    {extraDay && <td>{formatPrice(extraDay.amount, currency)}</td>}
                    {extraHour && <td>{formatPrice(extraHour.amount, currency)}</td>}
                </tr>
                <tr>
                    <th>{gettext('Distance Included')}</th>
                    <td>{formatIncludedDistance(ratePlan.distanceIncluded)}</td>
                    {extraDay && <td>{formatIncludedDistance(extraDay.distanceIncluded)}</td>}
                    {extraHour && <td>{formatIncludedDistance(extraHour.distanceIncluded)}</td>}
                </tr>
            </tbody>
        </table>);
    }
}

class CodedItemsSection extends React.Component {
    static propTypes = {
        items: CodedItems,
        label: PropTypes.string.isRequired,
        emptyLabel: PropTypes.string,
        showDescription: PropTypes.bool,
    }

    render() {
        const {items, label, emptyLabel, showDescription} = this.props;

        return (<Section label={label} type="list">
            {items && items.length
                ? items.map(({code, name, description})=>(
                    <Section.Item key={code}>
                        {markup(`**${code}** - ${name}`)}
                        {showDescription && description && <div className="modal-rate__sm-text">{description}</div>}
                    </Section.Item>))
                : <p><em>{emptyLabel}</em></p>}
        </Section>);
    }
}

class LocationSection extends React.Component {
    static propTypes = {
        location: locationShape,
        label: PropTypes.string,
        hours: PropTypes.string,
    };

    render () {
        const {hours, label, location: {airport, address}} = this.props;
        return (<Section label={label} type="paragraph">
            <Section.Item>
                {formatCarAddress(address)}
            </Section.Item>
            <Section.Item>
                {address.phone && <div><strong>{gettext('Phone')}: </strong>{address.phone}</div>}
                {hours && (<div>
                    <strong>{gettext('Hours of operation')}: </strong>{hours}
                </div>)}
            </Section.Item>
            <Section.Item>
                {airport && airport.shuttle &&
                    <em className="text-gray text-sm">{airport.shuttle}</em>}
            </Section.Item>
        </Section>);
    }
}

// Tabs

class GeneralInfoTab extends React.Component {
    static propTypes = rateShape.isRequired;
    render() {
        const {freetext, freetextInfo, surcharges, total, currency, ratePlan, car, totalAfterTaxes} = this.props;
        return (<TabContent>
            <Section label={gettext('Rate Information')} type="freetext">
                <Section.Item>
                    {freetext || freetextInfo}
                </Section.Item>
            </Section>
            <hr/>
            <Section label={gettext('Car Information')} type="list">
                {car && car.model && <Section.Item>
                    {ngettext('Car', 'Cars', 1)}: <strong>{car.model}</strong> {gettext('or similar')}
                </Section.Item>}
                {car && car.type && <Section.Item>
                    {gettext('ACRISS Code')}: <strong>{car.type}</strong> &ndash; {getExplanationText(car.type)}
                </Section.Item>}
            </Section>
            <Section label={gettext('Taxes & Surcharges')} type="list">
                {surcharges
                    ? surcharges.map(({name}, index)=> (
                        <Section.Item key={index}>
                            {name}
                        </Section.Item>))
                    : <p><em>
                        {gettext('Taxes & Surcharges information is not available.')}
                    </em></p>
                }
            </Section>
            <hr/>
            <Section type="list" label="">
                <Section.Item>
                    {gettext('Estimated Total:')}
                    <strong> {totalAfterTaxes || total + currency}</strong>
                </Section.Item>
            </Section>
            <Section type="wrapper" label={gettext('Rate Plan')}>
                <RatePlan ratePlan={ratePlan} currency={currency}/>
            </Section>
        </TabContent>);
    }
}

class SpecialEquipmentTab extends React.Component {
    static propTypes = rateShape.isRequired;
    render() {
        const {specialEquipment} = this.props;
        return (<TabContent>
            <CodedItemsSection
                showDescription
                label={gettext('Special Equipment')}
                items={specialEquipment}
                emptyLabel={gettext('No special equipment provided.')}/>
        </TabContent>);
    }
}

class InsuranceCoverageTab extends React.Component {
    static propTypes = rateShape.isRequired;
    render() {
        const {includedCoverage, optionalCoverage} = this.props;
        const emptyLabel = gettext('No coverage information provided.');
        return (<TabContent>
            <CodedItemsSection
                label={gettext('Included Coverage')}
                items={includedCoverage}
                emptyLabel={emptyLabel}/>
            <hr/>
            <CodedItemsSection
                label={gettext('Optional Coverage')}
                items={optionalCoverage}
                emptyLabel={emptyLabel}/>
        </TabContent>);
    }
}

class LocationAndContactTab extends React.Component {
    static propTypes = rateShape.isRequired;
    render() {
        const {pickup, pickupLocation, dropoff, dropoffLocation} = this.props;
        // This component is used in multiple places. `get_rate_details` returns
        // pickup but `get_reservation_details` and `GET car_bookings` returns
        // pickup_location. Same with dropoff/dropoffLocation
        // TBD: remove checks after GG-22320 will rename to pickup_location
        const pickUp = pickup || pickupLocation;
        const dropOff = dropoff || dropoffLocation;
        return (<TabContent>
            <LocationSection
                location={pickUp}
                label={gettext('Pick-up Location')}
                hours={pickUp.hours && pickUp.hours.pickup}/>
            <hr/>
            <LocationSection
                location={dropOff}
                label={gettext('Drop-off Location')}
                hours={dropOff.hours && dropOff.hours.dropoff}/>
        </TabContent>);
    }
}

export default class RateDetails extends React.Component {
    static propTypes = rateShape.isRequired;

    render() {
        return (<Fragment>
            <TabsWithContent
                className="modal-body__tabs-list"
                activeClass="modal-body__tabs-list--active"
                noDefaultClasses
                isList>
                <TabsMenu className="modal-body__tabs">
                    <MenuItem>{gettext('General Details')}</MenuItem>
                    <MenuItem>{gettext('Special Equipment')}</MenuItem>
                    <MenuItem>{gettext('Insurance Coverage')}</MenuItem>
                    <MenuItem>{gettext('Location & Contacts')}</MenuItem>
                </TabsMenu>
                <Content className="modal-body__tabs-content">
                    <GeneralInfoTab {...this.props}/>
                    <SpecialEquipmentTab {...this.props}/>
                    <InsuranceCoverageTab {...this.props}/>
                    <LocationAndContactTab {...this.props}/>
                </Content>
            </TabsWithContent>
        </Fragment>);
    }
}
