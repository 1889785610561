import React, {Component} from 'react';
import PT from 'prop-types';

import gettext from 'airborne/gettext';
import moment from 'moment';
import pick from 'lodash/pick';

import Input from 'midoffice/newforms/widgets/Input';
import Select from 'midoffice/newforms/widgets/Select';
import InputTable from 'airborne/checkout2/InputTable';

import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import {visaDraftFormFields} from 'airborne/checkout_air/TravelDocumentation/PassportSchema';
import {noVisaErrors, createOrUpdate, createVisaDraft, createVisaFromDraft} from 'airborne/checkout_air/helpers/checkoutForm';

export const VisaShape = PT.shape({
    number: PT.string.isRequired,
    'expiration_date': PT.string.isRequired,
    'issue_date': PT.string.isRequired,
    'issued_for_country': PT.string.isRequired,
    'place_of_issue': PT.string.isRequired,
});

const VisaForm = ({readOnly, value, countries, errors}) => (<>
    <Input.Field
        label={gettext('Visa Number')}
        placeholder={gettext('Visa Number')}
        name="visaNumber"
        inputCol={7}
        required
        disabled={readOnly}
        showErrorMessage
        {...(value ? {value: value.visaNumber} : {})}
        {...(errors ? {errors: errors.visaNumber} : {})}
    />

    <InputTable inputCol={7} label={gettext('Issue Date')} highlightErrors required>
        <Select.Field
            allowEmpty
            placeholder={gettext('Month')}
            name="visaIssueMonth"
            labelCol={0}
            inputCol={12}
            errorsPosition="bottom"
            required
            disabled={readOnly}
            showErrorMessage
            {...(value ? {value: value.visaIssueMonth} : {})}
            {...(errors ? {errors: errors.visaIssueMonth} : {})}
        />
        <Select.Field
            allowEmpty
            placeholder={gettext('Year')}
            name="visaIssueYear"
            tdWidth="33%"
            labelCol={0}
            inputCol={12}
            required
            disabled={readOnly}
            showErrorMessage
            {...(value ? {value: value.visaIssueYear} : {})}
            {...(errors ? {errors: errors.visaIssueYear} : {})}
        />
    </InputTable>

    <InputTable inputCol={7} label={gettext('Expiration Date')} highlightErrors required>
        <Select.Field
            allowEmpty
            placeholder={gettext('Month')}
            name="visaExpiryMonth"
            required
            labelCol={0}
            inputCol={12}
            errorsPosition="bottom"
            disabled={readOnly}
            showErrorMessage
            {...(value ? {value: value.visaExpiryMonth} : {})}
            {...(errors ? {errors: errors.visaExpiryMonth} : {})}
        />
        <Select.Field
            allowEmpty
            placeholder={gettext('Year')}
            name="visaExpiryYear"
            tdWidth="33%"
            labelCol={0}
            inputCol={12}
            required
            disabled={readOnly}
            showErrorMessage
            {...(value ? {value: value.visaExpiryYear} : {})}
            {...(errors ? {errors: errors.visaExpiryYear} : {})}
        />
    </InputTable>

    <hr/>

    <Select.Field
        allowEmpty
        label={gettext('Country')}
        placeholder={gettext('Country')}
        name="visaCountry"
        choices={countries}
        inputCol={7}
        required
        disabled={readOnly}
        showErrorMessage
        {...(value ? {value: value.visaCountry} : {})}
        {...(errors ? {errors: errors.visaCountry} : {})}
    />

    <Input.Field
        label={gettext('Issue Place')}
        placeholder={gettext('Issue Place')}
        name="visaIssuePlace"
        inputCol={7}
        required
        disabled={readOnly}
        showErrorMessage
        {...(value ? {value: value.visaIssuePlace} : {})}
        {...(errors ? {errors: errors.visaIssuePlace} : {})}
    />

    <Input.Field
        label={gettext('Place Of Birth')}
        placeholder={gettext('Place Of Birth')}
        name="visaPlaceOfBirth"
        inputCol={7}
        required
        disabled={readOnly}
        showErrorMessage
        {...(value ? {value: value.visaPlaceOfBirth} : {})}
        {...(errors ? {errors: errors.visaPlaceOfBirth} : {})}
    />
</>);

export class VisaFormPrefilled extends Component {
    static propTypes = {
        onPatch: PT.func.isRequired,
        value: PT.object.isRequired,
        schema: PT.object.isRequired,
        visaIndex: PT.number,
        countries: PT.array,
        placeOfBirth: PT.string,
    };

    componentDidMount() {
        this.prefillForm();
    }

    prefillForm = () => {
        const {onPatch, value, visaIndex, placeOfBirth} = this.props;
        const {'visa_documents': visas} = value;

        onPatch({
            toChange: {
                value: createVisaDraft(visas, visaIndex, placeOfBirth)
            }
        });
    }

    render() {
        const {countries} = this.props;
        return <VisaForm countries={countries} />;
    }
}

export class VisaFormModal extends Component {
    static propTypes = {
        onPatch: PT.func.isRequired,
        onHide: PT.func.isRequired,
        value: PT.object.isRequired,
        visaIndex: PT.number,
        editing: PT.bool,
        schema: PT.object,
        countries: PT.array,
        placeOfBirth: PT.string,
    };

    getDate = (year, month) => {
        return moment({year, month: month - 1, day: 1}).format('YYYY-MM-DD');
    }

    saveVisa = () => {
        const {value, onPatch, visaIndex: indexToModify, schema, onHide} = this.props;
        const {'visa_documents': visas, visaIndex: selectedVisaIndex} = value;
        const visaDraft = pick(value, visaDraftFormFields);
        const isEditing = indexToModify !== null;

        const newVisa = createVisaFromDraft(visaDraft);
        const update = createOrUpdate(value['visa_documents'], newVisa, indexToModify);
        const errors = schema.validate(value, {requireVisaDraft: true});

        if (noVisaErrors(errors)) {
            onPatch({
                toChange: {
                    value: {
                        'visa_documents': update,
                        visaIndex: isEditing ? selectedVisaIndex : visas.length
                    }
                }
            });

            onHide();
        }
        else {
            onPatch({toChange: {errors}});
        }
    }

    deleteVisa = () => {
        const {onPatch, visaIndex: indexToRemove, value, onHide} = this.props;
        const {visaIndex: selectedVisaIndex} = value;

        const indexToSelect = selectedVisaIndex === indexToRemove
            ? null
            : selectedVisaIndex > indexToRemove ? selectedVisaIndex - 1 : selectedVisaIndex;

        onPatch({
            toChange: {
                value: {
                    'visa_documents': value['visa_documents'].filter((v, idx) => idx !== indexToRemove),
                    visaIndex: indexToSelect
                }
            }
        });

        onHide();
    }

    render() {
        const {editing, onHide, onPatch, value, schema, countries, placeOfBirth, visaIndex} = this.props;

        return (<div className="modal-dialog">
            <div className="modal-content">
                <Modal.Header>
                    <Modal.Title>{gettext('Visa')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-horizontal">
                        <VisaFormPrefilled
                            onPatch={onPatch} value={value} schema={schema} visaIndex={visaIndex}
                            countries={countries} placeOfBirth={placeOfBirth} name="visaDraft" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {editing &&
                        <button onClick={this.deleteVisa} className="btn btn-link btn-link--narrow highlight-red pull-left">
                            Delete Visa
                        </button>}
                    <Button bsStyle="default" onClick={onHide}>{gettext('Cancel')}</Button>
                    <Button bsStyle="primary" onClick={this.saveVisa}>{editing ? gettext('Save') : gettext('Add')}</Button>
                </Modal.Footer>
            </div>
        </div>);
    }
}

export default VisaForm;
