/*
  FrequencyWidget

  Writes when approximately next date of action.
*/
import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Select from 'midoffice/newforms/widgets/Select';


const DESCRIPTIONS = {
    weekly: 'Delivered every Monday',
    monthly: 'Delivered on the first day of every month',
    quarterly: 'Delivered on the first day of every quarter',
};

const TOOLTIPS = {
    weekly: 'This report will initially include all records from the previous week.',
    monthly: 'This report will initially include all records from the previous month.',
    quarterly: 'This report will initially include all records from the previous quarter.',
};


class InfoTooltip extends React.Component {

    static propTypes = {
        message: PropTypes.string.isRequired,
    };

    render() {
        const {message} = this.props;
        const tooltip = (<Tooltip>{message}</Tooltip>);

        return (
            <OverlayTrigger
                    overlay={tooltip}
                    placement="right"
                    delayShow={300}
                    delayHide={150}>
                <Glyphicon glyph="info-sign"/>
            </OverlayTrigger>
        );
    }
}


export default class FrequencyWidget extends React.Component {

    static propTypes = {
        value: PropTypes.any,
    };

    render() {
        const value = this.props.value;
        const description = value && DESCRIPTIONS[value];
        const tooltip = value && TOOLTIPS[value];

        return (<div>
            <Select {...this.props}/>
            {value && (
                <small>{description} <InfoTooltip message={tooltip}/></small>
            )}
        </div>);
    }
}
