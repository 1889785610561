import React from 'react';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';


import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';


@connect(({actionableCounter}) => ({actionableCounter}))
class ActionableCounter extends React.Component {
    static propTypes = {
        actionableCounter: PropTypes.string,
        placement: PropTypes.string,
        children: PropTypes.node,
    };
    render() {
        const {actionableCounter, children, placement='bottom'} = this.props;
        const count = Number.isInteger(actionableCounter)
            ? actionableCounter
            : settings['ACTIONABLE_BOOKINGS'];
        const isAft = settings['SUB_PARTNER'] === 'aft' || settings['DISPLAY_MODE'] === 'aft';
        return (<>
            {children}
            {count > 0 && isAft  && (
                <AnnotateTooltip id={'actionable-couter'} placement={placement}>
                    <span className="navbar__counter highlight-red">
                        <Glyphicon glyph="exclamation-sign" />
                        <strong>{count > 9 ? '9+' : count}</strong>
                    </span>
                    {gettext('There are some reservations \nthat require agent attention')}
                </AnnotateTooltip>
            )}
        </>);
    }

}

export default ActionableCounter;
