import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

export default class ErrorBox extends React.Component {
    static propTypes = {
        append: PropTypes.string,
        children: PropTypes.any,
    };

    render() {
        return (<div className="alert alert-danger">
            <Glyphicon glyph="remove-sign" />
            <strong>
                {this.props.children}
            </strong>
            <p className="alert__text">
                {this.props.append}
            </p>
        </div>);
    }
}
