import React from 'react';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';
import {getOffers, resendEmailOffer, showOfferModal, sortOffers} from './actions/emailOffers';
import PropTypes from 'prop-types';
import {getEmailOffers, getEmailOffersErrors, getEmailOffersPnr, getEmailOffersSortBy} from './emailOffersSelectors';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import Offer from './Offer';
import Button from 'react-bootstrap/lib/Button';
import {CommonTable} from 'midoffice/components/CommonTable';
import {toHomepage} from 'airborne/store/modules/search_hotels/actions/navigate';


@connect((state) => {
    const pnrProfile = getPnrProfile(state, 0);
    return {
        companyName: pnrProfile?.['company_name'],
        offers: getEmailOffers(state),
        errors: getEmailOffersErrors(state),
        pnr: getEmailOffersPnr(state),
        sortBy: getEmailOffersSortBy(state),
    };
}, {load: getOffers, resendEmailOffer, showOfferModal, sortOffers, toHomepage})
class EmailOffers extends React.Component {
    static propTypes = {
        offers: PropTypes.array,
        errors: PropTypes.object,
        companyName: PropTypes.string,
        pnr: PropTypes.string,
        sortBy: PropTypes.string,
        sortOffers: PropTypes.func,
        load: PropTypes.func.isRequired,
        resendEmailOffer: PropTypes.func.isRequired,
        showOfferModal: PropTypes.func.isRequired,
        toHomepage: PropTypes.func.isRequired,
    };
    componentDidMount() {
        this.props.load(this.props.pnr);
    }

    handleSort = (sortBy) => {
        this.props.sortOffers(sortBy);
    }
    render() {
        const {offers, showOfferModal, companyName, pnr, sortBy, toHomepage} = this.props;

        const offerCols = [
            {label: 'Date Sent', w: 130,  field: 'date_sent'},
            {label: 'Offer Details', w: 370, field: 'offer_details'},
            {label: 'Offered Rates', field: 'offered_rates'},
            {label: '', w: 65},
        ];
        return (
            <div className="container">
                <div className="container clearfix">
                    <div className="page-wrapper">
                        <h5 className="page-section-title">
                            {gettext('Active Email Offers for PNR:')} <strong> {pnr} </strong>
                            {companyName && <span className="text-gray">({gettext(companyName)})</span>}
                            <Button onClick={toHomepage} className="btn-link--narrow btn btn-link page-section-title__control">
                                <strong>« Back to Homepage</strong>
                            </Button>
                        </h5>
                        <CommonTable
                            heads={offerCols}
                            onSortBy={this.handleSort}
                            sortBy={sortBy}
                            emptyLabel="No records."
                            loadingLabel="Records are loading…"
                            errorLabel="Failed to load records."
                            bsStyle="common" >
                            <tbody>
                                {offers && offers.map((offer) => {
                                    return <Offer key={offer.pack_id} onChange={showOfferModal} pnr={pnr} offer={offer} />;
                                })}
                                {!offers.length && <tr>
                                    <td colSpan="100" className="table-empty-message">{gettext('No active email offers found.')}</td>
                                </tr>}
                            </tbody>
                        </CommonTable>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailOffers;
