import React from 'react';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import Button from 'midoffice/components/Button';
import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';
import {connect} from 'react-redux';
import {sendTerms, changeTerms, hideTermsModal, showTermsModal, openPrivacyPolicy} from 'airborne/store/modules/login/actions/terms';
import TermsSchema from './schemas/TermsSchema';
import {getTermsModalState} from 'airborne/login/authSelectors';
import settings from 'airborne/settings';
import TermsModal from 'airborne/login/TermsModal';
import {
    getUserSettingsErrors,
    getUserSettingsLoading,
    getUserSettingsValue
} from 'airborne/store/modules/user_settings/selectors/userSettings';

@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        showTermsModal: PropTypes.func.isRequired,
        hideTermsModal: PropTypes.func.isRequired,
        errors: PropTypes.object,
        isShowTermsModal: PropTypes.bool,
        loading: PropTypes.bool,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit();
    }

    render() {
        const extra = {
            labelCol: 2,
            inputCol: 12,
        };
        const {showTermsModal, errors, isShowTermsModal, hideTermsModal, loading} = this.props;
        const acceptError = errors && errors['accept_terms'];
        const {'is_new_user': isNewUser, SUB_PARTNER} = settings;
        return (<>
            {isShowTermsModal && <TermsModal onHide={hideTermsModal} />}
            <h3 className="floated-form__body__header">{gettext('Welcome')}</h3>

            {isNewUser && <p>
                {gettext('Please review')}{' '}
                <Button bsStyle="link" className="btn-link--inline" onClick={showTermsModal}>{gettext('Terms and Conditions')}</Button>
                {' '}{gettext('and')}{' '}
                <Button name="open-link-btn" bsStyle="link" className="btn-link--inline" onClick={openPrivacyPolicy}>{gettext ('Privacy Policy')}</Button>.
            </p>}

            {!isNewUser && <>
                <p><strong>{gettext('Terms and Conditions have changed.')}</strong></p>
                <p>
                    {gettext('Please review the updated version')}{' '}
                    <Button bsStyle="link" name="show-terms" className="btn-link--inline" onClick={showTermsModal}> {gettext('here')}</Button>.
                </p>
            </>}


            <hr className="hr--dotted" />
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <Checkbox.Field
                    label={gettext('I Agree')}
                    name="accept_terms"
                    dataId="agree"
                    errors={null}
                    {...extra}/>

                {acceptError && <section className="floated-form__error">
                    <strong>{acceptError}</strong>
                </section>}
                <div className="floated-form__control">
                    <Button
                        className="btn-primary btn-block btn-lg"
                        disabled={loading}
                        type={'submit'}
                        onClick={this.handleSubmit}>{gettext('Continue')}</Button>
                </div>
            </form>
            </>);
    }
}


@connect((state) => ({
    value: getUserSettingsValue(state, 'terms'),
    loading: getUserSettingsLoading(state, 'terms'),
    errors: getUserSettingsErrors(state, 'terms'),
    isShowTermsModal: getTermsModalState(state),
}), {
    onSave: sendTerms,
    onChange: changeTerms,
    showTermsModal,
    hideTermsModal,
})
class Terms extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            'accept_terms': PropTypes.bool,
        }),
        errors: PropTypes.object,
        isShowTermsModal: PropTypes.bool,
        onChange: PropTypes.func,
        onSave: PropTypes.func,
        showTermsModal: PropTypes.func,
        hideTermsModal: PropTypes.func,
        loading: PropTypes.bool,
    };


    handleFormChange = ({value, errors}) => {
        this.props.onChange(value, errors);
    };

    handleSend = () => {
        const {value} = this.props;
        const errors = TermsSchema.validate(value);
        if (noErrors(errors)) {
            this.props.onSave();
        }
        else {
            this.props.onChange(value, errors);
        }
    };

    render() {
        const {value, errors, showTermsModal, isShowTermsModal, hideTermsModal, loading} = this.props;
        return (
            <div>
                <Editor
                    onChange={this.handleFormChange}
                    schema={TermsSchema}
                    value={value}
                    loading={loading}
                    hideTermsModal={hideTermsModal}
                    showTermsModal={showTermsModal}
                    isShowTermsModal={isShowTermsModal}
                    onSubmit={this.handleSend}
                    errors={errors}/>
            </div>
        );
    }
}

export default Terms;
