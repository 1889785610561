import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Row from 'react-bootstrap/lib/Row';


export default class RowWrapper extends React.Component {

    static propTypes = {
        absolute: PropTypes.bool,
        border: PropTypes.oneOf([false, 'bottom', 'right']),
        condensed: PropTypes.bool,
        className: PropTypes.string,
        highlighted: PropTypes.bool,
        inline: PropTypes.bool,
        simple: PropTypes.bool,

        style: PropTypes.object,
    };

    static defaultProps = {
        absolute: false,
        border: false,
        condensed: false,
        className: '',
        highlighted: false,
        inline: false,
        simple: false
    };

    render() {
        const props = this.props;
        const className = classnames('row-wrapper', props.className, {
            'border-bottom': props.border === 'bottom',
            'border-right': props.border === 'right',
            'row-wrapper--absolute-content': props.absolute,
            'row-wrapper--condensed': props.condensed,
            'row-wrapper--highlighted-inner': props.highlighted,
            'row-wrapper--inline': props.inline
        });

        return (
            <div className={className} style={props.style}>
                {props.simple ? props.children : <Row>{props.children}</Row>}
            </div>
        );
    }
}
