import React from 'react';
import {Tab, Row, Col, Nav, NavItem} from 'react-bootstrap';
import Preferences from './Preferences';
import ChangePassword from './ChangePassword';
import gettext from 'airborne/gettext';
import ProfileDetails from './ProfileDetails';

class UserSettings extends React.Component {
    render() {
        return (
            <div className="container clearfix">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="clearfix">
                        <Col xs={3}>
                            <Nav className="page-nav" bsStyle="pills" stacked>
                                <NavItem eventKey="first">{gettext('Default Display Preferences')}</NavItem>
                                <NavItem eventKey="second">{gettext('Change Password')}</NavItem>
                            </Nav>
                            <ProfileDetails/>
                        </Col>

                        <Col xs={9}>
                            <div className="page-wrapper">
                                <Tab.Content animation>
                                    <Tab.Pane eventKey="first">
                                        <Preferences/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <ChangePassword />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

export default UserSettings;
