'process i18n';
import React from 'react';

import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/lib/Alert';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';


class ModalAlert extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        title: PropTypes.string.isRequired,
        message: PropTypes.any,
    };

    render () {
        const {type, title, message} = this.props;
        return (<Alert bsStyle={type}>
            <Glyphicon glyph="exclamation-sign" />
            <strong>{title}</strong>
            <p className="alert__text">
                { message}
            </p>
        </Alert>);
    }
}

export default ModalAlert;
