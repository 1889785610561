'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {noop} from 'lodash';

import Modal from 'react-bootstrap/lib/Modal';
import Button from 'midoffice/components/Button';

import {formatStrict as formatPrice} from 'airborne/utils/price';
import annotateCurrency from 'airborne/search2/helpers/annotateCurrency';

import {
    counterOfferAccept,
    counterOfferReject,
    hideCounterofferModal,
} from 'airborne/store/modules/counteroffer/actions';
import {
    getCounterofferLoading,
    getCounterofferType,
    getCounterofferError,
    getBackupBooking,
    getCounterofferRequestSuccess,
} from 'airborne/store/modules/counteroffer/selectors';
import {getBooking} from 'midoffice/bookings/selectors';

function mapStateToProps(state, props) {
    const backupBooking = getBackupBooking(state);
    return {
        booking: getBooking(state, props) || backupBooking,
        counterofferType: getCounterofferType(state),
        error: getCounterofferError(state),
        loading: getCounterofferLoading(state),
        requestSuccess: getCounterofferRequestSuccess(state),
    };
}

@connect(mapStateToProps, {
    onAccept: counterOfferAccept,
    onReject: counterOfferReject,
    onHide: hideCounterofferModal,
})
export default class CounterofferModal extends React.Component {
    static propTypes = {
        onAccept: PropTypes.func.isRequired,
        onReject: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        requestSuccess: PropTypes.bool.isRequired,
        booking: PropTypes.object.isRequired,
        error: PropTypes.arrayOf(PropTypes.string),
        counterofferType: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.oneOf([null]).isRequired,
        ]),
    };

    handleAcceptClick = () => {
        const {onAccept, booking} = this.props;

        onAccept(booking);
    }

    handleRejectClick = () => {
        const {onReject, booking} = this.props;

        onReject(booking);
    }

    renderInfoMessage() {
        return (
            <p>
                {gettext('An on-request hotel booking resulted in the hotel to come back with a counteroffer. Review the offer and ')}
                <strong>{gettext('select accept or reject')}</strong>
                {gettext(' based on traveler’s requirements.')}
            </p>
        );
    }

    renderBookingInfo() {
        const {booking, requestSuccess} = this.props;
        const {
            'hotel': {
                'name': hotelName,
            },
            'traveler': {
                'first_name': firstName,
                'last_name': lastName,
                'company_name': companyName,
                email,
            },
            'rate' : {
                total: proposedTotal,
                currency: proposedCurrency,
            },
            'rate_before_counter_offer': {
                total: originalTotal,
                currency: originalCurrency,
            },
            'number': pnrNumber,
            checkin,
            checkout,
        } = booking;

        const DATE_PATTERN = 'DD MMM YYYY';
        const travelerFormatted = `${firstName} ${lastName} (${email})`;
        const formattedCheckin = `${checkin.format(DATE_PATTERN)} - ${checkout.format(DATE_PATTERN)}`;

        const infoList = [
            [gettext('Check-in / Check-out:'), formattedCheckin],
            [gettext('Company:'), companyName],
            [gettext('Traveler:'), travelerFormatted],
            [gettext('PNR:'), pnrNumber],
        ];

        const formattedTotal = annotateCurrency(formatPrice(proposedTotal, proposedCurrency));
        const formattedOriginal = annotateCurrency(formatPrice(originalTotal, originalCurrency));

        return (
            <>
                <div className="row-wrapper">
                    {requestSuccess
                        ? this.renderSuccessMessage()
                        : this.renderInfoMessage()}
                </div>

                <hr className="hr--dotted" />

                <h4 className="no-margin">
                    <strong>{hotelName}</strong>
                </h4>

                <hr className="hr--dotted" />

                {infoList.map(([label, value]) => (
                    <div key={label}>
                        <strong>{label}</strong> {value}
                    </div>
                ))}

                <div className="price-comparison">
                    <div className="price-comparison__item">
                        <h5 className="price-comparison__title">{gettext('Proposed Total')}</h5>
                        <div className="price-comparison__value"><strong>{formattedTotal}</strong></div>
                        <div className="price-comparison__subline">({gettext('taxes included')})</div>
                    </div>

                    <div className="price-comparison__item price-comparison__item--muted">
                        <h5 className="price-comparison__title">{gettext('Original Total')}</h5>
                        <div className="price-comparison__value"><s>{formattedOriginal}</s></div>
                        <div className="price-comparison__subline">({gettext('taxes included')})</div>
                    </div>
                </div>
            </>
        );
    }

    renderControllButtons() {
        const {loading, counterofferType, requestSuccess} = this.props;
        if (requestSuccess) {
            return null;
        }

        return (
            <div className="row-wrapper">
                <hr />
                <Button
                    progress={loading && counterofferType === 'ACCEPT'}
                    disabled={loading}
                    bsStyle="primary"
                    className="btn-lg pull-left"
                    style={{width: '36%'}}
                    onClick={this.handleAcceptClick}
                >
                    {gettext('Accept')}
                </Button>

                <Button
                    progress={loading && counterofferType === 'REJECT'}
                    disabled={loading}
                    bsStyle="danger"
                    className="btn-lg pull-right"
                    style={{width: '36%'}}
                    onClick={this.handleRejectClick}
                >
                    {gettext('Reject')}
                </Button>
            </div>
        );
    }

    renderSuccessMessage() {
        const {counterofferType} = this.props;
        const events = {
            'ACCEPT': {
                message: gettext('accepted'),
                prefix: 'info',
            },
            'REJECT': {
                message: gettext('rejected'),
                prefix: 'danger',
            },
        };

        const {message, prefix} = events[counterofferType];
        const classNames = `alert alert-${prefix}`;

        return (
            <div className={classNames}>
                {gettext('The counteroffer rate has been')} <strong>{message}</strong>. {gettext('This change may take up to one minute to be reflected in the booking records.')}
            </div>
        );
    }

    renderErrorMessage(message) {
        return (
            <div className="alert alert-danger">
                {message}
            </div>
        );
    }

    render() {
        const {
            error,
            onHide,
            loading,
        } = this.props;

        const onModalHide = loading ? noop : onHide;
        return (
            <Modal show onHide={onModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Counteroffer Information')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {error && this.renderErrorMessage(error)}
                    {this.renderBookingInfo()}
                    {this.renderControllButtons()}
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="default" disabled={loading} onClick={onModalHide}>{gettext('Close')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
