import {getFareKeysRoot} from "airborne/store/modules/search_air/selectors";
import {getLightOriginDestinations} from "airborne/store/modules/availability_air/selectors";

export const getSeparatedTicketsRoot = (state) => state.airPricing.separatedTickets;

export function getSeparatedTicketsCurrentOD(state) {
    return getSeparatedTicketsRoot(state).currentOD;
}

export function isAirSeparatedTicketsMode(state) {
    return getSeparatedTicketsRoot(state).separatedTicketsMode;
}

function getSelectedFares(state) {
    return getSeparatedTicketsRoot(state).separatedFares;
}

export function getSelectedOriginFare(state) {
    return getSelectedFares(state)[0];
}

export function getSelectedDestinationFare(state) {
    return getSelectedFares(state)[1];
}

export function isAirSeparatedTicketsSelectionFulfilled(state) {
    const fareKeys = Object.keys(getFareKeysRoot(state));
    return getLightOriginDestinations(state).length === fareKeys.length;
}
