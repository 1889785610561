'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Modal from 'react-bootstrap/lib/Modal';
import Button from 'midoffice/components/Button';
import {
    deleteProviderCred,
    hideModal,
} from 'midoffice/store/modules/providerCredentials/actions'
import {
    getCountDependencies, getCurrentId
} from 'midoffice/store/modules/providerCredentials/selectors';

@connect((state) => {
    return {
        countDependencies: getCountDependencies(state),
        currentId: getCurrentId(state),
    }
}, {
    onHide: hideModal,
    deleteTravelportCred: deleteProviderCred,
})
export default class DeleteCredsModal extends React.Component {
    static propTypes = {
        countDependencies: PropTypes.number,
        currentId: PropTypes.number,
        onHide: PropTypes.func,
        deleteTravelportCred: PropTypes.func,
    };

    handleUpdatePayment = () => {
        const {deleteTravelportCred, currentId} = this.props;
        deleteTravelportCred(currentId);
    }

    render() {
        const {
            countDependencies,
            onHide,
        } = this.props;

        const canRemove = countDependencies === 0;
        return (
            <Modal show onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Delete credentials')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!canRemove && <div>
                        <p>AgentSource Booking has detected {countDependencies} dependencies for these credentials?</p>
                        <p>You must remove all dependencies before proceeding…</p>
                    </div>}
                    {canRemove && <div>
                        <p>AgentSource Booking has detected 0 dependencies to this Credentials.</p>
                        <p>You may proceed with deletion…</p>
                    </div>}
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="default" onClick={onHide}>{gettext('Close')}</Button>
                    <Button
                        disabled={!canRemove}
                        bsStyle="primary"
                        onClick={this.handleUpdatePayment}
                    >
                        {gettext('Delete credentials')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
