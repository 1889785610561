import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {changeForm, create} from 'midoffice/data/actions/pnrTool';
import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';

import modals from 'midoffice/modals';

import Input from 'midoffice/newforms/widgets/Input';
import Passenger from 'midoffice/newforms/widgets/Passenger';
import SelectAutocomplete from 'midoffice/newforms/widgets/SelectAutocomplete';
import FieldLabel from 'midoffice/newforms/components/FieldLabel';

import {PNRCreateSchema} from 'midoffice/data/pnrTool';
import {companyAutocomplete} from 'airborne/homepage2/autocomplete/CompanyAutocomplete';

import Col from 'react-bootstrap/lib/Col';
import Button from 'midoffice/components/Button';
import RowWrapper from 'midoffice/components/RowWrapper';
import Form from 'midoffice/newforms/components/SimpleForm';


@injectFormContext
class CreateForm extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const {onSubmit, loading} = this.props;
        const extra = {
            labelCol: 3,
            inputCol: 8,
        };

        return (
            <Form onSubmit={onSubmit}>
                <SelectAutocomplete.Field
                    name="entity"
                    label="Entity"
                    autocompleteSource={companyAutocomplete}
                    {...extra}
                    withCompanyInfo />
                <Input.Field name="office_id" label="OID/PCC" disabled {...extra} />

                <div className="form-group">
                    <FieldLabel label="Passengers" className="control-label col-xs-3" required />
                </div>

                <Col xs={11}>
                    <Passenger.Field multi name="passengers"
                        label={false}
                        {...extra}
                        inputCol={12}
                        showErrorMessage={false} />
                </Col>

                <Input.Field name="email" label="Email" {...extra} />
                <Input.Field name="tspm_entity_id" label="TSPM Entity ID" {...extra} />
                <Input.Field name="traveler_id" label="TSPM Traveler ID" {...extra} />
                <Input.Field name="smid" label="Security Manager ID" {...extra} />

                <div className="form-group">
                    <Col xs={11}>
                        <Button bsStyle="primary"
                            className="pull-right"
                            onClick={onSubmit}
                            disabled={loading}
                            progress={loading}
                        > Create </Button>
                    </Col>
                </div>
            </Form>
        );
    }
}


@connect(({'pnr-tool': {createForm, loading}})=> {
    const {value, errors} = createForm;
    return {value, errors, loading};
}, (dispatch)=> {
    return {
        onChange(value, errors) {
            return dispatch(changeForm(value, errors, 'createForm'));
        },
        onCreate(value) {
            return dispatch(create(value));
        },
    };
})
export default class PNRCreate extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onCreate: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    handleChange = ({value, errors})=> {
        this.props.onChange(value, errors);
    };

    handleSubmit = ()=> {
        const {value, onSubmit} = this.props;
        const errors = PNRCreateSchema.validate(value);

        this.props.onChange(value, errors);

        if (noErrors(errors)) {
            this.props.onCreate(value).then(
                ({id: pnr})=> {
                    onSubmit(value.entity.value, pnr);
                },
                modals.oops,
            );
        }
    };

    render() {
        const {value, errors, loading} = this.props;
        return (
            <Col xs={6}>
                <RowWrapper simple border="right">
                    <div className="form-group">
                        <h4>Create PNR</h4>
                    </div>

                    <CreateForm schema={PNRCreateSchema}
                        value={value}
                        errors={errors}
                        loading={loading}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit} />
                </RowWrapper>
            </Col>
        );
    }
}
