import some from 'lodash/some';
import React from 'react';
import {connect} from 'react-redux';
import settings from 'airborne/settings';
import {Route, Redirect} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import intersection from 'lodash/intersection';
import every from 'lodash/every';
import {
    NEED_TO_PERMISSIONS,
    PERMISSION_TO_FILTER,
} from 'midoffice/helpers/permissionsConstants';

function getGranularPermissions() {
    return (settings.USER_ALL_PERMISSIONS || []).filter(item => PERMISSION_TO_FILTER.indexOf(item) === -1);
}
const paternToRegExp = patern => patern.replace('*', '.\*');
const isMatchingPattern = patern => str => str.match(paternToRegExp(patern));
const isPatern = str => str.includes('*');

export function hasAccess(need=[], permissions = getGranularPermissions()) {
    if (Object.keys(NEED_TO_PERMISSIONS).includes(need)) {
        need = NEED_TO_PERMISSIONS[need];
    }

    const needArray = Array.isArray(need) ? need : [need];
    return intersection(permissions, needArray).length > 0 ||
        some(needArray, need =>
            isPatern(need) &&
            some(permissions, isMatchingPattern(need))
        );
}

export function hasStrictAccess(need, permissions = getGranularPermissions()) {
    const needArray = Array.isArray(need) ? need : [need];
    return every(needArray, need => permissions.includes(need));
}

export function getGroupPermissionByAction(groupTitle, action) {
    if (groupTitle === 'RESTRICTED_PERMISSIONS') {
        return 'midoffice:restricted_permissions:edit';
    }

    const convertedTitle = groupTitle.toLowerCase().replaceAll(' ', '_');
    return `midoffice:groups:${convertedTitle}:${action}`;
}

export function canEditUser(user) {
    const groupEditPermissions = user.groups.length === 0
        ? ['midoffice:groups:user_without_group:edit']
        : user.groups.map(group => getGroupPermissionByAction(group, 'edit'));
    return hasStrictAccess(groupEditPermissions);
}

export function haveNotEnoughPermissions(user) {
    const groupEditPermissions = user.groups.length === 0
        ? ['midoffice:groups:user_without_group:edit']
        : user.groups.map(group => getGroupPermissionByAction(group, 'edit'));
    return hasAccess(groupEditPermissions) && !hasStrictAccess(groupEditPermissions);
}

export function toGranularPermissions(route, need) {
    const WrappedComponent = () => {
        const permissions = settings.USER_ALL_PERMISSIONS || [];
        if (!hasAccess(need, permissions)) {
            const {
                key,
                props: {exact, path},
            } = route;
            return (
                <Route exact={exact} path={path} key={key}>
                    <Redirect to="/" />
                </Route>
            );
        }
        return route;
    };

    return <WrappedComponent key={route.key} />;
}

@connect(() => ({permissions: getGranularPermissions()}))
export class Permission extends React.Component {
    static propTypes = {
        permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
        need: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        children: PropTypes.any,
    };

    getNeed() {
        const {need} = this.props;
        return (Array.isArray(need) ? need : [need]).filter(item => item);
    }

    hasAccess() {
        const need = this.getNeed();
        return need.length === 0 || hasAccess(need, this.props.permissions);
    }

    render() {
        const hasAccess = this.hasAccess();
        const {children} = this.props;
        if (typeof children === 'function') {
            return children(hasAccess);
        }

        return hasAccess ? children : null;
    }
}
