import extend from 'lodash/extend';
import omit from 'lodash/omit';
import Button from 'react-bootstrap/lib/Button';
import BootstrapModal from 'react-bootstrap/lib/Modal';
import React from 'react';


import PropTypes from 'prop-types';


/**
 * @class Modal
 */
export default class Modal extends React.Component {
    static propTypes = {
        buttons: PropTypes.array,
        children: PropTypes.any,
        title: PropTypes.string,
        className: PropTypes.string,
        dialogClassName: PropTypes.string,
        onHide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.renderFooter = this.renderFooter.bind(this);
    }

    renderFooter() {
        let buttons = this.props.buttons;
        if (!buttons) {
            return null;
        }
        return (
            <div className="modal-footer">
                {buttons.map((button, index) => {
                    let props = extend(
                        omit(button, 'label', 'action'), {
                            key: index,
                            onClick: button.action
                        }
                    );
                    return (
                        <Button {...props}>
                            {button.label}
                        </Button>
                    );
                })}
            </div>
        );
    }

    render() {
        let {children, className, dialogClassName, onHide, title} = this.props;
        return (
            <BootstrapModal className={className}
                dialogClassName={dialogClassName}
                onHide={onHide}
                title={title} show
            >
                <div className="modal-body">
                    {children}
                </div>
                {this.renderFooter()}
            </BootstrapModal>
        );
    }
}
