import noop from 'lodash/noop';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import gettext from 'airborne/gettext';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import Month from 'midoffice/components/calendar/Month';
import Time from 'midoffice/components/calendar/Time';

import Input from './Input';
import LabeledCheckbox from 'midoffice/newforms/widgets/LabeledCheckbox';
import {injectField} from 'midoffice/newforms/decorators';

import {toDayStart, isCompleteRange} from 'midoffice/helpers/date';


function isReversedDate({min, max}) {
    return isCompleteRange({min, max}) && toDayStart(max).isBefore(toDayStart(min));
}


function switchMonth(current, selected) {
    // @param current: start of month that is displayed in left panel of
    //                 two-month calendar widget
    // @param selected: date selected in left or right panel
    //
    // Should return false if selected date is in next month and is not
    // last day of month itself.
    const endOf = selected
        .clone()
        .endOf('month');
    const currentMonth = current
        .clone()
        .startOf('month');
    return (
        (endOf.diff(selected, 'days') < 7) ||
        (currentMonth.diff(selected, 'months') !== -1)
    );
}


function getTime(date) {
    if (!date || !date.isValid()) { return null; }
    return date.format('HH:mm');
}


function setTime(date, time) {
    const dateStr = date.format('DD/MM/YYYY');
    const dateTimeStr = `${dateStr} ${time}`;
    return moment(dateTimeStr, 'DD/MM/YYYY HH:mm');
}


function isDefaultTime (time, {defaultTime='00:00'}) {
    return time === null || defaultTime === time;
}


@injectField
export default class DateRange extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        min: PropTypes.any,
        max: PropTypes.any,

        value: PropTypes.shape({
            min: PropTypes.any,
            max: PropTypes.any,
        }),

        minFrom: PropTypes.any,
        minTo: PropTypes.any,
        maxFrom: PropTypes.any,
        maxTo: PropTypes.any,

        minTestId: PropTypes.string,
        maxTestId: PropTypes.string,

        withTime: PropTypes.bool,
        withOptionalTime: PropTypes.bool,
        defaultTime: PropTypes.string,
        minTimeLabel: PropTypes.string,
        maxTimeLabel: PropTypes.string,
        is12hTime: PropTypes.bool,

        placeholder: PropTypes.string,
        inputSize: PropTypes.string,
        inputFormat: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        removable: PropTypes.bool,
        plain: PropTypes.bool,
        plainDivString: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        onRemove: PropTypes.func
    };

    static defaultProps = {
        onBlur: noop,
        onFocus: noop,
        onRemove: noop,
        plain: false,
        plainDivString: '→',
        removable: false,
    };

    state = {
        open: false,
        displayed: this.initialDisplay([this.props.min, this.props.max]) || moment(),
        min: null,
        max: null,

        time: {
            min: getTime(this.props.value && this.props.value.min),
            max: getTime(this.props.value && this.props.value.max),
        },
        optionalTime: !isDefaultTime(getTime(this.props.value && this.props.value.min), this.props)
            || !isDefaultTime(getTime(this.props.value && this.props.value.max), this.props),
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.open) {
            return;
        }

        const {value, withOptionalTime} = this.props;
        const {value: prevValue} = prevProps;

        const valueChanged = prevValue?.min !== value?.min || prevValue?.max !== value?.max;

        if (valueChanged) {
            const displayed = this.initialDisplay([
                value && value.min,
                value && value.max,
            ]);
            displayed && this.setState({displayed});
        }

        if (valueChanged || withOptionalTime !== prevProps.withOptionalTime) {
            const minTime = getTime(value && value.min);
            const maxTime = getTime(value && value.max);
            const newOptionalTime = withOptionalTime
                ? !isDefaultTime(minTime, this.props) || !isDefaultTime(maxTime, this.props)
                : false;

            const { min: defaultMinTime, max: defaultMaxTime } = prevState.time;

            this.setState({
                time: {
                    min: minTime || defaultMinTime,
                    max: maxTime || defaultMaxTime,
                },
                optionalTime: newOptionalTime,
            });
        }
    }

    datepickerClick = false;
    timepickerClick = false;
    silentBlur = false;
    silentTimepickerBlur = false;

    initialDisplay(dates) {
        for (let date of dates) {
            if (date && date.isValid()) {
                return date.clone();
            }
        }
        return null;
    }

    addTime(dates, times, defaultTime) {
        return mapValues(dates, (date, which)=> {
            const time = times[which] || defaultTime;
            if (date && time) {
                return setTime(date, time);
            }
            return date;
        });
    };

    hasTime () {
        const {withTime, withOptionalTime} = this.props;
        return withTime || withOptionalTime && this.state.optionalTime;
    };

    doBlur = ()=> {
        if (!this.silentBlur && !this.datepickerClick) {
            this.setState({open: false});
            this.props.onBlur();
        }
        this.datepickerClick = false; // eslint-disable-line immutable/no-mutation
        this.timepickerClick = false; // eslint-disable-line immutable/no-mutation
        this.silentBlur = false; // eslint-disable-line immutable/no-mutation
    };

    handleFocus = (event, which)=> {
        this.setState({open: true, which});
        this.props.onFocus();
    };

    handleDateBlur = (event)=> {
        const needFocus = this.datepickerClick && !this.timepickerClick;
        if (needFocus) {
            event.target.focus();
        }
        this.doBlur();
    };

    handleTimeBlur = ()=> {
        const needFocus = this.datepickerClick && !this.timepickerClick;
        if (needFocus) {
            this.refs[this.state.which].focus();
        }

        if (!this.silentTimepickerBlur) {
            this.doBlur();
        }
        this.silentTimepickerBlur = false; // eslint-disable-line immutable/no-mutation
    };

    handleChange = (value, type)=> {
        const {onChange, name, defaultTime} = this.props;

        const dates = type === 'date' ? value : this.props.value;
        const times = type === 'time' ? value : this.state.time;
        const fullValue = this.hasTime() ? this.addTime(dates, times, defaultTime) : dates;
        onChange(fullValue, name);
    };

    handleDateChange = (value, which)=> {
        const {displayed} = this.state;
        let {inputFormat, value: oldValue} = this.props;
        let date;

        if (!value) {
            date = null;
        }
        else if (typeof value === 'string') {
            if (value.length < inputFormat.length) {
                date = null;
            }
            else {
                date = moment(value, inputFormat);
            }
        }
        else {
            date = value;
        }

        if (date && date.isValid()) {
            if (switchMonth(displayed, date)) {
                this.setState({[which]: null, displayed: date.clone()});
            }
            if (which === 'min') {
                this.silentBlur = true; // eslint-disable-line immutable/no-mutation
                this.refs.max.focus();
            }
        }
        else {
            this.setState({[which]: value});
        }

        let newValue = {
            ...oldValue,
            [which]: date
        };

        if (isReversedDate(newValue)) {
            newValue = {[which]: date};
            const opposite = which === 'min' ? 'max' : 'min';
            this.setState({time: {...this.state.time, [opposite]: null}});
        }

        this.handleChange(newValue, 'date');
    };

    handleSelectDate = (date, which)=> {
        this.handleDateChange(date, which);
        if (which === 'min') {
            this.setState({which: 'max'});
        }
        if (which === 'max' && !this.hasTime()) {
            this.handleDone();
        }
    };

    handleDone = ()=> {
        this.silentBlur = true; // eslint-disable-line immutable/no-mutation
        this.refs.wrapper.focus();
        this.silentBlur = false; // eslint-disable-line immutable/no-mutation
        this.setState({open: false});
    };

    handleChangeTime = (time, which)=> {
        const newTime = {...this.state.time, [which]: time};
        this.setState({time: newTime});
        this.handleChange(newTime, 'time');

        this.silentTimepickerBlur = true; // eslint-disable-line immutable/no-mutation
        this.refs[this.state.which].focus();
    };

    handlePickerMouseDown = (e)=> {
        this.datepickerClick = true; // eslint-disable-line immutable/no-mutation
        this.timepickerClick = e.target.tagName === 'SELECT'; // eslint-disable-line immutable/no-mutation
    };

    handlePrev = ()=> {
        this.state.displayed.subtract(1, 'months');
        this.forceUpdate();
    };

    handleNext = ()=> {
        this.state.displayed.add(1, 'months');
        this.forceUpdate();
    };

    handleRemove = (event)=> {
        event.preventDefault();
        this.props.onRemove();
    };

    handleToggleTime = (value)=> {
        this.setState({
            optionalTime: value
        });
    }

    renderTimePickers() {
        const {disabled, minTimeLabel, maxTimeLabel, defaultTime, withTime, is12hTime} = this.props;
        const {time: {min, max}, optionalTime} = this.state;

        return (
            <footer className="datepicker__footer">
                {this.hasTime() && (
                    <div className="datepicker__time">
                        <Time name="min" label={minTimeLabel} value={min} defaultValue={defaultTime} disabled={disabled} onChange={this.handleChangeTime} onBlur={this.handleTimeBlur} is12hTime={is12hTime}/>
                        <Time name="max" label={maxTimeLabel} value={max} defaultValue={defaultTime} disabled={disabled} onChange={this.handleChangeTime} onBlur={this.handleTimeBlur} is12hTime={is12hTime}/>
                    </div>
                )}
                <div className="datepicker__time__control">
                    {!withTime && (<div className="datepicker__trigger">
                        <LabeledCheckbox
                            name="selectTime"
                            placeholder={gettext('Select Time')}
                            value={optionalTime}
                            onChange={this.handleToggleTime}
                        />
                    </div>)}
                    {this.hasTime() && <Button bsStyle="primary" className="pull-right" onClick={this.handleDone}>{gettext('Done')}</Button>}
                </div>
            </footer>
        );
    }

    renderDropdown() {
        const {displayed, which} = this.state;
        const {min, max} = this.props.value || {};
        const selected = [
            (min && min.isValid()) ? min : null,
            (max && max.isValid()) ? max : null
        ].map(toDayStart);
        const displayedNextMonth = displayed.clone().add(1, 'months');

        const {minFrom, minTo, maxFrom, maxTo} = this.props;
        const limits = (which === 'min')
            ? {minDate: minFrom, maxDate: minTo}
            : {minDate: maxFrom, maxDate: maxTo};

        const {withTime, withOptionalTime} = this.props;

        return (
            <div className="dropdown-menu datepicker datepicker--double"
                ref="wrapper"
                tabIndex={-1}
                onMouseDown={this.handlePickerMouseDown}>
                <Month name={which}
                    {...limits}
                    year={displayed.year()}
                    month={displayed.month()}
                    selected={selected}
                    onClick={this.handleSelectDate}
                    onPrev={this.handlePrev} />
                <Month name={which}
                    {...limits}
                    year={displayedNextMonth.year()}
                    month={displayedNextMonth.month()}
                    selected={selected}
                    onClick={this.handleSelectDate}
                    onNext={this.handleNext} />

                {(withTime || withOptionalTime) && this.renderTimePickers()}
            </div>
        );
    }

    renderTrash() {
        let cls = 'form-group__tail-control';
        return (<a className={cls} href="#" onClick={this.handleRemove}>
            <Glyphicon glyph="trash" />
        </a>);
    }

    renderCols() {
        let {min, max} = this.props.value || {};
        let {open, which, min: minText, max: maxText} = this.state;
        let {disabled, placeholder, inputFormat, removable} = this.props;
        placeholder = placeholder || inputFormat?.toLowerCase();

        minText = minText || (min ? min.format(inputFormat) : '');
        maxText = maxText || (max ? max.format(inputFormat) : '');

        return (
            <Row>
                <Col xs={4} className={cx('dropdown', {open: open && which === 'min'})}>
                    <Input name="min"
                        ref="min"
                        value={minText}
                        placeholder={placeholder}
                        inputSize=""
                        autoComplete="off"
                        disabled={disabled}
                        onFocus={this.handleFocus}
                        onBlur={this.handleDateBlur}
                        onChange={this.handleDateChange} />
                    {open && which === 'min' && this.renderDropdown()}
                </Col>
                <Col xs={1} className="control-delimiter">—</Col>
                <Col xs={4} className={cx('dropdown', {open: open && which === 'max'})}>
                    <Input name="max"
                        ref="max"
                        value={maxText}
                        placeholder={placeholder}
                        inputSize=""
                        autoComplete="off"
                        disabled={disabled}
                        onFocus={this.handleFocus}
                        onBlur={this.handleDateBlur}
                        onChange={this.handleDateChange} />
                    {open && which === 'max' && this.renderDropdown()}
                </Col>
                {removable && this.renderTrash()}
            </Row>
        );
    }

    renderPlain () {
        let {min, max} = this.props.value || {};
        let {open, min: minText, max: maxText} = this.state;
        let {inputSize, disabled, placeholder, inputFormat, minTestId, maxTestId} = this.props;
        placeholder = placeholder || inputFormat.toLowerCase();

        let className = cx(
            'input-daterange', 'input-group', 'dropdown',
            `input-${inputSize}`,
            {open},
        );

        minText = minText || (min ? min.format(inputFormat) : '');
        maxText = maxText || (max ? max.format(inputFormat) : '');

        return (<div className={className}>

            <Input type="text"
                ref="min"
                name="min"
                className="form-control"
                value={minText}
                placeholder={placeholder}
                inputSize=""
                autoComplete="off"
                disabled={disabled}
                onFocus={this.handleFocus}
                onBlur={this.handleDateBlur}
                onChange={this.handleDateChange}
                data-testid={minTestId}
            />
            <span className="input-group-addon">{this.props.plainDivString}</span>
            <Input type="text"
                ref="max"
                name="max"
                className="form-control"
                value={maxText}
                placeholder={placeholder}
                inputSize=""
                autoComplete="off"
                disabled={disabled}
                onFocus={this.handleFocus}
                onBlur={this.handleDateBlur}
                onChange={this.handleDateChange}
                data-testid={maxTestId}
            />

            {open && this.renderDropdown()}
        </div>);
    }

    render() {
        let meth = this.props.plain ? 'renderPlain' : 'renderCols';
        return this[meth]();
    }

}
