'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/lib/Modal';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import RateCard from 'airborne/checkout2/RateCard';

import gettext from 'airborne/gettext';
import PartialWarning from './PartialWarning';
import CancellationPolicy from 'midoffice/bookings/CancellationPolicy';
import {getBooking} from 'midoffice/bookings/selectors';

import {cancelBooking} from './actions';
import {Col, Row} from 'react-bootstrap';

function ensureArray(policy) {
    if (!policy) return [];
    return Array.isArray(policy) ? policy : [policy];
}

export class ControlledBookingCancellationModal extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        fromBookingRecords: PropTypes.bool,
        cancellationPolicy: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.arrayOf(PropTypes.string).isRequired,
        ]),
        status: PropTypes.string.isRequired,
        gds: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        system: PropTypes.string,
        index: PropTypes.number,

        onCancel: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    state = {
        terms: false,
    }

    handleCheckbox = () => {
        this.setState({terms: !this.state.terms});
    }

    render() {
        const {cancellationPolicy, status, gds, number, loading, onHide, index, fromBookingRecords} = this.props;
        const policyDisclaimer = [
            ...ensureArray(cancellationPolicy),
            gettext('Cancellation fees are in hotel\'s local currency unless explicitly stated otherwise.'),
            gettext('All times are in the local hotel\'s time.'),
        ];
        const mainCol = fromBookingRecords ? 12 : 7;
        const modalSize = fromBookingRecords ? 'md' : 'large';
        return (
            <Modal bsSize={modalSize} onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title>{gettext('Cancel reservation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={mainCol}>
                            <PartialWarning status={status} gds={gds} number={number}/>
                            <h4>{gettext('Cancellation Policy')}</h4>
                            <div className="row-wrapper row-wrapper--condensed">
                                <CancellationPolicy policy={policyDisclaimer} className="ul-content"/>
                            </div>
                            <hr/>
                            <p className="highlight-red">{gettext('Please review the cancellation policy carefully. If the cancellation deadline has passed, you can still cancel this reservation, but cancellation penalties will apply as stated in the policy.')}</p>

                            <div className="modal-body__cxl-info__confirmation">
                                <Checkbox
                                    name="terms"
                                    value={this.state.terms}
                                    onChange={this.handleCheckbox}
                                    label={gettext('I have read and agree to the cancellation terms above.')}
                                />
                            </div>
                            <div className="modal-body__cxl-info__control">
                                <Button bsStyle="danger"
                                    bsSize="large"
                                    className="pull-left"
                                    progress={loading}
                                    disabled={loading || !this.state.terms}
                                    onClick={this.props.onCancel}>{gettext('Cancel This Reservation')}</Button>
                                <Button bsStyle="default"
                                    bsSize="large"
                                    className="pull-right"
                                    onClick={onHide}>{gettext('Do Not Cancel')}</Button>

                            </div>
                        </Col>
                        {!fromBookingRecords && <Col xs={5}>
                            <RateCard isConfirmation={true} index={index}/>
                        </Col>}
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const BookingCancellationModal = connect((state, props)=> {
    const {bookingModification} = state;
    const booking = getBooking(state, props);
    return {
        cancellationPolicy: booking['cancellation_policy'],
        status: booking['status'],
        gds: booking['gds'],
        number: booking['number'],
        loading: bookingModification.uuid === props.id,
        system: booking.system,
        id: props.id,
        fromBookingRecords: true,
    };
}, (dispatch, {id, data})=> ({
    onCancel: () => dispatch(cancelBooking(id || data.id)),
}))(ControlledBookingCancellationModal);

export default BookingCancellationModal;
