'process i18n';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import values from 'lodash/values';
import {shallowAssign} from 'midoffice/helpers/types';
import moment from 'moment';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';

import {noErrors, combineValidators, required, errorMessage} from './helpers';
import {checkoutEmailRegex, emailRegex} from './validators/email';
import iataCodeValidator from './validators/iata-code';
import {nameValidator} from './validators/name';

export const Field = {
    isRequired: false,
    emptyMessage: 'This field is required',
    toInternalValue(value) {
        return value;
    },
    toRepresentation(value, params) {
        return {
            value,
            errors: this.validate(value, params),
        };
    },

    isEmpty(value) {
        return value === '' || value === null || typeof value === 'undefined';
    },

    validateEmptyValue(value) {
        if (!this.isRequired || this.readOnly || this.disabled) {
            return null;
        }
        if (this.isEmpty(value)) {
            return errorMessage(this.emptyMessage, this);
        }
        return null;
    },

    validate(value) {
        return this.validateEmptyValue(value);
    }
};

export const BooleanField = {
    ...Field,
    toInternalValue(value) {
        return Boolean(value);
    },
};

export const ChoiceField = {
    ...Field,

    missingChoiceMessage: 'Invalid choice value',

    getChoice(byValue) {
        return find(this.choices, ([value])=> value === byValue);
    },

    validate(value) {
        if (!this.choices) {
            return Field.validate.call(this, value);
        }

        const choice = this.getChoice(value);
        if (!choice) {
            return errorMessage(this.missingChoiceMessage, this);
        }

        return null;
    }
};

export const OptionalChoiceField = {
    ...ChoiceField,

    validate(value) {
        if (this.isRequired) {
            return ChoiceField.validate.call(this, value);
        }

        return null;
    }
};

export const MultiChoiceField = {
    ...ChoiceField,

    isEmpty(value) {
        return ChoiceField.isEmpty(value) || (Array.isArray(value) && value.length === 0);
    },

    validate(value) {
        const errors = this.validateEmptyValue(value);
        if (errors) { return errors; }

        value = value || [];

        for (const choice of value) {  //eslint-disable-line
            const error = ChoiceField.validate(choice);
            if (error) { return error; }
        }

        return null;
    }
};

export const TagsField = {
    ...ChoiceField,
    maxLength: null,

    maxLengthMessage: ({maxLength})=> gettext('Ensure this field has no more than {maxLength} characters', {maxLength}),

    isEmpty(value) {
        return ChoiceField.isEmpty(value) || (Array.isArray(value) && value.length === 0);
    },

    validate(value) {
        const errors = this.validateEmptyValue(value);
        if (errors) { return errors; }
        value = value || [];

        for (let choice of value) { //eslint-disable-line
            const error = ChoiceField.validate.call(this, choice.value);
            if (error) { return error; }
        }

        const joinedValue = value.map(({value}) => value).join().trim();
        if (this.maxLength && joinedValue && joinedValue.length > this.maxLength) {
            return errorMessage(this.maxLengthMessage, this);
        }

        return null;
    }
};

export const CharField = {
    ...Field,
    exactLength: null,
    maxLength: null,
    minLength: null,

    exactLengthMessage: ({exactLength})=> gettext('Field must have {exactLength} characters', {exactLength}),
    maxLengthMessage: ({maxLength})=> gettext('Ensure this field has no more than {maxLength} characters', {maxLength}),
    minLengthMessage: ({minLength})=> gettext('Ensure this field has at least {minLength} characters', {minLength}),

    toRepresentation(formValue) {
        const errors = this.validate(formValue || '');

        return {
            value: formValue ? String(formValue) : formValue,
            errors,
        };
    },
    toInternalValue(value) {
        return value ? String(value) : null;
    },

    validate(value) {
        const trimmedValue = value ? String(value).trim() : '';

        if (this.isRequired && !trimmedValue) {
            return errorMessage(this.emptyMessage, this);
        }

        if (this.exactLength && trimmedValue && trimmedValue.length !== this.exactLength) {
            return errorMessage(this.exactLengthMessage, this);
        }

        if (this.maxLength && trimmedValue && trimmedValue.length > this.maxLength) {
            return errorMessage(this.maxLengthMessage, this);
        }

        if (this.minLength && trimmedValue && trimmedValue.length < this.minLength) {
            return errorMessage(this.minLengthMessage, this);
        }

        return null;
    }
};

export const RegexField = {
    ...CharField,

    regex: /.*/,
    invalidFormatMessage: null,

    validate(value) {
        const error = CharField.validate.apply(this, arguments);

        if (error) {
            return error;
        }

        if (value && !this.regex.test(value)) {
            return errorMessage(this.invalidFormatMessage, this);
        }

        return null;
    },
};

export const AlphanumericField = {
    ...RegexField,

    regex: /^[a-z0-9]+$/i,
    invalidFormatMessage: 'Ensure this field has only alphanumeric characters',
};

export const IdCardNumberField = {
    ...RegexField,

    regex: /^[a-z0-9\-]+$/i,
    invalidFormatMessage: 'Ensure this field has only alphanumeric characters and hyphens' ,
};

export const DigitsField = {
    ...RegexField,

    regex: /^[0-9]+$/,
    invalidFormatMessage: 'Ensure this field has only digit characters' ,
};

const phoneRegex = /^\+[0-9]{8,15}$/;

export const PhoneNumberField = {
    ...RegexField,

    regex: phoneRegex,
    invalidFormatMessage: gettext('Ensure this field has a valid phone number in format "+XXXXXXXXXXX" (8 to 15 digits)'),
};

export const EmailField = {
    ...RegexField,

    regex: emailRegex,
    invalidFormatMessage: gettext('Please enter a valid email address.'),
};

export const EmailCheckoutField = {
    ...EmailField,
    invalidEmailLengthMessage: gettext('Ensure that the local part (part of the email before the @ sign) is no longer than 50 characters'),
    maxEmailLocalLength: 50,
    validateLocalPartEmailLength: (value, maxLength) => {
        return value.substring(0, value.lastIndexOf('@')).length > maxLength;
    },

    validate(value) {
        const error = EmailField.validate.apply(this, arguments);

        if (error) {
            return error;
        }

        if (value && this.validateLocalPartEmailLength(value, this.maxEmailLocalLength)) {
            return errorMessage(this.invalidEmailLengthMessage, this);
        }

        return null;
    },
};

export const EmailCheckoutHotelsField = {
    ...EmailCheckoutField,

    regex: checkoutEmailRegex,
    invalidFormatMessage: gettext('Please enter a valid email address.'),
};

export const PhoneNumberOrEmailField = {
    ...RegexField,

    regex: RegExp(`(${emailRegex.source})|(${ phoneRegex.source})`),
    invalidFormatMessage: gettext('Ensure this field has a valid phone number in format "+XXXXXXXXXXX" (8 to 15 digits) or a valid email address.'),
};


export const UpperCaseField = {
    ...CharField,

    toRepresentation(formValue) {
        formValue = (formValue || '').toUpperCase().trim();
        const errors = this.validate(formValue);

        return {
            value: formValue ? String(formValue) : formValue,
            errors,
        };
    },
};

export const NumberField = {
    ...Field,
    invalidValueMessage: 'Please enter a number',
    minValue: null,
    maxValue: null,

    validateMinMaxValue(value) {
        if (value < this.minValue || value > this.maxValue) {
            return this.invalidValueMessage + ` between ${this.minValue} and ${this.maxValue}`;
        }
        return null;
    },

    validateMinValue(value) {
        if (value < this.minValue) {
            return this.invalidValueMessage + ` more or equal than ${this.minValue}`;
        }
        return null;
    },

    validateMaxValue(value) {
        if (value > this.maxValue) {
            return this.invalidValueMessage + ` less or equal than ${this.maxValue}`;
        }
        return null;
    },

    toRepresentation(formValue) {
        const errors = this.validate(formValue);

        return {
            value: (errors || !formValue) ? formValue : Number(formValue),
            errors,
        };
    },

    validate(value) {
        if (value && !isNumber(value)) {
            return this.invalidValueMessage;
        }

        const empty = !value && value !== 0;
        if (this.isRequired && empty) {
            return errorMessage(this.emptyMessage, this);
        }

        if (empty) {
            return null;
        }

        if (this.minValue != null && this.maxValue != null) {
            return this.validateMinMaxValue(value);
        }
        else if (this.minValue != null) {
            return this.validateMinValue(value);
        }
        else if (this.maxValue != null) {
            return this.validateMaxValue(value);
        }

        return null;
    }
};

export const DecimalNumberField = {
    ...NumberField,
    invalidMessage: 'Please enter a decimal number',
    toRepresentation(formValue) {
        return Field.toRepresentation.call(this, formValue);
    },
    validate(value) {
        if (typeof value === 'string' && !isNaN(value)) {
            const isDecimal = /^\d+\.?(\d{1,2})?$/.test(value);

            if (!isDecimal) {
                return errorMessage('Please enter a decimal number with two decimal points');
            }

            value = Number(value);
        }

        return NumberField.validate.call(this, value);
    }
};

export const ArrayField = {
    isRequired: false,
    isDistinct: false,
    maxLength: null,
    minLength: null,

    emptyMessage: 'This field is required',
    duplicatesMessage: 'Duplicates are not allowed',

    maxLengthMessage: ({maxLength})=> `Ensure this field has no more than ${maxLength} elements`,
    minLengthMessage: ({minLength})=> `Ensure this field has at least ${minLength} elements`,

    child: Field,
    toRepresentationChildren(value, params) {
        if (!Array.isArray(value)) {
            return {value: null, errors: null};
        }

        return value.reduce((acc, part)=> {
            const {value, errors} = this.child.toRepresentation(part, params);
            acc.value.push(value);
            acc.errors.push(errors);

            return acc;
        }, {value: [], errors: []});
    },
    toRepresentation(formValue, params) {
        let {value, errors} = this.toRepresentationChildren(formValue, params);

        if (noErrors(errors)) {
            errors = this.validate(value, params);
        }
        return {value, errors};
    },
    toInternalValue(value) {
        if (!value) {
            return null;
        }
        return value.map((part)=> this.child.toInternalValue(part));
    },

    validate(value, params) {
        if (this.isRequired && (!value || !value.length)) {
            return errorMessage(this.emptyMessage, this);
        }

        if (!Array.isArray(value)) {
            return null;
        }

        if (this.isDistinct && value && (new Set(value)).size !== value.length) {
            return errorMessage(this.duplicatesMessage, this);
        }

        if (this.maxLength && value && value.length > this.maxLength) {
            return errorMessage(this.maxLengthMessage, this);
        }

        if (this.minLength && value && value.length < this.minLength) {
            return errorMessage(this.minLengthMessage, this);
        }

        const errors = value.map((part)=> this.child.validate(part, params));
        if (noErrors(errors)) {
            return null;
        }

        return errors;
    },
};

export const DateField = {
    ...Field,

    isRequired: false,
    format: 'YYYY-MM-DD',
    maxDateErrorMsg: '',
    minDateErrorMsg: '',

    _inputFormat: null,
    get inputFormat() {
        return this._inputFormat || settings.USER?.['date_format_str'];
    },
    set inputFormat(value) {

        this._inputFormat = value;

        return value;
    },

    toRepresentation(value) {
        if (value && !moment.isMoment(value)) {
            value = moment(value, this.inputFormat);
        }

        if (value && value.isValid()) {
            value = value.format(this.format);
        }
        return {value, errors: this.validate(value)};
    },
    toInternalValue(value) {
        if (value && !moment.isMoment(value)) {
            value = moment(value, this.format);
        }
        return value;
    },
    validate(value, {strictDate=false}={}) {
        if (!value) {
            if (this.isRequired) {
                return errorMessage(this.emptyMessage, this);
            }
            return null;
        }

        if (!moment.isMoment(value)) {
            value = moment(value, this.format, strictDate);
        }

        if (!value.isValid()) {
            return gettext('Please enter a valid date');
        }

        if (this.maxDate && this.maxDate.isBefore(value)) {

            if (this.maxDateErrorMsg) {
                return errorMessage(this.maxDateErrorMsg);
            }
            else {
                const maxFormatted = moment(this.maxDate).add(1, 'day').format(this.inputFormat);
                return gettext('Please select a date earlier than {maxFormatted}', {maxFormatted});
            }
        }

        if (this.minDate && value.isBefore(this.minDate)) {

            if (this.minDateErrorMsg) {
                return errorMessage(this.minDateErrorMsg);
            }
            else {
                const minFormatted = moment(this.minDate).add(-1, 'day').format(this.inputFormat);
                return gettext('Please select a date later than {minFormatted}', {minFormatted});
            }
        }

        return null;
    },
};

// We need to use shallowAssign helper instead of the spread operator
// to preserve getters and setters that StrictDateFiled inherits from DateField.
// Default Object.assign (spread) just copy values of the object and call getters instead of copying them.
export const StrictDateField = shallowAssign({}, DateField, {
    toRepresentation(value) {
        const momentValue = moment(value, this.inputFormat, true);
        const formatted = momentValue.isValid()
            ? momentValue.format(this.format)
            : value;
        return {
            value: formatted,
            errors: this.validate(formatted)};
    },
    toInternalValue(value) {
        const momentValue = moment(value, this.format, true);
        return momentValue.isValid() ? momentValue.format(this.inputFormat) : value;
    },
    validate(value) {
        return DateField.validate.call(this, value, {strictDate: true});
    }
});

export const DateTimeField = {
    ...DateField,
    // TODO: GG-19270 should be moment.ISO_8601 since moment.v3 according to
    // https://github.com/moment/moment/issues/3019
    format: 'YYYY-MM-DDTHH:mm:ssZ',
};


export const DateRange = {
    _inputFormat: null,
    get inputFormat() {
        return this._inputFormat || settings.USER?.['date_format_str'];
    },
    set inputFormat(value) {

        this._inputFormat = value;

        return value;
    },

    fields: {
        min: DateField,
        max: DateField,
    },
};

export const DateTimeRange = {
    ...DateRange,
    fields: {
        min: DateTimeField,
        max: DateTimeField,
    },
};


export const RequiredDateRange = {
    ...DateRange,

    validate({min, max}={}) {
        const validatedMin = required(this.fields.min).validate(min);
        const validatedMax = required(this.fields.max).validate(max);

        if (!validatedMin && !validatedMax) {
            return null;
        }

        return {
            min: validatedMin,
            max: validatedMax,
        };
    },
};


export const IntRange = {
    ...Field,
    fields: {
        min: Field,
        max: Field,
    },

    swap({min, max}) {
        if (min && max && min > max) {
            return {min: max, max: min};
        }

        return {min, max};
    },

    toRepresentation({min, max}, params) {
        const formValue = this.swap({min, max});
        const {value, errors} = this.toRepresentationFields(formValue);
        return {
            value,
            errors: noErrors(errors) ? this.validate(value, params) : errors,
        };
    },
};

export const RequiredIntRange = {
    ...IntRange,
    invalidRangeMessage: 'TO value must be greater than FROM value',
    validate({min, max}={}) {
        if (isNumber(min) && isNumber(max)) {
            if (min > max) {
                return this.invalidRangeMessage;
            }

            return null;
        }

        return {
            min: isNumber(min) ? null : this.emptyMessage,
            max: isNumber(max) ? null : this.emptyMessage,
        };
    },
};

export const IataCodeField = {
    ...CharField,
    validate: combineValidators(
        CharField.validate,
        iataCodeValidator()
    ),
};


const IATA_NUMBER_REGEX = /^[0-9]{7,8}$/;
const IATA_ERROR_MESSAGE = 'IATA number should be 7 or 8 characters long (digits only)';

export const IataNumberField = {
    ...CharField,
    validate(number) {
        if (!number) {
            return this.validateEmptyValue(number);
        }
        return IATA_NUMBER_REGEX.test(number) ? null : IATA_ERROR_MESSAGE;
    }
};


export const TranslatedTextField = {
    ...Field,

    maxLength: 400,
    maxLengthMessage: 'Ensure this field has no more than 400 characters',
    textsRequired: false,

    validate(value, {defaultLanguage}={}) {
        let empty = !value || isEmpty(value) || !some(values(value));
        if (this.isRequired && empty) {
            return this.emptyMessage;
        }

        if (empty || !this.maxLength) { return null; }

        const langErrors = Object.keys(value).reduce((errors, lang)=> {
            if (isString(value[lang]) && value[lang].length > this.maxLength) {
                errors = {...errors, [lang]: this.maxLengthMessage};
            }
            if (this.textsRequired && !value[lang]) {
                errors = {...errors, [lang]: this.emptyMessage};
            }

            return errors;
        }, {});

        const errors = (this.isRequired && defaultLanguage && !value[defaultLanguage])
            ? {...langErrors, [defaultLanguage]: this.emptyMessage}
            : langErrors;

        return isEmpty(errors) ? null : errors;
    }
};

export const HotelLabelingTranslatedTextField = {
    ...TranslatedTextField,
    validate(value, {defaultLanguage} = {}) {
        let empty = !value || isEmpty(value) || some(values(value), isEmpty);
        if (this.isRequired && empty) {
            return this.emptyMessage;
        }

        return TranslatedTextField.validate.call(this, value, defaultLanguage);
    }
};


export const ColorField = {
    ...RegexField,

    regex: /^#[a-f0-9]{6}$/i,
    invalidFormatMessage: 'Please enter a valid hex color.',
    emptyMessage: 'Please enter a valid hex color.',
};


export const RateCodesArray = {
    ...ArrayField,
    checkUnique({name, value}, currentIndex, values) {
        if (!name && value) return 'Rate Code can\'t be empty';
        const isUnique = values.some((elem, index) => {
            return elem.name === name && currentIndex !== index;
        });
        return !isUnique || !name ? null : 'Rate Code is not unique';
    },

    validate(values) {
        let errors = values.map((rateCode, currentIndex) => {
            const uniqueError = this.checkUnique(rateCode, currentIndex, values);
            const validError = AlphanumericField.validate(rateCode.name);
            return validError ? validError :
                uniqueError ? uniqueError : null;
        });
        return noErrors(errors) ? null : errors;

    },
};

export const NamePart = {
    ...CharField,
    validate: combineValidators(CharField.validate, nameValidator),
};

export const RemarkField = {
    fields: {
        'name': required(CharField),
        'value': required(CharField),
    },
};

export const UrlField = {
    ...Field,
    isRequired: true,
    validate(value) {
        if (!value) {
            return this.validateEmptyValue(value);
        }
        const regex = /^(http|https)+:\/\/+(www\.)?[-a-zA-Z0-9@:%.\+~#=/]{1,256}(\.|\/)[a-zA-Z0-9()]{1,256}\b([\/-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const isCorrect = value.match(regex);
        if (isCorrect) {
            return null;
        }
        return 'Please enter a valid url.';
    },
};
