import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

function wrap(value) {
    return React.isValidElement(value)
        ? value
        : (value ? (<span>{value}</span>) : null);
}


export default class AnnotateTooltip extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        children: PropTypes.any,
        placement: PropTypes.string,
    };

    static defaultProps = {
        placement: 'top',
    };

    renderTooltip(id, contents, placement) {
        return (<Tooltip id={id} placement={placement}>{wrap(contents)}</Tooltip>);
    }

    render() {
        const {id, placement} = this.props;
        const [text, annotate] = React.Children.toArray(this.props.children);
        if (!annotate) {
            return wrap(text);
        }
        return (<OverlayTrigger placement={placement} overlay={this.renderTooltip(id, annotate, placement)}>
            {wrap(text)}
        </OverlayTrigger>);

    }
}
