import React from 'react';

import PropTypes from 'prop-types';

import gettext from 'airborne/gettext';
import {providerCode} from 'midoffice/helpers/format';

import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';

export default class NoRatesModal extends React.Component {
    static propTypes = {
        kind: PropTypes.string.isRequired,
        message: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string
        ]),
        details: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string
        ]),
        provider: PropTypes.string,
        onHide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
        };
        this.handleDetails = this.handleDetails.bind(this);
    }

    handleDetails() {
        this.setState({showDetails: true});
    }

    renderDetails(details) {
        if (!this.state.showDetails) {
            return (<Button className="btn-error-details" bsStyle="link" onClick={this.handleDetails}>
                {gettext('See details »')}
            </Button>);
        }
        return (<pre className="error-text">{details}</pre>);
    }

    render() {
        const {onHide, details, provider} = this.props;
        return (
            <Modal show keyboard={false} backdrop="static" onHide={onHide} >
                <div className="info-modal">
                    <Modal.Body>
                        <p>
                            {provider && (<span key="p">[{providerCode(provider)}]</span>)}
                            &nbsp;
                            {gettext('No rates available for selected hotel. Please try changing dates or hotel.')}
                        </p>
                        {details && this.renderDetails(details)}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={onHide} bsStyle="primary" bsSize="large" >{gettext('Ok')}</Button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
}
