import settings from 'airborne/settings';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import Input from 'midoffice/newforms/widgets/Input';
import Select from 'midoffice/newforms/widgets/Select';
import Button from 'midoffice/components/Button';
import InternationalPhoneNumber from 'airborne/checkout_air/InternationalPhoneNumber';
import omit from 'lodash/omit';

import {AddressShape} from './propTypes';


function states(country) {
    return settings.COUNTRY_STATES[country];
}

function fixCountryCode(address) {
    const COUNTRY_CODES = settings.COUNTRIES.map(([code])=> code);
    const {'credit_card_country_code': countryCode, ...rest} = address;
    return COUNTRY_CODES.includes(countryCode) ? address : rest;
}

export default class BillingAddress extends React.Component {
    static propTypes = {
        defaultAddress: AddressShape,
        withPhone: PropTypes.bool,
        fieldInputCol: PropTypes.number,
        zipInputCol: PropTypes.number,
    };

    static defaultProps = {
        fieldInputCol: 6,
        zipInputCol: 3,
    }

    static contextTypes = {
        onFormPatch: PropTypes.func.isRequired,
        form: PropTypes.shape({
            value: PropTypes.object.isRequired,
        }).isRequired,
    };

    getStates() {
        const {value} = this.context.form;
        return states(value && value['credit_card_country_code']);
    }

    handlePrefill = (event)=> {
        event.preventDefault();

        const {withPhone} = this.props;
        const defaultAddress = withPhone ? 
            this.props.defaultAddress : 
            omit(this.props.defaultAddress, 'phone');
        
        const address = Object.entries(defaultAddress)
            .reduce((acc, [key, value])=> ({
                ...acc,
                ['credit_card_' + key]: value,
            }), {});
        this.context.onFormPatch(fixCountryCode(address));
    };

    render() {
        const {
            defaultAddress, withPhone,
            fieldInputCol, zipInputCol,
        } = this.props;
        const stateChoices = this.getStates();

        return (<div>
            {defaultAddress && (<div className="billing-form__control">
                <h4 className="billing-form__title">
                    { gettext('Billing address from TSPM') }
                </h4>
                <Button bsSize="small" onClick={this.handlePrefill} >
                    { gettext('Use TSPM address data') }
                </Button>
                <p className="billing-form__text">
                    <strong>{ gettext('Note:') }</strong>
                    &nbsp;
                    {gettext('The address data from TSPM may be inaccurate. Please check it carefully and correct if needed before proceeding.')}
                </p>
            </div>)}
            <Select.Field label={gettext('Country')}
                name="credit_card_country_code"
                choices={settings.COUNTRIES}
                allowEmpty
                inputCol={fieldInputCol} />
            {stateChoices && (<Select.Field
                label={gettext('State/Province')}
                name="credit_card_state_province_code"
                choices={Object.entries(stateChoices)}
                required
                allowEmpty
                inputCol={fieldInputCol} />)}
            <Input.Field
                label={gettext('Company Name')}
                placeholder={gettext('Company Name')}
                name="credit_card_company_name"
                inputCol={fieldInputCol} />
            <Input.Field
                label={gettext('Address')}
                placeholder={gettext('Address')}
                name="credit_card_address"
                inputCol={fieldInputCol} />
            <Input.Field
                label={gettext('Address 2')}
                placeholder={gettext('Address 2')}
                name="credit_card_address2"
                inputCol={fieldInputCol} />
            <Input.Field
                label={gettext('City')}
                placeholder={gettext('City')}
                name="credit_card_city"
                inputCol={fieldInputCol} />
            <Input.Field
                label={gettext('ZIP/Postal Code')}
                placeholder={gettext('ZIP/Postal Code')}
                name="credit_card_postal_code"
                inputCol={zipInputCol} />
            {withPhone && <InternationalPhoneNumber.Field
                label={gettext('Billing Phone')}
                placeholder={gettext('Billing Phone')}
                name="credit_card_phone"
                inputCol={fieldInputCol} />}
        </div>);
    }
}
