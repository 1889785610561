import Modal from 'midoffice/components/Modal';
import React from 'react';

import PropTypes from 'prop-types';

export default class DiffModal extends React.Component {
    static propTypes = {
        diff: PropTypes.string.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    render() {
        const buttons = [{
            label: 'Done',
            action: this.props.onHide,
            bsStyle: 'primary',
            className: 'input-small btn-secondary',
        }];
        return (
            <Modal className="modal-window-container modal-wide"
                   title="Compare Changes" show
                   onHide={this.props.onHide}
                   buttons={buttons}>
                <div dangerouslySetInnerHTML={{__html: this.props.diff}} />
            </Modal>
        );
    }
}
