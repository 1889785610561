import getAirExchangeRoot from './root';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {getAirExchangeFormValue} from 'airborne/store/modules/exchange_air/selectors/form';
import flow from 'lodash/flow';
import map from 'lodash/fp/map';
import uniqBy from 'lodash/fp/uniqBy';
import {getSegmentsDurationObject} from 'airborne/search_air/helpers';
import {getDateStringFromDatetime} from 'airborne/exchange_air/helpers';

const getAirExchangeParams = (state) => getAirExchangeRoot(state).params;
export const isAirExchangeActive = (state) => getAirExchangeParams(state).active;
export const isAirExchangeLoading = (state) => getAirExchangeParams(state).loading;
export const isAirExchangeFlow = (state) => isAirExchangeActive(state) || isAirExchangeLoading(state);
export const getAirExchangeCurrentStep = (state) => getAirExchangeParams(state).step;
export const isAirManualExchange = (state) => isAirExchangeFlow(state) && getAirExchangeParams(state).manualExchange;

export const getAirExchangeCurrentBooking = (state) => getAirExchangeParams(state).currentBooking;
export const getAirExchangeBookingCompany = (state) => {
    const {company=null} = getAirExchangeCurrentBooking(state);
    return company === null ? null : {
        value: company.id,
        label: company.name,
        tspmId: company.tspmId,
    };
};
export const getAirExchangeOriginDestinations = (state) => getAirExchangeCurrentBooking(state).originDestinations;
export const getAirExchangeBookingPNR = (state) => getAirExchangeCurrentBooking(state)?.pnr?.id;
export const getAirBeforeExchangeCurrency = (state) => getAirExchangeParams(state).beforeExchangeCurrency;

const getAirExchangeBookingRefreshedOriginDestinations = (state) => {
    const {segments, originDestinations} = getAirExchangeCurrentBooking(state);

    // In External bookings we don't have "refreshed" segments
    // The ones in fare_group are considered as refreshed
    if (!segments) {
        return originDestinations;
    }

    const originDestinationIds = flow(
        uniqBy('originDestinationId'),
        map('originDestinationId')
    )(segments);

    return originDestinationIds.map(originDestinationId => {
        const currentSegments = segments.filter(segment => segment.originDestinationId === originDestinationId);
        const {length, [0]: firstSegment, [length - 1]: lastSegment} = currentSegments;

        return {
            segments: currentSegments,
            departure: firstSegment.departure,
            arrival: lastSegment.arrival,
            duration: getSegmentsDurationObject(firstSegment, lastSegment)
        };
    });
};

export const getAirExchangeBookingOriginDestinationCodes = (state) => {
    const originDestinations = getAirExchangeBookingRefreshedOriginDestinations(state);

    return originDestinations.map(originDestination => {
        const {departure, arrival} = originDestination;

        return [departure.airport.code, arrival.airport.code];
    });

};

export const getAirExchangeBookingDates = (state) => {
    return getAirExchangeBookingRefreshedOriginDestinations(state).map(originDestination => (
        getDateStringFromDatetime(originDestination.departure.datetime)
    ));
};

export const getAirExchangeFlownIndicators = (state) =>
    getAirExchangeBookingRefreshedOriginDestinations(state).map(
        OD => OD.segments.every(({flownIndicator}) => flownIndicator)
    );

export const getExchangeFlownFlightOption = (state) => {
    const [flownOD] = getAirExchangeBookingRefreshedOriginDestinations(state);
    const {originDestinations} = getAirExchangeFormValue(state);
    const [flownODFormValue] = originDestinations;

    const {
        pickUp: {iataCode: departureIata},
        dropOff: {iataCode: arrivalIata}
    } = flownODFormValue;

    const {duration, segments} = flownOD;
    const carriers = segments.map(({carrier}) => carrier);

    return {
        segments,
        duration,
        departureIata,
        arrivalIata,
        carriers: uniqBy('code')(carriers),
    };
};

const getAirExchangeActiveTicket = (state) => {
    const booking = getAirExchangeCurrentBooking(state);
    return booking?.tickets?.find((ticket) => ['TICKETED', 'OPEN'].includes(ticket.status));
};

export const getAirExchangeBookingKey = (state) => {
    return isAirExchangeFlow(state)
        ? getAirExchangeActiveTicket(state)?.exchangeKey
        : null;
};

export const getAirExchangeModifyFlags = (state) => {
    return getAirExchangeCurrentBooking(state)?.modifyFlights;
};

export const getAirExchangeUnderlyingProvider = (state) => getAirExchangeCurrentBooking(state)?.underlyingProvider;

export const getAirExchangeBookingTravelerPrice = (state) => {
    const activeTicket = getAirExchangeActiveTicket(state);

    return activeTicket?.fareGroup?.travelerGroup?.travelerPrice;
};

export const getAirExchangeBookingBaseFarePrice = (state) => {
    const activeTicket = getAirExchangeActiveTicket(state);

    return activeTicket?.ticketTotal?.originalBaseTotalAmount;
};

export const isFirstExchangeOriginDestinationFlown = (state) => {
    const isAirExchangeFlowActive = isAirExchangeFlow(state);
    const {tripType, originDestinations} = getAirExchangeFormValue(state);

    return isAirExchangeFlowActive &&
        tripType === AIR_TRIP_TYPES.ROUND_TRIP &&
        originDestinations[0].flownIndicator;
};

const getFlownIndicatorsByOriginDestinations = (state) => {
    const isAirExchangeFlowActive = isAirExchangeFlow(state);
    const {originDestinations} = getAirExchangeFormValue(state);
    
    if (!isAirExchangeFlowActive) {
        return [];
    }
    
    return originDestinations.map((originDestination) => originDestination.flownIndicator);
};

export const isAnyDestFlown = (state) => {
    const isAirExchangeFlowActive = isAirExchangeFlow(state);
    
    if (!isAirExchangeFlowActive) {
        return false;
    }
    
    const flownIndicators = getFlownIndicatorsByOriginDestinations(state);
    return flownIndicators.some(flown => flown);
};

export const getFlownLightOriginDestinations = (state) => {
    const isAirExchangeFlowActive = isAirExchangeFlow(state);

    if (!isAirExchangeFlowActive) {
        return [];
    }

    const {originDestinations: originDestinationsFormValue} = getAirExchangeFormValue(state);
    const originDestinations = getAirExchangeOriginDestinations(state);
    
    return originDestinationsFormValue
        .map((originDestinationFormValue, ODIndex) => {
            const originDestination = originDestinations[ODIndex];
            const segments = originDestination.segments;
            const carriers = segments.map(({carrier}) => carrier);
            
            return {
                flownFlightOption: {
                    segments,
                    duration: originDestination.duration,
                    carriers: uniqBy('code')(carriers)
                },
                departureIata: originDestinationFormValue.pickUp.iataCode,
                arrivalIata: originDestinationFormValue.dropOff.iataCode,
                ODIndex,
                flown: Boolean(originDestinationFormValue.flownIndicator)
            };
        })
        .filter((originDestinationFormValue) => originDestinationFormValue.flown);
};
