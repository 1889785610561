import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import noop from 'lodash/noop';
import Button from 'midoffice/components/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
function countChildren(children) {
    return Array.isArray(children) ? children.filter((c)=> c).length : 0;
}


const LABEL_PROP_TYPE = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
]);


export class Row extends React.Component {

    static propTypes = {
        highlight: PropTypes.bool,
        error: PropTypes.bool,
        selectable: PropTypes.bool,
        collapse: PropTypes.bool,
        disabled: PropTypes.bool,
        checked: PropTypes.bool,
        onCheck: PropTypes.func,
        onClick: PropTypes.func,
        children: PropTypes.any,
        actions: PropTypes.array,
        onAction: PropTypes.func,
        id: PropTypes.string,
        renderExpand: PropTypes.func,
    };

    static defaultProps = {
        selectable: true,
        renderExpand: noop,
        actions: [],
        onAction: () => {},
        checked: false,
    };

    state = {
        open: false,
    };

    nop(event) {
        event.stopPropagation();
    }

    handleCollapse = () => this.setState(({open}) => ({open: !open}));

    handleActionSelection = actionId => this.props.onAction(actionId, this.props.id);

    render() {
        const {
            error,
            highlight,
            onClick,
            onCheck,
            selectable,
            collapse,
            disabled,
            checked,
            children,
            actions,
            id,
            renderExpand,
        } = this.props;
        const {open} = this.state;
        const classes = classnames({
            warning: error || highlight || open
        });

        return (<React.Fragment>
            <tr className={classes} onClick={onClick}>
                {collapse && (
                    <td onClick={this.nop}>
                        <Button onClick={this.handleCollapse} className="btn-link btn-sm text-xl">
                            <Glyphicon glyph={open ? 'minus' : 'plus'} />
                        </Button>
                    </td>
                )}
                {selectable && (
                    <td onClick={this.nop}>
                        <input type="checkbox" disabled={disabled} onChange={onCheck} checked={checked} />
                    </td>
                )}
                {children}
                {actions.length > 0 && (<td><DropdownButton title="More…" bsSize="small" pullRight id={id + '.actions'} onSelect={this.handleActionSelection}>
                    {actions.map((action, idx) => (action ? <MenuItem eventKey={action.id} key={idx}>{action.label}</MenuItem> : null))}
                </DropdownButton></td>)}
            </tr>
            {collapse && open && renderExpand()}
        </React.Fragment>);
    }
}

export class Empty extends React.Component {

    static propTypes = {
        label: LABEL_PROP_TYPE.isRequired,
        error: PropTypes.bool,
    };

    render() {
        const {label, error} = this.props;
        const className = classnames({
            'table-empty-message': true,
            'table-empty-message--error': error,
        });

        return (<tbody>
            <tr>
                <td colSpan="100" className={className}>
                    {label}
                </td>
            </tr>
        </tbody>);
    }
}

export class CommonTable extends React.Component {

    static propTypes = {
        small: PropTypes.bool,
        bsStyle: PropTypes.string,
        sortBy: PropTypes.string,

        emptyLabel: LABEL_PROP_TYPE.isRequired,
        errorLabel: LABEL_PROP_TYPE,
        loadingLabel: LABEL_PROP_TYPE,

        onCheckAll: PropTypes.func,
        onSortBy: PropTypes.func,

        heads: PropTypes.arrayOf(PropTypes.shape({
            w: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            help: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            field: PropTypes.string,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
        })).isRequired,
        children: PropTypes.any,

        selectable: PropTypes.bool,
        collapse: PropTypes.bool,
        actions: PropTypes.array,
        disabled: PropTypes.bool,
        allChecked: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.bool,
    };

    handleSort(field) {
        const {sortBy, onSortBy} = this.props;

        onSortBy((field && field === sortBy) ? `-${field}` : field);
    }

    renderHead({field, help: helpText, w: width, label, sortable=true}, index) {
        const {sortBy, onSortBy} = this.props;

        const headCls = classnames({
            sorted: field && (field === sortBy || ('-' + field === sortBy))
        });

        const clickable = sortable && onSortBy && field;
        const labelCls = classnames({
            clickable,
            asc: clickable && sortBy === field,
            desc: clickable && sortBy === ('-' + field),
        });

        const onClick = clickable ? this.handleSort.bind(this, field) : null;

        const help = helpText ? (
            <OverlayTrigger placement="bottom" overlay={<Tooltip id={field}>{helpText}</Tooltip>}>
                <span className="muted">[?]</span>
            </OverlayTrigger>
        ) : null;

        return (
            <th style={{width}} key={index} className={headCls} onClick={onClick}>
                <div className={labelCls}>{label} {help}</div>
            </th>
        );
    }

    renderEmptyTh(width=100) {
        return (<th style={{width}} />);
    }

    renderCheckAll() {
        const {onCheckAll, allChecked, disabled} = this.props;

        return (
            <th style={{width: 26}}>
                <input type="checkbox" onChange={onCheckAll} checked={allChecked} disabled={disabled}/>
            </th>
        );
    }

    renderEmpty() {
        return (<Empty label={this.props.emptyLabel} />);
    }

    renderError() {
        return (<Empty label={this.props.errorLabel} error />);
    }

    renderLoading() {
        return (<Empty label={this.props.loadingLabel} />);
    }

    renderBody() {
        const {children, loading, error} = this.props;

        if (error) { return this.renderError(); }
        if (loading) { return this.renderLoading(); }
        if (
            children.length === 0 ||
            children.type === 'tbody' && countChildren(children.props.children) === 0
        ) {
            return this.renderEmpty();
        }

        return children;
    }

    render() {
        const {
            bsStyle, selectable, collapse, actions, heads, small,
        } = this.props;

        const className = classnames({
            'table': true,
            'table--light': bsStyle.indexOf('light') > -1,
            'table-common': bsStyle.indexOf('common') > -1,
            'table-striped': bsStyle.indexOf('striped') > -1,
            'table-hover': bsStyle.indexOf('hover') > -1,
            'table-common--small': small,
        });

        return (
            <table className={className}>
                <thead>
                    <tr>
                        {collapse && this.renderEmptyTh(36)}
                        {selectable && this.renderCheckAll()}
                        {heads.map(this.renderHead, this)}
                        {actions && this.renderEmptyTh(36)}
                    </tr>
                </thead>
                {this.renderBody()}
            </table>
        );
    }
}
