
import React from 'react';
import PropTypes from 'prop-types';
import {injectField} from 'midoffice/newforms/decorators';

import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import FieldError from 'midoffice/newforms/components/FieldError';
import Select from 'midoffice/newforms/widgets/Select';
import Input from 'midoffice/newforms/widgets/Input';

import classnames from 'classnames';
import gettext from 'airborne/gettext';

export class AddMore extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        label: PropTypes.string,
        testId: PropTypes.string,
    };

    render() {
        const {onClick, disabled, label, testId} = this.props;

        if (disabled) { return null; }

        return (
            <div className="form-group__sub pull-right">
                <Button bsStyle="link" bsSize="xs" className="btn-link--narrow" onClick={onClick} data-testid={testId}>
                    {label}
                </Button>
            </div>
        );
    }
}


@injectField
export default class IdNumbers extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        errors: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        inputPlaceholder: PropTypes.string.isRequired,
        vendors: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        onlyValue: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        onRemove: PropTypes.func.isRequired,
        hideRemove: PropTypes.bool,
    };

    handleChange = (fieldValue, fieldName)=> {
        const {value, onChange} = this.props;
        onChange({...value, [fieldName]: fieldValue});
    };

    renderRemove() {
        const {onRemove} = this.props;
        return (
            <Button bsStyle="link" className="highlight-red form-group__select-group__control" onClick={onRemove}>
                <Glyphicon glyph="remove" />
            </Button>
        );
    }

    render() {
        const {value: {vendor, number}, errors, vendors, disabled, inputPlaceholder, hideRemove, onBlur} = this.props;

        const className = classnames(
            'form-group__select-group',
            {'has-error': errors}
        );

        return (
            <div className={className}>
                <div className="form-group__select-group__wrapper">
                    <Select
                        name="vendor"
                        inputSize="max"
                        onChange={this.handleChange}
                        onBlur={onBlur}
                        choices={vendors}
                        value={vendor}
                        allowEmpty
                        placeholder={gettext('Vendor')}
                        disabled={disabled} />
                    <div className="custom-input">
                        <Input
                            name="number"
                            value={number}
                            onChange={this.handleChange}
                            onBlur={onBlur}
                            placeholder={inputPlaceholder}
                            disabled={disabled}
                        />
                        {errors && <FieldError position="bottom" errors={errors} />}
                    </div>
                </div>
                {!hideRemove && this.renderRemove()}
            </div>
        );
    }
}
