import get from 'lodash/get';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import {connect} from 'react-redux';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Overlay from 'react-bootstrap/lib/Overlay';
import HighlightTooltip from 'airborne/homepage2/HighlightTooltip';

import {getMessageForElement} from 'airborne/store/modules/header/selectors/inbox';
import {markRead} from 'airborne/store/modules/header/actions/inbox';


const HighlightContext = React.createContext({
    didDisplay: () => {},
    willHide: () => {},
    canBeDisplayed: () => true,
});

export class HighlightProvider extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    // eslint-disable-next-line react/sort-comp
    didDisplay = (id, component) => {
        if (this.canBeDisplayed(id, component)) {
            this.setState(({displayed}) => {
                if (displayed[id]) {
                    return null;
                }
                return {
                    displayed: {
                        ...displayed,
                        [id]: component,
                    },
                };
            });
        }
    };

    willHide = (id, component) => {
        const {displayed} = this.state;
        if (this.canBeDisplayed(id, component)) {
            this.setState({
                displayed: omit(displayed, id),
            });
        }
    };

    canBeDisplayed = (id, component) => {
        return !this.state.displayed[id] || this.state.displayed[id] === component;
    };

    state = {
        displayed: {},
        didDisplay: this.didDisplay,
        willHide: this.willHide,
        canBeDisplayed: this.canBeDisplayed,
    };

    render() {
        return (<HighlightContext.Provider value={this.state}>
            {this.props.children}
        </HighlightContext.Provider>);
    }
}

@connect((state, {id})=> ({
    lang: get(state, 'i18n.lang') || 'en',
    message: getMessageForElement(state, id),
}), {markRead})
export default class Highlight extends React.Component {
    static contextType = HighlightContext;

    static propTypes = {
        id: PropTypes.string.isRequired,
        fancy: PropTypes.bool,
        lang: PropTypes.string.isRequired,
        placement: PropTypes.oneOf([
            'top', 'bottom', 'left', 'right',
        ]),
        disabled: PropTypes.bool,
        message: PropTypes.shape({
            'text': PropTypes.object.isRequired,
        }),
        markRead: PropTypes.func.isRequired,
        children: PropTypes.any,
    };

    static defaultProps = {
        fancy: false,
        placement: 'right',
    };

    componentDidMount() {
        this.context.didDisplay(this.props.id, this);
    }

    componentWillUnmount() {
        this.context.willHide(this.props.id, this);
    }

    canBeDisplayed() {
        return this.context.canBeDisplayed(this.props.id, this);
    }

    thisRef = ()=> this;

    isEnabled() {
        const {message, disabled} = this.props;
        return message && !disabled && this.canBeDisplayed();
    }

    selectText() {
        const {message, lang} = this.props;
        return message.text[lang] || message.text.en;
    }

    handleHide = ()=> {
        const {message} = this.props;
        this.props.markRead({id: message.id, element: message.element});
    };

    renderTooltip() {
        const {fancy} = this.props;
        return fancy
            ? <HighlightTooltip onHide={this.handleHide}>
                {this.selectText().split('\n')}
            </HighlightTooltip>
            : <Tooltip id="tour">
                {this.selectText()}
            </Tooltip>;
    }

    render() {
        const {placement, children, fancy} = this.props;
        return (<Fragment>
            {children}
            {this.isEnabled() && <Overlay target={this.thisRef} placement={placement} show rootClose onHide={fancy ? noop : this.handleHide}>
                {this.renderTooltip()}
            </Overlay>}
        </Fragment>);
    }
}
