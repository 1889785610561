import take from 'lodash/take';
import drop from 'lodash/drop';
import remove from 'lodash/remove';
import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'react-bootstrap';
import {removeAtIdx} from 'midoffice/helpers/editAtIdx';
import Field from './Field';
import gettext from 'airborne/gettext';
import noop from 'lodash/noop';

class FieldRow extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        index: PropTypes.number.isRequired,

        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        indexedLabel: PropTypes.bool,
        multiLabels: PropTypes.arrayOf(PropTypes.string),
        placeholder: PropTypes.string,
        multiPlaceholders: PropTypes.arrayOf(PropTypes.string),

        widget: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object,
        ]),
        plainWidget: PropTypes.bool,
    };

    focus() {
        this.componentRef && this.componentRef.focus && this.componentRef.focus();
    }

    getLabel() {
        const {label, index, indexedLabel, multiLabels} = this.props;

        if (multiLabels) {
            return multiLabels[index];
        }

        if (indexedLabel) {
            return `${label} ${index + 1}`;
        }

        return label;
    }

    getPlaceholder() {
        const {placeholder, multiPlaceholders, index} = this.props;

        if (multiPlaceholders) {
            return multiPlaceholders[index];
        }

        return placeholder;
    }


    handleChange = (value)=> {
        this.props.onChange(this.props.index, value);
    };

    handleReference = (component)=> {
        this.componentRef = component; // eslint-disable-line immutable/no-mutation
    };

    handleRemove = ()=> {
        this.props.onRemove(this.props.index);
    };
    render() {
        const {plainWidget, widget} = this.props;
        const Component = (
            plainWidget ? widget || Field
                : Field
        );

        return (
            <Component {...this.props}
                ref={this.handleReference}
                label={this.getLabel()}
                placeholder={this.getPlaceholder()}
                onRemove={this.handleRemove}
                onChange={this.handleChange} />
        );
    }
}


export default class MultiField extends React.Component {
    static propTypes = {
        child: PropTypes.object.isRequired,
        schemaParams: PropTypes.any,
        errors: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.bool,
            PropTypes.string
        ]),
        value: PropTypes.array,
        name: PropTypes.string,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        noAddMore: PropTypes.bool,
        addMoreComponent: PropTypes.func,
        addMoreLabel: PropTypes.string,
        addMoreTestId: PropTypes.string,
        emptyComponent: PropTypes.func,
        maxCountForMultiComponent: PropTypes.number,
        defaultValue: PropTypes.any,
        onDeleteAll: PropTypes.func,
        onPaste: PropTypes.func,
    };

    static get defaultProps() {
        return {
            value: [],
        };
    };

    placeholderMessages = {
        default: "Click here to bulk-paste drop down items",
        focus: "Press Ctrl+V (Cmd+V on Mac) to paste your content",
    };

    state = {
        uncommited: [],
        placeholder: this.placeholderMessages.default,
    };

    static getDerivedStateFromProps(props, state) {
        if (state.disabled !== props.disabled) {
            return {uncommited: [], disabled: props.disabled};
        }
        return null;
    }

    rowRefs = [];

    addRef(index) {
        return (row)=> {
            if (row) {
                if (index === 0) { this.rowRefs = []; } // eslint-disable-line immutable/no-mutation
                this.rowRefs.push(row);
            }
            else {
                this.rowRefs = remove(this.rowRefs, this.rowRefs[index]); // eslint-disable-line immutable/no-mutation
            }
        };
    }

    focus(index=0) {
        this.rowRefs[index] && this.rowRefs[index].focus && this.rowRefs[index].focus();
    }

    handleChange = (index, value)=> {
        let commited = [...(this.props.value || [])];
        let {uncommited} = this.state;
        const commitedQnt = commited.length;

        if (index >= commitedQnt) {
            const qntToCommit = index - commitedQnt + 1;
            commited = commited.concat(take(uncommited, qntToCommit));
            uncommited = drop(uncommited, qntToCommit);
        }

        commited[index] = value; // eslint-disable-line immutable/no-mutation
        this.setState({uncommited});
        this.props.onChange(commited);
    };

    handleAddMore = (event)=> {
        event.preventDefault();

        const {child: field, schemaParams, defaultValue, value, onChange} = this.props;
        const {uncommited} = this.state;
        const empty = field.toRepresentation(defaultValue ? defaultValue : '', schemaParams).value;

        if (defaultValue) {
            onChange([...(value || []), empty]);
        }
        else {
            this.setState({uncommited: uncommited.concat([empty])});
        }
    };

    handleRemove = (index)=> {
        const {value} = this.props;
        const {uncommited} = this.state;
        const commitedQnt = value.length;

        if (index < commitedQnt) {
            this.props.onChange(removeAtIdx(value, index));
        }
        else {
            this.setState({
                uncommited: removeAtIdx(uncommited, index - commitedQnt)
            });
        }
    };

    handlePaste = (event)=> {
        const {onPaste, value} = this.props;
        if (onPaste) {
            const pastedValue = event.clipboardData.getData('Text');
            onPaste(pastedValue, value);
        }
    };

    handleDeleteAll = ()=> {
        const {onDeleteAll, value} = this.props;
        onDeleteAll(value);
    };


    handleFocus = () => {
        this.setState({placeholder: this.placeholderMessages.focus});
    };

    handleBlur = () => {
        this.setState({placeholder: this.placeholderMessages.default});
    };

    renderPasteAndDelete = () => {
        const {onPaste, onDeleteAll} = this.props;

        if (!onDeleteAll && !onPaste) {
            return null;
        }

        return (<div className="well-section__control">
            {onPaste && (<input className="form-control well-section__control__input"
                value=""
                id={`${this.props.name}_paste`}
                placeholder={this.state.placeholder}
                onChange={noop}
                onPaste={this.handlePaste}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            />)}

            <div className="well-section__control__hint">
                <strong>Note:</strong> The data should be tab-separated, as formatted when copied from spreadsheet software like Excel,
                Google Sheets, or Numbers. This ensures proper alignment and formatting of the pasted content.
            </div>

            {onDeleteAll && (
                <Button bsStyle="link" id="delete_all" style={{marginLeft: 'auto'}} onClick={this.handleDeleteAll}>Delete all</Button>
            )}
        </div>);
    };

    renderAddMore = () => {
        const {
            value: values,
            maxCountForMultiComponent,
            disabled,
            addMoreComponent: AddMoreComponent,
            addMoreLabel,
            addMoreTestId
        } = this.props;

        const {uncommited} = this.state;
        const allValues = (values || []).concat(uncommited);
        const isShowAddMoreBtn = Boolean(maxCountForMultiComponent) && maxCountForMultiComponent <= allValues.length;
        if (isShowAddMoreBtn) return null;
        return AddMoreComponent ? (
            <AddMoreComponent onClick={this.handleAddMore} disabled={disabled} label={addMoreLabel} testId={addMoreTestId} />
        ): (
            <div className="block-trigger">
                {!disabled && (
                    <a href="#"
                        className="block-trigger__text"
                        onClick={this.handleAddMore}
                        data-testid={addMoreTestId}>
                        {addMoreLabel || gettext('+ Add More')}
                    </a>
                )}
            </div>
        );
    }

    render() {
        const {value: values, errors, disabled, noAddMore, emptyComponent: EmptyComponent} = this.props;
        const {uncommited} = this.state;

        const allValues = (values || []).concat(uncommited);
        return (
            <>
                {!allValues.length && !!EmptyComponent && <EmptyComponent />}
                {allValues.map((value, index)=>
                    <FieldRow {...this.props}
                        ref={this.addRef(index)}
                        removable={!disabled}
                        onlyValue={allValues.length === 1}
                        onRemove={this.handleRemove}
                        index={index}
                        key={(this.props.name || '') + index}
                        value={value}
                        errors={errors && errors[index]}
                        onChange={this.handleChange} />
                )}
                {noAddMore ? null : this.renderAddMore()}
                {this.renderPasteAndDelete()}
            </>
        );
    }
}
