import React from 'react';
import gettext from 'airborne/gettext';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import {Glyphicon} from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/lib/DropdownMenu';

const getTranslation = (translations, lang) => {
    const translation = translations?.find(({language}) => language === lang);

    if (translation) {
        return translation;
    }

    return translations?.find(({language}) => language === 'en');
};

const OutOfPolicy = ({ policyRules = [], outOfPolicyRules = [], lang = 'en' }) => {
    const rules = policyRules.filter(({id}) => outOfPolicyRules.includes(id));

    return (
        <div className="h-item__policy" data-testid="out-of-policy-tooltip">
            <AnnotateTooltip id="out-of-policy" onClick={e => e.stopPropagation()}>
                <Dropdown id="out-of-policy-dropdown">
                    <Dropdown.Toggle variant="default" noCaret>
                        <div className="a-content__item__label a-content__item__label--danger">
                            {gettext('Out of Policy')}
                            <Glyphicon bsClass="glyphicons" glyph="circle-info" />
                        </div>
                    </Dropdown.Toggle>

                    <DropdownMenu>
                        {rules.map(({id, ruleName, ruleTranslations}) => {
                            const translation = getTranslation(ruleTranslations, lang);

                            return (
                                <article className="h-item__errors__item" key={id}>
                                    <header className="h-item__errors__header">
                                        {translation ? translation.title : ruleName}
                                    </header>
                                    {
                                        translation?.description &&
                                            <p className="h-item__errors__text">
                                                {translation.description}
                                            </p>
                                    }
                                </article>
                            );
                        })}
                    </DropdownMenu>
                </Dropdown>
                {gettext('Click this icon to view the out of policy reason')}
            </AnnotateTooltip>
        </div>
    );
};

export default OutOfPolicy;
