import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';

import Nav from 'react-bootstrap/lib/Nav';
import Navbar from 'react-bootstrap/lib/Navbar';
import NavItem from 'react-bootstrap/lib/NavItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import CurrencyDropdown from 'airborne/header2/CurrencyDropdown';
import LocaleDropdown from 'airborne/header2/LocaleDropdown';
import PNRButton from 'airborne/header2/PNRButton';
import PNRModWarning from 'airborne/homepage2/PNRModWarning';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import Inbox from 'airborne/header2/Inbox';
import ActionableCounter from 'airborne/common/ActionableCounter';

import {isModification} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {isAirExchangeActive} from 'airborne/store/modules/exchange_air/selectors';

const SUPPORT_LINK = 'https://getgoing1.zendesk.com/hc/en-us';
import session from 'airborne/session';

import {sendLogout} from 'airborne/store/modules/logout/actions/logout';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import browserHistory from 'airborne/browserHistory';
import StorageService from 'airborne/services/Storage';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {getCarModificationFlowBool} from 'midoffice/car-bookings/selectors';
import {hasAccess} from 'midoffice/helpers/permission';

const popupStorage = new StorageService('local', 'actionableBookings');
const countStorage = new StorageService('local', 'actionableBookingsCount');

function setActionablePopup(read) {
    popupStorage.setItem({actionableBookings: read});
}

function setActionableCounter(count) {
    countStorage.setItem({actionableBookingsCount: count});
}

function getActionablePopup() {
    const defaultValue = {actionableBookings: false};
    return popupStorage.getItem() || defaultValue;
}

function getActionableCount() {
    const defaultValue = {actionableBookingsCount: 0};
    return countStorage.getItem() || defaultValue;
}

function mapStateProps(state) {
    return {
        modification: isModification(state),
        carBookingModification: getCarModificationFlowBool(state),
        airExchangeActive: isAirExchangeActive(state),
        actionableCounter: state.actionableCounter,
        skipAbrPopup: getFeatureFlag(state,'QA_SKIP_ABR_POPUP'),
        silverdoorEnabled: getFeatureFlag(state, 'SILVERDOOR_ENABLED'),
    };
}

@connect(mapStateProps, {
    logout: sendLogout,
})
export default class Header extends React.Component {
    static propTypes = {
        modification: PropTypes.bool,
        airExchangeActive: PropTypes.bool,
        logout: PropTypes.func,
        actionableCounter: PropTypes.number,
        skipAbrPopup: PropTypes.bool,
        silverdoorEnabled: PropTypes.bool,
        carBookingModification: PropTypes.bool,
    };

    state = {
        viewedNotification: getActionablePopup()?.actionableBookings,
    };

    componentDidMount() {
        const newCount = this.getCount();
        const oldCount = getActionableCount() || {};
        if (
            oldCount &&
            oldCount?.actionableBookingsCount !== newCount && newCount > oldCount?.actionableBookingsCount
        ) {
            setActionablePopup(false);
            setActionableCounter(newCount);
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({viewedNotification: false});
        }
    }

    shortcuts = {
        home: SHORTCUTS_LIST.E,
        logout: SHORTCUTS_LIST.LOGOUT,
        settings: SHORTCUTS_LIST.SETTINGS,
        records: SHORTCUTS_LIST.B,
    };

    gotoSettings() {
        browserHistory.push('/ui/profile-settings/');
    }

    gotoHome() {
        browserHistory.push('/ui/homepage/');
    }

    closeActionablePopup = () => {
        setActionablePopup(true);
        setActionableCounter(this.getCount());
        this.setState({
            viewedNotification: true,
        });
    };

    gotoRecords = event => {
        event.preventDefault();
        const isParentClick = event.target === event.currentTarget;
        if (isParentClick) {
            browserHistory.push('/bookings/hotels/');
            this.closeActionablePopup();
        }
    };

    goToRecordsFromAbrPopUp = () => {
        const agentsCheckBoxChecked = this.getNotificationState();
        this.closeActionablePopup();
        browserHistory.push('/bookings/hotels/', {checkedBox: agentsCheckBoxChecked});
    };

    getCount = () => {
        const {actionableCounter} = this.props;
        return Number.isInteger(actionableCounter) ? actionableCounter : settings['ACTIONABLE_BOOKINGS'];
    };

    getNotificationState = () => {
        const count = this.getCount();
        const isAft = settings['SUB_PARTNER'] === 'aft' || settings['DISPLAY_MODE'] === 'aft';
        return count > 0 && isAft && !this.state.viewedNotification;
    };

    handleClick() {
        session.set('sid', '');
    }

    handleOnCloseClick = event => {
        event.preventDefault();
        event.stopPropagation();
        this.closeActionablePopup();
    };

    renderBookingRecordsLink = () => {
        const {skipAbrPopup} = this.props;
        const shouldShowNotification = this.getNotificationState();

        if (!hasAccess('aft:booking_records:edit')) {
            return null;
        }

        return (
            <>
                <NavItem className="nav-unstyled nav-popover no-margin" onClick={this.gotoRecords} id="booking-records-link">
                    <ActionableCounter>
                        <Shortcut
                            label={gettext('Booking Records')}
                            shortcut={this.shortcuts.records}
                            action={this.gotoRecords}
                        />
                    </ActionableCounter>
                    {Boolean(shouldShowNotification) && !skipAbrPopup && (
                        <div className="feature-tooltip feature-tooltip--condensed feature-tooltip--bottom">
                            <Button
                                bsStyle="link"
                                bsSize="small"
                                className="feature-tooltip__control"
                                onClick={this.handleOnCloseClick}
                            >
                                <Glyphicon bsClass={'glyphicons'} glyph={'remove-2'} />
                            </Button>
                            <h2 className="feature-tooltip__title">{gettext('Some bookings need your attention')}</h2>
                            <footer className="feature-tooltip__footer">
                                <Button bsStyle="link" name="openMessage" onClick={this.goToRecordsFromAbrPopUp}>
                                    {gettext('Click here to view')}
                                </Button>
                            </footer>
                        </div>
                    )}
                </NavItem>
            </>
        );
    };

    renderMenu() {
        const {'first_name': name} = settings.USER;
        const {silverdoorEnabled} = this.props;
        return [
            <Nav key={0} name="links">
                <NavItem href="https://need2know.tripsource.com/" className="navbar__ext-link" target="_blank">
                    {gettext('COVID-19 Information Hub')} <span className="glyphicons glyphicons-new-window" />
                </NavItem>
                {silverdoorEnabled && (
                    <NavItem
                        href="https://bcdtravel.silverdoorapartments.com/"
                        className="navbar__ext-link"
                        target="_blank"
                        data-heap="heap-silverdoor"
                    >
                        {gettext('SilverDoor')} <span className="glyphicons glyphicons-new-window" />
                    </NavItem>
                )}
                {this.renderBookingRecordsLink()}
            </Nav>,
            <Nav className="pull-right" key={1}>
                <CurrencyDropdown />
                <LocaleDropdown />
                <NavDropdown eventKey={3} title={name} pullRight id="user-menu">
                    <LinkContainer to="/ui/profile-settings/">
                        <MenuItem>
                            <Shortcut
                                label={gettext('My Settings')}
                                shortcut={this.shortcuts.settings}
                                action={this.gotoSettings}
                                isInherit
                            />
                        </MenuItem>
                    </LinkContainer>
                    <MenuItem href={SUPPORT_LINK} eventKey={3.2} target="_blank">
                        {gettext('Support')}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onSelect={this.props.logout}>
                        <Shortcut
                            label={gettext('Sign Out')}
                            shortcut={this.shortcuts.logout}
                            action={this.props.logout}
                            isInherit
                        />
                    </MenuItem>
                </NavDropdown>
            </Nav>,
            <Inbox key={2} />,
        ];
    }

    renderPNRMod() {
        return [
            <Nav className="pull-right" key={1}>
                <CurrencyDropdown />
                <LocaleDropdown />
            </Nav>,
            <PNRModWarning key={2} />,
        ];
    }

    render() {
        const {modification, airExchangeActive, carBookingModification} = this.props;
        const activeModificationFlow = modification || carBookingModification;

        return (
            <Navbar className={{'navbar-exchange': airExchangeActive}}>
                <Navbar.Header onClick={this.handleClick}>
                    <Navbar.Brand>
                        <a href="/" className="logo" />
                    </Navbar.Brand>
                </Navbar.Header>
                {activeModificationFlow ? this.renderPNRMod() : this.renderMenu()}
                <PNRButton />
            </Navbar>
        );
    }
}
