import React from 'react';
import PropTypes from 'prop-types';
import {injectField} from 'midoffice/newforms/decorators';
import gettext from 'airborne/gettext';

import Input from 'midoffice/newforms/widgets/Input';
import Radio from 'midoffice/newforms/widgets/Radio';
import RadioWithCustomInput from 'midoffice/newforms/widgets/RadioWithCustomInput';
import Button from 'react-bootstrap/lib/Button';
import Select from 'midoffice/newforms/widgets/Select';
import FieldError from 'midoffice/newforms/components/FieldError';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

export class AddMoreNegotiatedNumber extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        label: PropTypes.string,
    };

    render() {
        const {onClick, disabled, label} = this.props;

        if (disabled) { return null; }

        return (
            <div className="form-group__sub pull-right">
                <Button bsStyle="link" bsSize="xs" className="btn-link--narrow" onClick={onClick}>
                    {label}
                </Button>
            </div>
        );
    }
}


function getLabel(vendors, vendor, choices) {
    if (!vendor || !choices.hasOwnProperty(vendor)) return '';
    const label = vendors.filter(([key])=> key === vendor)[0][1];
    return (<span className="input-group-label" style={{width: '40%'}}>{label}</span>);
}

@injectField
export default class NegotiatedNumbers extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        vendors: PropTypes.array.isRequired,
        errors: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        choices: PropTypes.object.isRequired,
        inputPlaceholder: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onlyValue: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        handleIdCdChange: PropTypes.func,
    };

    handleChangeNumber = (number, name) => {
        const {value, onChange, handleIdCdChange} = this.props;
        const {vendor} = value;
        onChange({...value, [name]: number});

        if (handleIdCdChange) {
            handleIdCdChange(vendor, number);
        }
    };
    handleChangeCustom = (number) => {
        const {value, onChange} = this.props;
        onChange({...value, 'customInput': number});
    };
    renderEmpty = () => {
        const {value: {vendor, number}, disabled, vendors, choices, errors, inputPlaceholder, onlyValue} = this.props;
        const className = classnames(
            'form-group__select-group',
            {'has-error': errors}
        );
        const choicesWithoutSelectedVendors = vendors?.filter(vendor => !choices.hasOwnProperty([vendor[0]])) || [];
        return (<div className={className}>
            <div className="form-group__select-group__wrapper">
                <Select
                    name="vendor"
                    inputSize="max"
                    onChange={this.handleChangeNumber}
                    choices={choicesWithoutSelectedVendors}
                    value={vendor}
                    allowEmpty
                    placeholder={gettext('Vendor')}
                    disabled={disabled} />
                <div className="custom-input">
                    <Input
                        name="number"
                        value={number}
                        onChange={this.handleChangeNumber}
                        placeholder={inputPlaceholder}
                        disabled={disabled} />
                </div>
            </div>
            {errors && <FieldError position="bottom" errors={errors} />}
            {!onlyValue && this.renderRemove()}
        </div>);
    }

    renderNumber() {
        const {value: {vendor, number: propsCompanyNumber, agencyNumber: propsAgencyNumber, customInput}, choices, disabled, errors, name} = this.props;
        const  {customInput: customInputError} = errors || {};
        if (isEmpty(choices) || !vendor || !choices.hasOwnProperty(vendor)) {
            return this.renderEmpty();
        }

        if (choices[vendor] && choices[vendor].length === 1) {
            const [[, label, isBcdCd]] = choices[vendor];
            const field = isBcdCd ? 'agencyNumber' : 'number';
            return (
                <Input
                    name={field}
                    value={label}
                    disabled />
            );
        }

        const companyNumbers = choices[vendor].filter(([, , isBcdCode]) => !isBcdCode);
        const agencyNumbers = choices[vendor].filter(([, , isBcdCode]) => isBcdCode);

        const label = name === 'cdNumbers' ? 'CD' : 'IT';

        return (<>
            <div className="multi-selector-body">
                {companyNumbers.length > 0 && <>
                    <div className="multi-selector-body__title">{gettext(`Company ${label} Number(s)`)}</div>
                    <div style={{marginLeft: '40px'}}>
                        <RadioWithCustomInput
                            name={'number'}
                            uniqueName={'number' + label + vendor}
                            block
                            disabled={disabled}
                            choices={companyNumbers}
                            className={'radio-choice'}
                            onChange={this.handleChangeNumber}
                            onChangeCustom={this.handleChangeCustom}
                            customInput={customInput}
                            value={propsCompanyNumber || companyNumbers[0][0]}
                            placeholderCustomInput={gettext(`Custom ${label} Number`)}
                            customInputError={customInputError}
                        />
                    </div>
                </>}
                {agencyNumbers.length > 0 && <>
                    <div className="multi-selector-body__title">{gettext(`Agency ${label} Number(s)`)}</div>
                    <div style={{marginLeft: '40px'}}>
                        <Radio
                            block
                            disabled={disabled}
                            name={'agencyNumber'}
                            uniqueName={'agencyNumber' + label + vendor}
                            choices={agencyNumbers}
                            onChange={this.handleChangeNumber}
                            value={propsAgencyNumber || agencyNumbers[0][0]}/>
                    </div>
                </>}
            </div>
        </>);

    }

    renderRemove() {
        const {onRemove} = this.props;
        return (
            <Button bsStyle="link" className="highlight-red form-group__select-group__control" onClick={onRemove}>
                <Glyphicon glyph="remove" />
            </Button>
        );
    }
    render() {
        const {value: {vendor}, vendors, choices} = this.props;
        return (
            <div className="form-group__select-group">
                <div className="form-group__select-group__wrapper">
                    {getLabel(vendors, vendor, choices)}
                    <div className="custom-input">
                        {this.renderNumber()}
                    </div>
                </div>
            </div>
        );
    }
}
