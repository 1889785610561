import omit from 'lodash/omit';

import {stringifyUrlQuery} from 'midoffice/helpers/url';

import * as OSIMessaging from './OSIMessaging';
import * as agenciesRateOptimization from './agenciesRateOptimization';
import * as agencyGds from './agencyGds';
import * as agentChat from './agentChat';
import * as airExclusions from './airExclusions';
import * as airPolicy from './airPolicy';
import * as airPreferredAirlinesSettings from './airPreferredAirlines';
import * as airSearchCustomizations from './airSearchCustomizations';
import * as airSortingCustomization from './airSortingCustomization';
import * as amadeusGDS from './amadeusGDS';
import * as carsHomePage from './carsHomePage';
import * as carsNegotiatedRates from './carsNegotiatedRates';
import * as carsPaymentMethodsPriority from './carsPaymentMethodsPriority';
import * as carsPreferredRates from './carsPreferredRates';
import * as carsRateExclusions from './carsRateExclusions';
import * as carsSorting from './carsSorting';
import * as carsTravelPolicy from './carsTravelPolicy';
import * as companyLocations from './companyLocations';
import * as crd from './crd';
import * as crdConfiguration from './crdConfiguration';
import * as customAirportHubs from './customAirportHubs';
import * as customCountryCodes from './customCountryCodes';
import * as dynamicSorting from './dynamicSorting';
import * as hotelExclusionRules from './hotelExclusionRules';
import * as hotelExclusions from './hotelExclusions';
import * as hotelLabeling from './hotelLabeling';
import * as hotelSorting from './hotelSorting';
import * as hrs from './hrs';
import * as lccCredentials from './lccCredentials';
import * as legal from './legal';
import * as searchEngineConfiguration from './modifyRate';
import * as negotiatedFares from './negotiatedFares';
import * as overrides from './overrides';
import * as pnr from './pnr';
import * as preferredHotels from './preferredHotels';
import * as railDeutscheBahn from './railDeutscheBahn';
import * as railTrainline from './railTrainline';
import * as rateExclusions from './rateExclusions';
import * as regionalSettings from './regionalSettings';
import * as revenueManagement from './revenueManagement';
import * as sabreContentControl from './sabreContentControl';
import * as securityVettedHotels from './securityVettedHotels';
import * as segmentConfig from './segmentConfig';
import * as settings from './settings';
import * as ssoConfigurations from './ssoConfigurations';
import * as hrsDirect from './supplierHrsDirect';
import * as supplierPremierInn from './supplierPremierInn';
import * as totalstay from './totalstay';
import * as travelPolicy from './travelPolicy';
import * as tripsourceCreditsAndUnusedTickets from './tripsourceCreditsAndUnusedTickets';
import * as tripsourceInvoiceCollection from './tripsourceInvoiceCollection';
import * as vpaSettings from './vpaSettings';
import * as officeHours from './officeHours';


const IDENTITY = (obj) => obj;

function skip(...fields) {
    return function(data) {
        return omit(data, ...fields);
    };
}

const PARSERS = {
    settings: settings.parse,
    'supplier-hrs': hrs.parse,
    'supplier-hrsd': hrsDirect.parse,
    'supplier-premierinn': supplierPremierInn.parse,
    'supplier-totalstay': totalstay.parse,
    'terms-and-conditions': legal.parse,
    'company-locations': companyLocations.parse,
    'rate-caps': travelPolicy.parse,
    'regional-settings': regionalSettings.parse,
    'client-hotels': preferredHotels.parse,
    'tmc-preferred-hotels': preferredHotels.parse,
    'crd': crd.parse,
    'invoice-collection': tripsourceInvoiceCollection.parse,
    'tripsource-credits-and-unused-tickets': tripsourceCreditsAndUnusedTickets.parse,
    'hotel-labeling': hotelLabeling.parse,
    'hotel-dynamic-sort': dynamicSorting.parse,
    'hotel-exclusions': hotelExclusions.parse,
    'hotel-exclusion-rules': hotelExclusionRules.parse,
    'branch-hotel-exclusions': hotelExclusions.parse,
    'hotel-sorting': hotelSorting.parse,
    'amadeus-credentials': agencyGds.parse,
    'sabre-credentials': agencyGds.parse,
    'apollo-credentials': agencyGds.parse,
    'galileo-credentials': agencyGds.parse,
    'farelogix-credentials': agencyGds.parse,
    'air-pnr-remarks': pnr.parse,
    'pnr-remarks': pnr.parse,
    'pnr-templates': pnr.parse,
    'general-pnr-remarks': pnr.parse,
    'cars-pnr-remarks': pnr.parse,
    'cars-pnr-templates': pnr.parse,
    'cars-preferred-rates': carsPreferredRates.parse,
    'cars-negotiated-rates': carsNegotiatedRates.parse,
    'cars-rate-exclusions': carsRateExclusions.parse,
    'cars-travel-policy': carsTravelPolicy.parse,
    'air-exclusions': airExclusions.parse,
    'passive-segments': pnr.parsePassiveSegments,
    'air-passive-segments': pnr.parseAir,
    'air-gds-ticketing': pnr.parseAirGdsTicketing,
    'agency-rate-optimization': agenciesRateOptimization.parse,
    'rate-exclusions': rateExclusions.parse,
    'agency-negotiated-fares': negotiatedFares.parse,
    'revenue-management': revenueManagement.parse,
    'search-engine-configuration': searchEngineConfiguration.parse,
    'negotiated-fares': negotiatedFares.parse,
    'cars-sorting': carsSorting.parse,
    'crd_questions': crdConfiguration.parse,
    'vpa-settings': vpaSettings.parse,
    'sso-configuration': ssoConfigurations.parse,
    'air-preferred-airlines': airPreferredAirlinesSettings.parse,
    'supplier-travelfusion': lccCredentials.parse,
    'supplier-sabre-content-control': sabreContentControl.parse,
    'supplier-amadeus-gds': amadeusGDS.parse,
    'air-lcc-corporate-credentials': lccCredentials.parse,
    'overrides': overrides.parse,
    'air-osi-messaging': OSIMessaging.parse,
    'air-policy': airPolicy.parse,
    'security-vetted-hotels': securityVettedHotels.parse,
    'air-search-customizations': airSearchCustomizations.parse,
    'air-custom-airport-hubs': customAirportHubs.parse,
    'air-search-sorting-customization': airSortingCustomization.parse,
    'cars-payment-methods-priority': carsPaymentMethodsPriority.parse,
    'segment-configuration': segmentConfig.parse,
    'agent-chat': agentChat.parse,
    'cars-home-page': carsHomePage.parse,
    'rail-supplier-deutschebahn': railDeutscheBahn.parse,
    'rail-supplier-trainline': railTrainline.parse,
    'air-office-hours': officeHours.parse,
};

const ERROR_PARSERS = {
    'air-pnr-formatting': pnr.parseError,
    'air-pnr-remarks': pnr.parseError,
    'passive-segments': pnr.parseError,
    'air-passive-segments': pnr.parseError,
    'air-gds-ticketing': pnr.parseError,
    'pnr-formatting': pnr.parseError,
    'pnr-remarks': pnr.parseError,
    'pnr-templates': pnr.parseError,
    'cars-pnr-remarks': pnr.parseError,
    'cars-pnr-templates': pnr.parseError,
    'cars-pnr-formatting': pnr.parseError,
    'general-pnr-formatting': pnr.parseError,
    'general-pnr-remarks': pnr.parseError,
};

const SERIALIZERS = {
    'settings': settings.serialize,
    'supplier-premierinn': supplierPremierInn.serialize,
    'supplier-hrsd': hrsDirect.serialize,
    'supplier-totalstay': totalstay.serialize,
    'hotel-labeling': hotelLabeling.serialize,
    'hotel-dynamic-sort': dynamicSorting.serialize,
    'terms-and-conditions': legal.serialize,
    'invoice-collection': tripsourceInvoiceCollection.serialize,
    'tripsource-credits-and-unused-tickets': tripsourceCreditsAndUnusedTickets.serialize,
    'rate-caps': travelPolicy.serialize,
    'regional-settings': regionalSettings.serialize,
    'client-hotels': preferredHotels.serialize,
    'tmc-preferred-hotels': preferredHotels.serialize,
    'crd': crd.serialize,
    'hotel-exclusions': hotelExclusions.serialize,
    'hotel-exclusion-rules': hotelExclusionRules.serialize,
    'branch-hotel-exclusions': hotelExclusions.serialize,
    'hotel-sorting': hotelSorting.serialize,
    'rate-exclusions': rateExclusions.serialize,
    'air-policy': airPolicy.serialize,
    'amadeus-credentials': agencyGds.serialize,
    'sabre-credentials': agencyGds.serialize,
    'apollo-credentials': agencyGds.serialize,
    'galileo-credentials': agencyGds.serialize,
    'farelogix-credentials': agencyGds.serialize,
    'company-locations': companyLocations.serialize,
    'custom-country-codes': customCountryCodes.serialize,
    'air-pnr-remarks': pnr.serialize,
    'pnr-remarks': pnr.serialize,
    'pnr-templates': pnr.serialize,
    'cars-pnr-remarks': pnr.serialize,
    'general-pnr-remarks': pnr.serialize,
    'cars-pnr-templates': pnr.serialize,
    'cars-preferred-rates': carsPreferredRates.serialize,
    'cars-negotiated-rates': carsNegotiatedRates.serialize,
    'cars-rate-exclusions': carsRateExclusions.serialize,
    'cars-travel-policy': carsTravelPolicy.serialize,
    'air-exclusions': airExclusions.serialize,
    'passive-segments': pnr.serializePassiveSegments,
    'air-passive-segments': pnr.serializeAir,
    'air-gds-ticketing': pnr.serializeAirGdsTicketing,
    'agency-rate-optimization': agenciesRateOptimization.serialize,
    'agency-negotiated-fares': negotiatedFares.serialize,
    'negotiated-fares': negotiatedFares.serialize,
    'revenue-management': revenueManagement.serialize,
    'search-engine-configuration': searchEngineConfiguration.serialize,
    'cars-sorting': carsSorting.serialize,
    'crd_questions': crdConfiguration.serialize,
    'vpa-settings': vpaSettings.serialize,
    'air-preferred-airlines': airPreferredAirlinesSettings.serialize,
    'supplier-travelfusion': lccCredentials.serialize,
    'supplier-sabre-content-control': sabreContentControl.serialize,
    'supplier-amadeus-gds': amadeusGDS.serialize,
    'air-lcc-corporate-credentials': lccCredentials.serialize,
    'air-osi-messaging': OSIMessaging.serialize,
    'overrides': overrides.serialize,
    'security-vetted-hotels': securityVettedHotels.serialize,
    'air-search-customizations': airSearchCustomizations.serialize,
    'air-custom-airport-hubs': customAirportHubs.serialize,
    'air-search-sorting-customization': airSortingCustomization.serialize,
    'cars-payment-methods-priority': carsPaymentMethodsPriority.serialize,
    'segment-configuration': segmentConfig.serialize,
    'agent-chat': agentChat.serialize,
    'cars-home-page': carsHomePage.serialize,
    'rail-supplier-deutschebahn': railDeutscheBahn.serialize,
    'rail-supplier-trainline': railTrainline.serialize,
    'air-office-hours': officeHours.serialize,
};
const INHERITANCE_SERIALIZERS = {
    'terms-and-conditions': legal.serializeInheritance,
    'amadeus-credentials': agencyGds.serializeInheritance,
    'sabre-credentials': agencyGds.serializeInheritance,
    'apollo-credentials': agencyGds.serializeInheritance,
    'galileo-credentials': agencyGds.serializeInheritance,
    'farelogix-credentials': agencyGds.serializeInheritance,
};

const PARENTS = {
    'gds-configuration': skip('contact_info:country_code'),
    'terms-and-conditions': legal.fromParent,
    'special-rates': skip('primary_gds'),
    'rate-exclusions': skip('primary_gds'),
    'rate-codes': skip('primary_gds'),
    'client-hotels': skip('filename'),
    'company-locations': skip('filename', 'template'),
    'air-pnr-formatting': skip('primary_gds'),
    'pnr-formatting': skip('primary_gds'),
    'cars-pnr-formatting': skip('primary_gds'),
    'general-pnr-formatting': skip('primary_gds'),
    'queues-general': skip('primary_gds'),
    'hotel-exclusions': skip('primary_gds'),
    'hotel-labeling': skip('primary_gds', 'languages', 'regional:language'),
    'hotel-sorting': hotelSorting.fromParent,
    'pnr-remarks': skip('country_code', 'agency:custom_country_codes'),
    'pnr-templates': skip('country_code', 'agency:custom_country_codes'),
    'cars-pnr-remarks': skip('country_code', 'agency:custom_country_codes'),
    'cars-pnr-templates': skip('country_code', 'agency:custom_country_codes'),
    'air-pnr-remarks': skip('country_code', 'agency:custom_country_codes'),
    'passive-segments': skip('country_code', 'agency:custom_country_codes'),
    'air-passive-segments': skip('country_code', 'agency:custom_country_codes'),
    'air-gds-ticketing': skip('country_code', 'agency:custom_country_codes'),
    'general-pnr-remarks': skip('country_code', 'agency:custom_country_codes'),
    'cars-negotiated-rates': skip('primary_gds'),
    'cars-sorting': carsSorting.fromParent,
};

export function parse(data, query, {fromParent, errors} = {}) {
//export function parse(data, query, {fromParent, errors, companyInfo}={}) {
    const setting = query.section;
    const parser = errors ? ERROR_PARSERS[setting] || IDENTITY : PARSERS[setting] || IDENTITY;
    const parentFilter = fromParent ? PARENTS[setting] || IDENTITY : IDENTITY;

    // TODO: remove parentFilter GG-9380
    //return parentFilter(parser(data, query, companyInfo), query);
    return parentFilter(parser(data, query), query);
}

function genericInhSerialiser(inherits, params) {
    return inherits ? {inherits} : {...params, inherits};
}

export function serialize(data, query, inherits, policyGroupId, ...args) {
    const setting = query.section;
    const addPolicyGroup = data => policyGroupId ? {...data, 'policy_group_id': Number(policyGroupId)} : data;
    const paramSerializer = SERIALIZERS[setting] || IDENTITY;
    const inhSerializer = INHERITANCE_SERIALIZERS[setting] || genericInhSerialiser;
    return addPolicyGroup(inhSerializer(inherits, paramSerializer(data, query, ...args)));
}

export function fetchScreen(type, {treeName, id, section, system, policyGroupId, action = '', inheritsFlag}) {
    const path = !system || treeName === 'agencies'
        ? `/api/${treeName}/${id}/${section}/${action}`
        : `/api/${treeName}/${id}/${section}/${system}/${action}`;

    const query = stringifyUrlQuery({'policy_group_id': policyGroupId, inherits: inheritsFlag});
    return path + query;
}

export function fetchScreenItem(type, {itemId, action, ...query}) {
    return fetchScreen(type, {...query, action: `${itemId}/${action}`});
}

export function fetchMatrix(type, {treeName, section}) {
    return `/api/${treeName}/matrix/${section}/`;
}

export function download(type, query) {
    return fetchScreen(type, {...query, action: 'download/'});
}

export function downloadItem(type, query) {
    return fetchScreenItem(type, {...query, action: 'download/'});
}

export function upload(type, query) {
    return fetchScreen(type, {...query, action: 'upload/'});
}

export function test(type, query) {
    return fetchScreen(type, {...query, action: 'test_connection/'});
}
