'process i18n';
import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import settings from 'airborne/settings';
import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/lib/Modal';


export default class TermsModal extends React.Component {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
    };

    render() {
        const {onHide} = this.props;
        return (<Modal dialogClassName="modal-lg" show>
            <Modal.Header onHide={onHide} closeButton className="clearfix" />
            <Modal.Body>
                <p dangerouslySetInnerHTML={{__html:settings.tos}} />
            </Modal.Body>
            <Modal.Footer>
                <Button name="modal-close" onClick={onHide}>
                    {gettext('Close')}
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}
