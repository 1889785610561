import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BootstrapButton from 'react-bootstrap/lib/Button';


export default class Button extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        progress: PropTypes.bool,
        children: PropTypes.any,
        onClick: PropTypes.func,
        bsStyle: PropTypes.oneOf(['default', 'primary', 'secondary', 'link', 'inverse', 'danger', 'warning', 'success', 'info']),
        bsSize: PropTypes.oneOf(['large', 'small', 'xsmall', 'xs']),
        block: PropTypes.bool,
        disabled: PropTypes.bool,
        href: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        progress: false,
    };

    render() {
        const {progress, children, ...props} = this.props;
        const className = classnames(this.props.className, {
            'btn--in-progress': progress,
        });

        return (
            <BootstrapButton {...props} className={className}>
                <div className="btn__text">
                    {children}
                </div>
                <div className="btn__spinner" />
            </BootstrapButton>
        );
    }
}
