'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/lib/Modal';

import gettext from 'airborne/gettext';

import {cancelBooking} from './actions';
import {getCarBooking, isModificationInProgress} from '../selectors';


export class ControlledBookingCancellationModal extends React.Component {
    static propTypes = {
        booking: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    };

    handleCancel = ()=> {
        const {onCancel, booking} = this.props;

        onCancel({
            'booking_uid': booking.uid,
            system: 'aft',
        });
    };

    render() {
        const {onHide, loading} = this.props;

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title>{gettext('Cancel reservation')}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button bsStyle="default"
                        onClick={onHide}>{gettext('Do Not Cancel')}</Button>
                    <Button bsStyle="danger"
                        progress={loading}
                        disabled={loading}
                        onClick={this.handleCancel}>{gettext('Cancel This Reservation')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        booking: getCarBooking(state, props.uid),
        loading: isModificationInProgress(state, props.uid),
    };
}

const mapDispatchToProps = {onCancel: cancelBooking};

export default connect(mapStateToProps, mapDispatchToProps)(ControlledBookingCancellationModal);
