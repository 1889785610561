import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Glyphicons from 'midoffice/components/Glyphicons';


export class Spinner extends React.Component {
    static propTypes = {
        product: PropTypes.oneOf(['hotels', 'cars', 'air']),
    };

    render() {
        const {product} = this.props;
        const glyph = {
            hotels: 'building',
            cars: 'car',
            air: 'airplane',
        }[product];
        return (<div className="hotel-preloader__spinner-wrapper">
            <Glyphicons glyph={glyph} className="hotel-preloader__spinner-icon" />

            <div className="hotel-preloader__spinner-circle" />
            <div className="hotel-preloader__spinner-circle" />
            <div className="hotel-preloader__spinner-circle" />
        </div>);
    }
}


export default class WaitBooking extends React.Component {
    static propTypes = {
        show: PropTypes.bool,
        product: PropTypes.oneOf(['hotels', 'cars', 'air']),
        message: PropTypes.string,
    };

    static defaultProps = {
        message: gettext('Please wait while we confirm availability and price…'),
    };

    render() {
        const {show, product} = this.props;
        return (<Modal show={show} className="modal--hotel-preloader">
            <Modal.Body>
                <Row>
                    <Col xs={9}>
                        <h3 className="hotel-preloader__message">
                            {this.props.message}
                        </h3>
                    </Col>
                    <Col xs={3}>
                        <Spinner product={product} />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>);
    }
}
