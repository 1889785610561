'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Modal from 'react-bootstrap/lib/Modal';
import Button from 'midoffice/components/Button';

import PartialWarning from './PartialWarning';
import CancellationPolicy from 'midoffice/bookings/CancellationPolicy';

import {modifyBooking} from './actions';
import {getBooking} from 'midoffice/bookings/selectors';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import Shortcut from 'midoffice/components/shortcut/Shortcut';


@connect((state, props)=> {
    const {bookingModification} = state;
    const booking = getBooking(state, props);

    const id = props.data ? props.data.id : props.id;
    return {
        cancellationPolicy: booking['cancellation_policy'],
        status: booking['status'],
        gds: booking['gds'],
        number: booking['number'],
        loading: bookingModification.uuid === id,
    };
}, {
    onModify: modifyBooking,
})
export default class BookingModificationModal extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        data: PropTypes.object,
        loading: PropTypes.bool,
        status: PropTypes.string.isRequired,
        cancellationPolicy: PropTypes.arrayOf(PropTypes.string).isRequired,
        gds: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        onModify: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    handleModify = ()=> {
        const {id, data, onModify} = this.props;

        onModify(id || data.id);
    };

    render() {
        const {cancellationPolicy, status, gds, number, onHide, loading} = this.props;
        const policyDisclaimer = [
            ...(cancellationPolicy || []),
            gettext('Cancellation fees are in hotel\'s local currency unless explicitly stated otherwise.'),
            gettext('All times are in the local hotel\'s time.'),
        ];

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title>{gettext('Modify reservation')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <PartialWarning status={status} gds={gds} number={number} />
                    <div className="row-wrapper row-wrapper--condensed">
                        <h4>{gettext('Cancellation Policy')}</h4>
                        <CancellationPolicy policy={policyDisclaimer} className="ul-content" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" onClick={onHide}>
                        <Shortcut label={gettext('Cancel')} shortcut={SHORTCUTS_LIST.C} action={onHide} isModalShortcut />
                    </Button>
                    <Button progress={loading} disabled={loading} bsStyle="primary" onClick={this.handleModify}>
                        <Shortcut label={gettext('Proceed')} shortcut={SHORTCUTS_LIST.P} action={this.handleModify} isModalShortcut />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
