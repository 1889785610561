import React from 'react';

import PropTypes from 'prop-types';

import gettext from 'airborne/gettext';

import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {userMessages} from 'airborne/search2/helpers/apiError';


export default class SearchModal extends React.Component {
    static propTypes = {
        kind: PropTypes.string.isRequired,
        message: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string
        ]),
        onHide: PropTypes.func.isRequired,
    };

    renderLabel(kind) {
        return kind === 'search'
            ? gettext('Search Again')
            : gettext('OK');
    }

    render() {
        const {onHide, message, kind} = this.props;
        return (
            <Modal show keyboard={false} backdrop="static" onHide={onHide} >
                <div className="info-modal">
                    <Modal.Body>
                        {userMessages(message, kind).map((part, idx)=> (
                            <p key={idx}>{part}</p>
                        ))}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={onHide} bsStyle="primary">{this.renderLabel(kind)}</Button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
}
