import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import React from 'react';

import {getAllDistanceChoices} from 'airborne/homepage2/helpers/searchDistance';

import PropTypes from 'prop-types';

import {injectFormContext} from 'midoffice/newforms/decorators';

import Select from 'midoffice/newforms/widgets/Select';
import Button from 'midoffice/components/Button';

@injectFormContext
export default class Editor extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const {onSubmit, value, loading} = this.props;
        const extra = {
            labelCol: 2,
            inputCol: 6,
            inputSize: '',
        };
        const options = {
            distanceUnits: [
                ['KM', gettext('Kilometers (km)')],
                ['MI', gettext('Miles (mi)')],
            ],
            dateFormats: [
                ['en', gettext('MM/DD/YYYY')],
                ['fr', gettext('DD/MM/YYYY')],
                ['ja', gettext('YYYY/MM/DD')],
            ],
            searchRadius: getAllDistanceChoices(value.distance_units),
            languages: settings.LANGUAGES,
            currencies: settings.CURRENCIES,
        };
        return (
            <div>
                <h5 className="page-section-title">{gettext('Default Display Preferences')}</h5>

                <form className="form-horizontal" onSubmit={onSubmit}>
                    <fieldset>
                        <Select.Field name="lang_code"
                            label={gettext('Default Language')}
                            choices={options.languages}
                            {...extra} autoFocus/>
                        <Select.Field name="currency_code"
                            label={gettext('Currency')}
                            choices={options.currencies}
                            {...extra} />
                        <Select.Field name="distance_units"
                            label={gettext('Distance in')}
                            choices={options.distanceUnits} {...extra} />
                        <Select.Field name="date_format"
                            label={gettext('Date Format')}
                            choices={options.dateFormats} {...extra} />
                        <Select.Field name="search_radius"
                            label={gettext('Hotel Search Radius')}
                            choices={options.searchRadius} {...extra} />
                        <Select.Field name="car_search_radius"
                            label={gettext('Car Search Radius')}
                            choices={options.searchRadius} {...extra} />
                        <div className="page-nav__footer">
                            <Button progress={loading} className="btn btn-secondary btn-lg" type="submit">{gettext('Save Settings')}</Button>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
}
