import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import Button from 'react-bootstrap/lib/Button';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getCarModificationFlowBool} from 'midoffice/car-bookings/selectors';
import {modifyCarBookingFlowQuit} from 'midoffice/car-bookings/modify/modifyActions';

const EMPTY = {};

function selectName(profile) {
    const {
        'pnr_name_first': firstName,
        'pnr_name_last': lastName,
    } = profile;
    return [firstName, lastName].join(' ');
}

function stateProps(state) {
    const profile = getPnrProfile(state, 0) || EMPTY;
    return {
        pnr: profile.pnr,
        name: selectName(profile),
        inCarModificationFlow: getCarModificationFlowBool(state),
    };
}
@connect(stateProps, {modifyCarBookingFlowQuit})
export default class PnrModWarning extends React.Component {
    static propTypes = {
        pnr: PropTypes.string,
        name: PropTypes.string,
        inCarModificationFlow: PropTypes.bool,
        modifyCarBookingFlowQuit: PropTypes.func,
    };

    handleQuit = () => {
        const {inCarModificationFlow, modifyCarBookingFlowQuit} = this.props;
        if (inCarModificationFlow) {
            modifyCarBookingFlowQuit();
        } else {
            document.location.href = "/bookings/";
        }
    };

    render() {
        const {pnr, name} = this.props;
        return (<div className="pnr-message">
            <div className="pull-left">
                <div className="pnr-message__header">
                    { gettext('You’re in PNR modification mode.') }
                </div>
                <div>
                    {pnr && (<span className="pnr-message__item">
                        <strong>{ gettext('PNR') }:</strong> { pnr }
                    </span>)}
                    {name && (<span className="pnr-message__item">
                        <strong>{ gettext('Traveler') }:</strong> { name }
                    </span>)}
                </div>
            </div>
            <Button className="pull-right" href="#" onClick={this.handleQuit}>
                { gettext('Quit') }
            </Button>
        </div>);
    }
}
