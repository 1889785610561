import React from 'react';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import Input from 'midoffice/newforms/widgets/Input';
import Button from 'midoffice/components/Button';
import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';
import {connect} from 'react-redux';
import {sendForgotPassword, changeForgotPassword} from 'airborne/store/modules/login/actions/forgotPassword';
import ForgotPasswordSchema from './schemas/ForgotPasswordSchema';

import {
    getUserSettingsErrors,
    getUserSettingsLoading,
    getUserSettingsValue
} from 'airborne/store/modules/user_settings/selectors/userSettings';
import {Link} from 'react-router-dom';

@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        errors: PropTypes.object,
        loading: PropTypes.bool,
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit();
    }

    render() {
        const {errors, loading} = this.props;
        const extra = {
            labelCol: 2,
            inputCol: 12,
        };
        const errorMessage = errors && errors.errors;

        return (<form className="form-horizontal" onSubmit={this.handleSubmit}>
            <h3 className="floated-form__body__header">{gettext('Reset your password')}</h3>

            <p>{gettext('Enter the email associated with your account, and we\'ll email you a link to reset your password.')}</p>
            <Input.Field
                placeholder={gettext('Email')}
                name="email"
                {...extra}/>
            {errorMessage && <section className="floated-form__error">
                <strong>{gettext('Sorry, we couldn\'t verify your email.')}</strong>
            </section>}
            <div className="floated-form__control">
                <Button
                    className="btn-secondary pull-right"
                    type={'submit'}
                    disabled={loading}
                    onClick={this.handleSubmit}>{gettext('Continue')}</Button>
                <Link className="pull-right btn btn-link" to="/accounts/login/">{gettext('Cancel')}</Link>

            </div>
        </form>);
    }
}
@connect((state) => {
    return {
        value: getUserSettingsValue(state, 'forgotPassword'),
        loading: getUserSettingsLoading(state, 'forgotPassword'),
        errors: getUserSettingsErrors(state, 'forgotPassword'),
        login: getUserSettingsValue(state, 'login'),
    };
}, {onSave: sendForgotPassword, onChange: changeForgotPassword})
class ForgotPassword extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            'email': PropTypes.string,
        }),
        errors: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        login: PropTypes.object,
        history: PropTypes.object,
    };

    componentDidMount() {
        const {login} = this.props;
        this.props.onChange({email: login.email || ''}, null);
    }

    handleFormChange = ({value, errors}) => {
        this.props.onChange(value, errors);
    };

    handleSend = () => {
        const {value, history} = this.props;
        const errors = ForgotPasswordSchema.validate(value);
        if (noErrors(errors)) {
            this.props.onSave(history);
        }
        else {
            this.props.onChange(value, errors);
        }
    };

    render() {
        const {value, errors, loading} = this.props;
        return (
            <div>
                <Editor
                    onChange={this.handleFormChange}
                    schema={ForgotPasswordSchema}
                    value={value}
                    loading={loading}
                    onSubmit={this.handleSend}
                    errors={errors}/>
            </div>
        );
    }
}

export default ForgotPassword;
